import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  faPlus, faMinus, faCheck, faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useBlocker } from 'react-router-dom';
import axios from '../services/Api';
import TwoButtonModal from './TwoButtonModal';
import InputComponent from './InputComponent';
import InputSelector from './InputSelector';
import ToggleSwitch from './ToggleSwitch';
import CircledButton from './CircledButton';
import Card from './Card';
import EditRoles from './EditRoles';

// styles
import '../styles/components/EditProfile.scss';

const EditProfile = ({
  user, onClose, saveUser, header, admin, removeUser,
}) => {
  const { t } = useTranslation();
  const [errorTextRequired, setErrorTextRequired] = useState({
    firstName: '',
    lastName: '',
    upn: '',
    mainPhone: '',
    mainPhoneType: '',
    email: '',
    location: '',
    eContactFirstName: '',
    eContactLastName: '',
    eContactMainPhone: '',
    alternatePhone1: '',
    alternatePhone2: '',
  });
  const [errorTextPhone, setErrorTextPhone] = useState({
    mainPhone: '',
    workPhone: '',
    eContactMainPhone: '',
    eContactAlternatePhone: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [alternateEmailsError, setAlternateEmailsError] = useState(['', '']);
  const [mainPhoneTextNotifications, setMainPhoneTextNotifications] = useState(false);
  const [alternatePhonesError, setAlternatePhonesError] = useState([]);
  const [invalidRoles, setInvalidRoles] = useState({});
  const [locationOptions, setLocationOptions] = useState([]);

  const [userChanges, setUserChanges] = useState({
    isAdmin: user.isAdmin || false,
    emergencyContact: { ...user.emergencyContact },
    upn: user.upn,
    email: user.email,
    alternateEmails: user.alternateEmails ? user.alternateEmails.map((email) => ({ email })) : [],
    firstName: user.firstName,
    lastName: user.lastName,
    location: user.location,
    mainPhone: { ...user.mainPhone },
    alternatePhones: user.alternatePhones ? JSON.parse(JSON.stringify(user.alternatePhones)) : [],
    homeAddress: user.homeAddress,
    extension: user.extension,
    notes: user.notes,
    workPhone: user.workPhone,
    role: user.role,
  });
  useEffect(() => {
    const abortController = new AbortController();
    window.scrollTo(0, 0);
    const getLocations = async () => {
      try {
        const { data } = await axios.get('/constants/locations', { signal: abortController.signal });
        const sortedData = data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (b.name > a.name) {
            return -1;
          }
          return 0;
        });
        setLocationOptions(sortedData);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
    };

    if (admin) {
      getLocations();
    }

    return () => {
      abortController.abort();
    };
  }, []);

  const handleDelete = async () => {
    try {
      await toast.promise(axios.post('admin/user/delete', {
        deleteId: user._id,
      }), {
        pending: t('deletingUser'),
        success: t('deletedUser'),
        error: t('deleteUserError'),
      });
      removeUser(user._id);
      onClose();
    } catch (e) {
      setDeleteModalOpen(false);
    }
  };

  const handleChange = (change, key, nestedKey, index) => {
    const temp = JSON.parse(JSON.stringify(userChanges));
    if (index !== undefined) {
      if (nestedKey) {
        temp[key][index][nestedKey] = change;
      } else {
        temp[key][index] = change;
      }
    } else if (nestedKey) {
      temp[key][nestedKey] = change;
    } else {
      temp[key] = change;
    }
    setUserChanges(temp);
  };

  const isUserEdited = () => {
    let isEdited = false;
    Object.keys(userChanges).forEach((key) => {
      if (typeof userChanges[key] !== 'object' && typeof user[key] !== 'object') {
        if (!Array.isArray(userChanges[key])) {
          if (userChanges[key] !== user[key]) {
            isEdited = true;
          }
        }
      } else if (typeof userChanges[key] === 'object' && typeof user[key] === 'object') {
        if (!Array.isArray(userChanges[key]) && userChanges[key]) {
          Object.keys(userChanges[key]).forEach((nestedKey) => {
            if (userChanges[key][nestedKey] !== user[key][nestedKey]) {
              isEdited = true;
            }
          });
        }
      }
    });
    userChanges.alternateEmails.forEach((email, i) => {
      if (email.email !== user.alternateEmails[i]) {
        isEdited = true;
      }
    });
    userChanges.alternatePhones.forEach((phone, i) => {
      if (phone.number !== user.alternatePhones[i]?.number
        || phone.type !== user.alternatePhones[i]?.type
      ) {
        isEdited = true;
      }
    });
    if ((user.alternatePhones && userChanges.alternatePhones.length !== user.alternatePhones.length)
    || (user.alternateEmails && userChanges.alternateEmails.length !== user.alternateEmails.length)
    ) {
      isEdited = true;
    }
    return (isEdited);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const requiredCheck = [{ val: userChanges.firstName, key: 'firstName', header: t('firstName') },
      { val: userChanges.lastName, key: 'lastName', header: t('lastName') },
      { val: userChanges.email, key: 'email', header: t('corporateEmail') },
      { val: userChanges.location, key: 'location', header: t('officeLocation') },
      { val: userChanges.mainPhone.number, key: 'mainPhone', header: t('mainPhone') },
      { val: userChanges.mainPhone.type, key: 'mainPhoneType', header: t('type') },
      { val: userChanges.emergencyContact.firstName, key: 'eContactFirstName', header: t('firstName') },
      { val: userChanges.emergencyContact.lastName, key: 'eContactLastName', header: t('lastName') },
      { val: userChanges.emergencyContact.mainPhone, key: 'eContactMainPhone', header: t('mainPhone') },

    ];
    const errorTextRequiredTemp = { ...errorTextRequired };
    for (let i = 0; i < requiredCheck.length; i += 1) {
      if (!requiredCheck[i].val) {
        errorTextRequiredTemp[requiredCheck[i].key] = t('requiredError', { header: requiredCheck[i].header });
        error = true;
      } else if (requiredCheck[i].key === 'email') {
        if (!validator.isEmail(requiredCheck[i].val)) {
          errorTextRequiredTemp[requiredCheck[i].key] = t('invalidEmail', { header: requiredCheck[i].header });
          error = true;
        } else {
          errorTextRequiredTemp[requiredCheck[i].key] = '';
        }
      } else {
        errorTextRequiredTemp[requiredCheck[i].key] = '';
      }
    }
    if (admin) {
      if (!userChanges.upn) {
        errorTextRequiredTemp.upn = t('requiredError', { header: t('upn') });
        error = true;
      } else if (!validator.isEmail(userChanges.upn)) {
        errorTextRequiredTemp.upn = t('invalidEmail', { header: t('upn') });
        error = true;
      } else {
        errorTextRequiredTemp.upn = '';
      }
    }
    for (let i = 0; i < userChanges.alternatePhones.length; i += 1) {
      if (!userChanges.alternatePhones[i].type) {
        errorTextRequiredTemp[`alternatePhone${i + 1}`] = t('requiredError', { header: t('type') });
        error = true;
      } else {
        errorTextRequiredTemp[`alternatePhone${i + 1}`] = '';
      }
    }
    setErrorTextRequired(errorTextRequiredTemp);
    const phoneCheck = [{ val: userChanges.mainPhone.number, key: 'mainPhone', header: t('phoneNumber') },
      { val: userChanges.workPhone, key: 'workPhone', header: t('phoneNumber') },
      { val: userChanges.emergencyContact.mainPhone, key: 'eContactMainPhone', header: t('mainPhone') },
      { val: userChanges.emergencyContact.alternatePhone, key: 'eContactAlternatePhone', header: t('alternatePhone') },
    ];
    const errorTextPhoneTemp = { ...errorTextPhone };
    for (let i = 0; i < phoneCheck.length; i += 1) {
      if (phoneCheck[i].val && phoneCheck[i].val.length !== 10) {
        errorTextPhoneTemp[phoneCheck[i].key] = t('invalidPhone', { header: phoneCheck[i].header });
        error = true;
      } else {
        errorTextPhoneTemp[phoneCheck[i].key] = '';
      }
    }
    setErrorTextPhone(errorTextPhoneTemp);
    const tempEmails = [...alternateEmailsError];
    for (let i = 0; i < userChanges.alternateEmails.length; i += 1) {
      if (!validator.isEmail(userChanges.alternateEmails[i].email)
        || userChanges.alternateEmails.length === 0) {
        tempEmails[i] = t('alternateEmailError');
        error = true;
      } else {
        tempEmails[i] = '';
      }
    }
    setAlternateEmailsError(() => [...tempEmails]);
    const tempPhone = [...alternatePhonesError];
    for (let i = 0; i < userChanges.alternatePhones.length; i += 1) {
      if (userChanges.alternatePhones[i].number.length !== 10) {
        tempPhone[i] = t('alternatePhonesError');
        error = true;
      } else {
        tempPhone[i] = '';
      }
    }
    setAlternatePhonesError(() => [...tempPhone]);
    if (Object.values(invalidRoles).some((field) => field)) {
      // Block the submit if invalidRoles contains a truthy value
      error = true;
    }

    if (!error) {
      try {
        const temp = JSON.parse(JSON.stringify(userChanges));
        temp._id = user._id;
        const alternateEmailTemp = userChanges.alternateEmails.map((email) => email.email).filter((email) => email !== '');
        const alternatePhoneTemp = userChanges.alternatePhones.map((phone) => (
          { number: phone.number, type: phone.type }));
        temp.alternateEmails = alternateEmailTemp;
        temp.alternatePhones = alternatePhoneTemp;

        const toastOptions = {
          pending: t('savingProfile'),
          success: t('updateUserSuccess'),
          error: t('updateUserFailRequest'),
        };
        if (admin) {
          toastOptions.pending = user._id ? t('updatingUser') : t('creatingUser');
          toastOptions.success = user._id ? t('updatedUser') : t('createdUser');
          toastOptions.error = user._id ? t('errorUpdatingUser') : t('errorCreatingUser');
        }

        await toast.promise(saveUser(temp, {}, user.isAdmin, user._id), toastOptions);
        onClose();
      } catch {
        // Do nothing (error toast handled by toast.promise)
      }
    } else {
      toast(t('updateUserFailInline'), { type: 'error' });
    }
  };

  const phoneTypes = [
    {
      display: t('home'),
      value: 'home',
    },
    {
      display: t('workCell'),
      value: 'work cell',
    },
    {
      display: t('personalCell'),
      value: 'personal cell',
    },
    {
      display: t('cottage'),
      value: 'cottage',
    },
    {
      display: t('spouse'),
      value: 'spouse',
    },
    {
      display: t('Other'),
      value: 'other',
    },
  ];
  const handleAddInput = (e, location, maxLen, newValue) => {
    e.preventDefault();
    const temp = JSON.parse(JSON.stringify(userChanges));
    if (temp[location].length < maxLen) {
      temp[location] = [...userChanges[location], newValue];
      setUserChanges(temp);
    }
  };
  const handleAddRole = (location, newValue) => {
    const temp = { ...userChanges };
    temp[location] = [...userChanges[location], newValue];
    setUserChanges(temp);
  };

  const handleRemoveInput = (e, location, index) => {
    e.preventDefault();
    const temp = JSON.parse(JSON.stringify(userChanges));
    const filtered = temp[location].filter((_, listIndex) => listIndex !== index);
    temp[location] = filtered;
    setUserChanges(temp);
  };
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && isUserEdited()),
  );
  useEffect(() => {
    const unloadCallback = (e) => {
      e.preventDefault();
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const handleCloseEdit = (e) => {
    e.preventDefault();
    if (isUserEdited()) {
      setIsModalOpen(true);
    } else {
      onClose();
    }
  };
  return (
    <div className="edit-profile">
      {user && (
      <Card
        header={(
          <div className="profile-header-edit">
            <h1>{header ?? t('editProfile')}</h1>
            <div className="profile-header-edit-buttons">
              <CircledButton onClick={(e) => handleSubmit(e)}>
                <FontAwesomeIcon icon={faCheck} title={t('updateProfile')} />
              </CircledButton>
              <CircledButton onClick={(e) => handleCloseEdit(e)}>
                <FontAwesomeIcon icon={faX} title={t('cancelChanges')} />
              </CircledButton>
            </div>
          </div>
        )}
      >
        <div className="profile-items-container">
          <InputComponent id="profile-first-name" header={t('firstName')} type="string" value={userChanges.firstName} onChange={(change) => handleChange(change, 'firstName')} name="first-name" limit={50} />
          {errorTextRequired.firstName.length > 0 && (
          <p className="err">{errorTextRequired.firstName}</p>
          )}
          <InputComponent id="profile-last-name" header={t('lastName')} type="string" value={userChanges.lastName} onChange={(change) => handleChange(change, 'lastName')} name="last-name" limit={50} />
          {errorTextRequired.lastName.length > 0 && (
          <p className="err">{errorTextRequired.lastName}</p>
          )}
          { admin
              && (
                <>
                  <InputComponent id="profile-upn" header={t('upn')} type="string" value={userChanges.upn} onChange={(change) => handleChange(change, 'upn')} name="upn" limit={100} />
                  {errorTextRequired.upn.length > 0 && (
                  <p className="err">{errorTextRequired.upn}</p>
                  )}
                </>
              )}
          {admin ? (
            <>
              <h2>
                {t('officeLocation')}
              </h2>
              <select value={userChanges.location} name="office-location" id="office-location" className="selector-input" onChange={(change) => handleChange(change.target.value, 'location')}>
                <option selected disabled value="">{t('selectLocation')}</option>
                {locationOptions
                  .map((loc) => <option key={loc.name} value={loc.value}>{loc.name}</option>)}
              </select>
              {errorTextRequired.location.length > 0 && (
              <p className="err">{errorTextRequired.location}</p>
              )}
            </>
          ) : (
            <>
              <h2>
                {t('officeLocation')}
              </h2>
              <p>{user.location}</p>
            </>
          )}

          <div>
            { admin
              ? (
                <>
                  <InputComponent id="profile-email" header={t('corporateEmail')} type="string" value={userChanges.email} onChange={(change) => handleChange(change, 'email')} name="email" limit={100} />
                  {errorTextRequired.email.length > 0 && (
                  <p className="err">{errorTextRequired.email}</p>
                  )}
                </>
              )
              : (
                <>
                  <h2>
                    {t('corporateEmail')}
                  </h2>
                  <p>{user.email}</p>
                </>
              ) }
            <div className="input-selectors">
              <div className="input-selectors-item">
                <h3 className="button-header">{t('addAlternateEmail')}</h3>
                <button
                  type="button"
                  className="change-button"
                  onClick={(e) => (handleAddInput(e, 'alternateEmails', 2, {
                    email: '',
                    notifications: false,
                  }))}
                >
                  <FontAwesomeIcon icon={faPlus} title={t('addAlternateEmail')} />
                </button>
              </div>
            </div>
            {userChanges.alternateEmails.map((email, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <div key={`profileAlternateEmail${i}`}>
                <InputComponent
                  id={`profile-alternate-email${i}`}
                  header={t('alternateEmail')}
                  type="email"
                  value={email.email}
                  name="alternate-email"
                  onChange={(change) => {
                    handleChange(change, 'alternateEmails', 'email', i);
                  }}
                  nestedItem
                  limit={100}
                />
                {alternateEmailsError[i] && <p className="err err-right">{alternateEmailsError[i]}</p>}
                <div className="input-selectors">
                  <div className="input-selectors-item">
                    <h2 className="button-header">{t('emailNotifications')}</h2>
                    <ToggleSwitch
                      id={`profile-alternate-email-notification${i}`}
                      header={t('emailNotifications')}
                      name="email-notifications"
                      value={email.notifications}
                      onChange={() => {
                        handleChange(email.notifications ? !email.notifications : true, 'alternateEmails', 'notifications', i);
                      }}
                    />
                  </div>
                  <div className="input-selectors-item">
                    <h3 className="button-header">{t('removeAlternateEmail')}</h3>
                    <button
                      className="change-button"
                      type="button"
                      onClick={(e) => (handleRemoveInput(
                        e, 'alternateEmails', i,
                      ))}
                    >
                      <FontAwesomeIcon icon={faMinus} title={t('removeAlternateEmail')} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <h2>{t('workPhone')}</h2>
          <InputComponent id="profile-work-phone-number" header={t('phoneNumber')} optional={t('optional')} type="number" value={userChanges.workPhone} onChange={(change) => handleChange(change, 'workPhone')} name="work-phone" nestedItem limit={10} phone />
          {errorTextPhone.workPhone.length > 0 && (
          <p className="err">{errorTextPhone.workPhone}</p>
          )}
          <InputComponent id="profile-work-phone-number-extension" header={t('extension')} type="number" value={userChanges.extension} onChange={(change) => handleChange(change, 'extension')} name="work-phone" nestedItem limit={6} />

          <h2>
            {t('mainPhone')}
          </h2>
          <div>
            <InputComponent id="profile-main-phone-number" header={t('phoneNumber')} type="number" value={userChanges.mainPhone.number} onChange={(change) => handleChange(change, 'mainPhone', 'number')} name="main-phone" nestedItem limit={10} phone />
            {errorTextRequired.mainPhone.length > 0 && (
            <p className="err err-right">{errorTextRequired.mainPhone}</p>
            )}
            {errorTextPhone.mainPhone.length > 0 && (
            <p className="err">{errorTextPhone.mainPhone}</p>
            )}
            <InputComponent id="profile-main-phone-number-extension" header={t('extension')} type="number" value={userChanges.mainPhone.extension} onChange={(change) => handleChange(change, 'mainPhone', 'extension')} name="main-phone" nestedItem limit={6} />
            <InputSelector
              id="profile-main-phone-number-type"
              header={t('type')}
              value={userChanges.mainPhone.type}
              onChange={(changes) => {
                handleChange(changes, 'mainPhone', 'type', undefined);
              }}
              nestedItem
              options={phoneTypes}
            />
            {errorTextRequired.mainPhoneType.length > 0 && (
            <p className="err err-right">{errorTextRequired.mainPhoneType}</p>
            )}
            <div className="input-selectors">
              <div className="input-selectors-item">
                <h2 className="button-header">{t('textNotifications')}</h2>
                <ToggleSwitch id="profile-main-phone-number-notifications" header={t('textNotifications')} name="phone-notifications" onChange={() => setMainPhoneTextNotifications(!mainPhoneTextNotifications)} value={mainPhoneTextNotifications} />
              </div>
              <div className="input-selectors-item">
                <h3 className="button-header">{t('addAlternatePhone')}</h3>
                <button
                  type="button"
                  className="change-button"
                  onClick={(e) => (handleAddInput(e, 'alternatePhones', 2, {
                    type: '',
                    number: '',
                    notifications: false,
                  }))}
                >
                  <FontAwesomeIcon icon={faPlus} title={t('addAlternatePhone')} />
                </button>
              </div>
            </div>
            {userChanges.alternatePhones.map((phone, i) => (
            // eslint-disable-next-line  react/no-array-index-key
              <div key={`profileAlternatePhoneNumber${i}`}>
                <InputComponent
                  id={`profile-alternate-phone-number${i}`}
                  header={t('alternatePhone')}
                  type="number"
                  value={phone.number}
                  name="alternate-phone"
                  onChange={(change) => {
                    handleChange(change, 'alternatePhones', 'number', i);
                  }}
                  nestedItem
                  limit={10}
                  phone
                />
                {alternatePhonesError[i] && <p className="err err-right">{alternatePhonesError[i]}</p>}
                <InputSelector
                  id={`profile-alternate-phone-type${i}`}
                  header={t('type')}
                  value={userChanges.alternatePhones[i].type}
                  onChange={(change) => {
                    handleChange(change, 'alternatePhones', 'type', i);
                  }}
                  options={phoneTypes}
                />
                {errorTextRequired[`alternatePhone${i + 1}`]
                  && <p className="err err-right">{errorTextRequired[`alternatePhone${i + 1}`]}</p>}
                <div className="input-selectors">
                  <div className="input-selectors-item">
                    <h2 className="button-header">{t('textNotifications')}</h2>
                    <ToggleSwitch
                      id={`profileAlternatePhoneNotification${i}`}
                      header={t('textNotifications')}
                      name="PhoneNotifications"
                      value={phone.notifications}
                      onChange={() => {
                        handleChange(!phone.notifications, 'alternatePhones', 'notifications', i);
                      }}
                    />
                  </div>
                  <div className="input-selectors-item">
                    <h3 className="button-header">{t('removeAlternatePhone')}</h3>
                    <button
                      type="button"
                      className="change-button"
                      onClick={(e) => (handleRemoveInput(
                        e, 'alternatePhones', i,
                      ))}
                    >
                      <FontAwesomeIcon icon={faMinus} title={t('removeAlternatePhone')} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <InputComponent id="profile-home-address" header={t('homeAddress')} optional={t('optional')} type="string" value={userChanges.homeAddress} onChange={(change) => handleChange(change, 'homeAddress')} name="home-address" limit={500} />
            <InputComponent id="profile-notes" header={t('notes')} optional={t('optional')} type="string" value={userChanges.notes} onChange={(change) => handleChange(change, 'notes')} name="notes" limit={1000} />
            <div className="emergency-contact">
              <h2 className="emergency-contact-header">{t('emergencyContact')}</h2>
              <div className="main">
                <InputComponent id="profile-emergency-first-name" header={t('firstName')} type="string" value={userChanges.emergencyContact.firstName} onChange={(change) => handleChange(change, 'emergencyContact', 'firstName')} name="e-contact-first-name" limit={50} />
                {errorTextRequired.eContactFirstName.length > 0 && (
                <p className="err">{errorTextRequired.eContactFirstName}</p>
                )}
                <InputComponent id="profile-emergency-last-name" header={t('lastName')} type="string" value={userChanges.emergencyContact.lastName} onChange={(change) => handleChange(change, 'emergencyContact', 'lastName')} name="e-contact-last-name" limit={50} />
                {errorTextRequired.eContactLastName.length > 0 && (
                <p className="err">{errorTextRequired.eContactLastName}</p>
                )}
                <InputComponent id="profile-emergency-relation" header={t('relation')} optional={t('optional')} type="string" value={userChanges.emergencyContact.relation} onChange={(change) => handleChange(change, 'emergencyContact', 'relation')} name="e-contact-relation" limit={50} />
              </div>
              <div>
                <InputComponent id="profile-emergency-main-phone" header={t('mainPhone')} type="number" value={userChanges.emergencyContact.mainPhone} onChange={(change) => handleChange(change, 'emergencyContact', 'mainPhone')} name="e-contact-main-phone" limit={10} phone />
                {errorTextRequired.eContactMainPhone.length > 0 && (
                <p className="err err-right">{errorTextRequired.eContactMainPhone}</p>
                )}
                {errorTextPhone.eContactMainPhone.length > 0 && (
                <p className="err">{errorTextPhone.eContactMainPhone}</p>
                )}

                <InputComponent id="profile-emergency-alternate-phone" header={t('alternatePhone')} optional={t('optional')} type="number" value={userChanges.emergencyContact.alternatePhone} onChange={(change) => handleChange(change, 'emergencyContact', 'alternatePhone')} name="e-contact-alternate-phone" limit={10} phone />
                {errorTextPhone.eContactAlternatePhone.length > 0 && (
                <p className="err">{errorTextPhone.eContactAlternatePhone}</p>
                )}
              </div>
            </div>
          </div>
          {blocker.state === 'blocked' && (
          <TwoButtonModal
            isOpen
            redButtonFunctionality={() => blocker.proceed()}
            blueButtonFunctionality={() => blocker.reset()}
            closeFunctionality={() => blocker.reset()}
            headerText={t('moveAwayModalHeader')}
            redButtonText={t('leave')}
            blueButtonText={t('stay')}
          />
          )}
          <TwoButtonModal
            isOpen={isModalOpen}
            redButtonFunctionality={() => onClose()}
            blueButtonFunctionality={() => setIsModalOpen(false)}
            closeFunctionality={() => setIsModalOpen(false)}
            headerText={t('discardModalText')}
            redButtonText={t('discard')}
            blueButtonText={t('cancel')}
          />
          { admin && (
          <TwoButtonModal
            isOpen={deleteModalOpen}
            redButtonFunctionality={() => handleDelete()}
            blueButtonFunctionality={() => setDeleteModalOpen(false)}
            closeFunctionality={() => setDeleteModalOpen(false)}
            headerText={t('deleteModalText')}
            redButtonText={t('yes')}
            blueButtonText={t('no')}
          />
          )}
        </div>

        {admin && (
        <div className="roles-container">
          <h2 className="roles-header">
            {t('roles')}
          </h2>

          <EditRoles
            userRoles={userChanges.role}
            handleAddRole={handleAddRole}
            handleChange={handleChange}
            handleRemoveInput={handleRemoveInput}
            setUserChanges={setUserChanges}
            isAdmin={userChanges.isAdmin}
            setInvalidRoles={setInvalidRoles}
            fullName={`${userChanges.firstName} ${userChanges.lastName}`}
          />
        </div>
        )}

        <div className="button-container">
          <button type="button" className="blue-button" onClick={(e) => handleSubmit(e)}>{t('save')}</button>
          <button type="button" className="blue-button" onClick={(e) => handleCloseEdit(e)}>{t('discard')}</button>
          {(admin && user._id) && <button type="button" className="red-button" onClick={() => setDeleteModalOpen(true)}>{t('delete')}</button>}
        </div>

      </Card>
      )}
    </div>
  );
};

export default EditProfile;

EditProfile.defaultProps = {
  onClose: () => { },
  saveUser: () => { },
  user: {
    _id: '',
    isAdmin: false,
    upn: '',
    emergencyContact: {
      firstName: '',
      lastName: '',
      relation: '',
      mainPhone: '',
      alternatePhone: '',
    },
    alternateEmails: [],
    email: '',
    firstName: '',
    lastName: '',
    mainPhone: {
      type: '',
      string: '',
      extension: '',
    },
    alternatePhones: [],
    homeAddress: '',
    workPhone: '',
    extension: '',
    notes: '',
    role: [{
      name: '',
      locations: [{
        location: '',
        position: '',
      }],
    }],
  },
  header: undefined,
  admin: false,
  removeUser: () => { },
};

EditProfile.propTypes = {
  onClose: PropTypes.func,
  saveUser: PropTypes.func,
  user: PropTypes.shape({
    _id: PropTypes.string,
    upn: PropTypes.string,
    isAdmin: PropTypes.bool,
    emergencyContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      mainPhone: PropTypes.string,
      alternatePhone: PropTypes.string,
    }),
    alternateEmails: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mainPhone: PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    alternatePhones: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    })),
    homeAddress: PropTypes.string,
    workPhone: PropTypes.string,
    extension: PropTypes.string,
    notes: PropTypes.string,
    location: PropTypes.string,
    role: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.shape({
        location: PropTypes.string,
        position: PropTypes.string,
      })),
    })),
  }),
  header: PropTypes.string,
  admin: PropTypes.bool,
  removeUser: PropTypes.func,
};
