import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useBlocker } from 'react-router-dom';
import axios from '../../../services/Api';
import KDCLogo from '../../KDCLogo';
import Card from '../../Card';
import StyledSelect from '../../StyledSelect';
import BlueButton from '../../BlueButton';
import SortableList from '../../SortableList';
import TwoButtonModal from '../../TwoButtonModal';
import AddDecisionMakersModal from '../../AddDecisionMakersModal';
import '../../../styles/components/pages/admin/DecisionMakersPage.scss';

const DecisionMakersPage = () => {
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [decisionMakersLoading, setDecisionMakersLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [workingDecisionMakers, setWorkingDecisionMakers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const { t } = useTranslation('common');

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && hasChanged),
  );

  useEffect(() => {
    // Sync working copy with master copy when master changes
    setWorkingDecisionMakers(decisionMakers);
    setHasChanged(false);
  }, [decisionMakers]);

  // Get Locations
  useEffect(() => {
    const abortController = new AbortController();
    const getLocations = async () => {
      try {
        const { data } = await axios.get('/constants/locations', { signal: abortController.signal });
        setLocations(data.map((location) => location.name));
        setLocationsLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
    };

    const getUsers = async () => {
      try {
        const { data } = await axios.get('/admin/users', { signal: abortController.signal });
        // confirm this one below
        const orderedUsers = data.map((user) => (
          {
            email: user.email,
            fullName: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            _id: user._id,
            upn: user.upn,
          }));
        orderedUsers.sort((a, b) => a.fullName.localeCompare(b.fullName));

        setAllUsers(orderedUsers);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingUsers'));
      }
    };
    getLocations();
    getUsers();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    setDecisionMakersLoading(true);
    const getDecisionMakers = async () => {
      try {
        const { data } = await axios.get(`/roll-call/decision-makers/${selectedLocation}`);
        setDecisionMakers(data);
        setDecisionMakersLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingDecisionMakers'));
      }
    };

    if (selectedLocation) {
      getDecisionMakers();
    }

    return () => {
      abortController.abort();
    };
  }, [selectedLocation]);

  const selectLocation = (location) => {
    if (location === selectedLocation) return;
    if (!hasChanged) {
      setSelectedLocation(location);
    } else {
      setNewLocation(location);
      setDiscardModalOpen(true);
    }
  };

  const updateDMs = (newDMs) => {
    setWorkingDecisionMakers(newDMs.map(
      (dm) => workingDecisionMakers.find(({ _id }) => dm.id === _id),
    ));
    setHasChanged(true);
  };

  const onSave = async () => {
    try {
      setHasChanged(false);
      await toast.promise(axios.post(
        `/admin/decision-makers/${selectedLocation}`,
        workingDecisionMakers.map((dm) => dm._id),
      ),
      {
        success: t('savedDM', { location: selectedLocation }),
        pending: t('savingDM', { location: selectedLocation }),
        error: t('errorSavingDM', { location: selectedLocation }),
      });
      setDecisionMakers(workingDecisionMakers);
    } catch {
      setHasChanged(true);
    }
  };

  const addUser = (user) => {
    setWorkingDecisionMakers([...workingDecisionMakers, {
      firstName: user.firstName,
      lastName: user.lastName,
      _id: user._id,
    }]);
    setHasChanged(true);
    setAddModalOpen(false);
  };

  return (
    <div className="decision-makers-page">
      <Card header={<div>{t('locations')}</div>}>
        <div className="select-card-body">
          {locationsLoading && <KDCLogo spinning />}
          {!locationsLoading && (
            <StyledSelect
              id="location-select"
              autoFocus={false}
              onChange={({ value }) => selectLocation(value)}
              options={locations.map((name) => ({ value: name, label: name }))}
            />
          )}
        </div>
      </Card>

      {selectedLocation && (
        <Card header={t('currentDecisionMakersForLocation', { location: selectedLocation })}>
          {decisionMakersLoading && <KDCLogo spinning />}
          {!decisionMakersLoading && (
            <SortableList
              items={workingDecisionMakers.map((dm) => ({ id: dm._id, content: `${dm.firstName} ${dm.lastName}` }))}
              updateItems={updateDMs}
              deletable
              accessability={{
                announcements: {
                  dragStart: 'dm.dragStart',
                  dragOver: 'dm.dragOver',
                  dragEnd: 'dm.dragEnd',
                  dragCancel: 'dm.dragEnd',
                },
                instructions: 'dm.instructions',
              }}
              deleteTitle="dm.remove"
              dragTitle="dm.reorder"
              className="decision-makers-list"
            />
          )}
          {!decisionMakersLoading && workingDecisionMakers.length > 0 && (
            <div className="note-container">
              {t('dragDecisionMakersNote')}
            </div>
          )}
          <div className="add-button-container">
            <BlueButton
              text={(
                <>
                  <AiOutlineUserAdd size="1.5em" />
                  <span>{t('add')}</span>
                </>
              )}
              onClick={() => setAddModalOpen(true)}
            />
          </div>
          <div className="button-container">
            <BlueButton text={t('save')} onClick={onSave} disabled={!hasChanged} />
            <BlueButton text={t('discard')} onClick={() => setDiscardModalOpen(true)} disabled={!hasChanged} />
          </div>
        </Card>
      )}
      {blocker.state === 'blocked' && (
        <TwoButtonModal
          isOpen
          redButtonFunctionality={() => blocker.proceed()}
          blueButtonFunctionality={() => blocker.reset()}
          closeFunctionality={() => blocker.reset()}
          headerText={t('moveAwayModalHeader')}
          redButtonText={t('leave')}
          blueButtonText={t('stay')}
        />
      )}
      <TwoButtonModal
        isOpen={discardModalOpen}
        redButtonFunctionality={() => {
          if (newLocation) {
            setSelectedLocation(newLocation);
            setNewLocation('');
          } else {
            setWorkingDecisionMakers(decisionMakers);
            setHasChanged(false);
          }
          setDiscardModalOpen(false);
        }}
        blueButtonFunctionality={() => {
          setDiscardModalOpen(false);
          setNewLocation('');
        }}
        closeFunctionality={() => {
          setDiscardModalOpen(false);
          setNewLocation('');
        }}
        headerText={t('discardModalText')}
        redButtonText={t('discard')}
        blueButtonText={t('cancel')}
      />
      <AddDecisionMakersModal
        isOpen={addModalOpen}
        closeFunctionality={() => setAddModalOpen(false)}
        location={selectedLocation}
        users={allUsers.filter((x) => !workingDecisionMakers.some((y) => y._id === x._id))}
        addFunctionality={addUser}
      />

    </div>
  );
};

export default DecisionMakersPage;
