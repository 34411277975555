import React, {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IoIosArrowForward } from 'react-icons/io';
import axios from '../../../services/Api';
import SearchBar from '../../SearchBar';
import Card from '../../Card';
import ITRequirementsModal from '../../ITRequirementsModal';
import highlight from '../../../helpers/searchHighlight';

import '../../../styles/components/pages/admin/ITRequirementsPage.scss';
import editBtn from '../../../assets/images/profile-page/editBtn.svg';

/** Directory Page */
const ITRequirementsPage = () => {
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [allRequirements, setAllRequirements] = useState([]);
  const [displayedRequirements, setDisplayedRequirements] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [requirement, setRequirement] = useState({
    _id: '',
    name: '',
  });

  const addRequirement = () => {
    setRequirement({
      _id: '',
      name: '',
    });
    setOpenModal(true);
  };

  const updateRequirements = (workingRequirement, deleted) => {
    const updated = allRequirements.find((req) => req._id === workingRequirement._id);
    if (deleted) {
      const updatedRequirements = allRequirements.filter(
        (req) => req._id !== workingRequirement._id,
      );
      setAllRequirements(updatedRequirements);
      setDisplayedRequirements(updatedRequirements);
    } else if (updated) {
      const updatedRequirements = allRequirements.map(
        (req) => (req._id === workingRequirement._id
          ? { _id: workingRequirement._id, name: workingRequirement.name }
          : { _id: req._id, name: req.name }),
      );
      updatedRequirements.sort((a, b) => a.name.localeCompare(b.name));
      setAllRequirements(updatedRequirements);
      setDisplayedRequirements(updatedRequirements);
    } else {
      const updatedRequirements = [...allRequirements,
        { _id: workingRequirement._id, name: workingRequirement.name }];
      updatedRequirements.sort((a, b) => a.name.localeCompare(b.name));
      setAllRequirements(updatedRequirements);
      setDisplayedRequirements(updatedRequirements);
    }
    setSearch('');
  };

  const closeModal = () => {
    setOpenModal(false);
    setRequirement({
      _id: '',
      name: '',
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadRequirements = async () => {
      try {
        const { data } = await axios.get('/internalITRequirements', { signal: abortController.signal });
        const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
        setAllRequirements(sortedData);
        setDisplayedRequirements(sortedData);
        setIsPageLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingITRequirements'));
      }
    };
    loadRequirements();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (search && search.trim().length >= 2) {
      const searchResults = allRequirements.filter((x) => (
        x?.name.toLowerCase().includes(search?.toLowerCase())));
      setDisplayedRequirements(searchResults);
    } else {
      setDisplayedRequirements(allRequirements);
    }
  }, [search]);

  return (
    <div className="it-requirements-main">
      <Card
        className="it-requirements-main-card"
        header={(
          <div className="it-requirements-main-header">
            <h2>{t('searchITRequirement')}</h2>
          </div>
            )}
      >
        <div className="filters-container">
          <SearchBar
            placeholder={t('search')}
            label={t('searchITRequirement')}
            id="requirementSearch"
            type="text"
            value={search}
            onChange={setSearch}
          />
        </div>
      </Card>
      <Card
        className="it-requirements-main-card"
        header={(
          <div className="it-requirements-main-header">
            <h2>{t('itRequirements')}</h2>

            <button type="button" onClick={addRequirement}>
              <span>{t('addPlus')}</span>
            </button>

          </div>
            )}
      >
        {!isPageLoading
              && (
              <div className="body">
                {displayedRequirements.map((req) => (
                  <div className="single-requirement" key={req._id}>
                    <h3>{highlight(req.name, search.trim().length >= 2 ? search : '')}</h3>

                    <button
                      className="right-side"
                      type="button"
                      data-testid="edit-pencil"
                      onClick={() => {
                        setRequirement(req);
                        setOpenModal(true);
                      }}
                    >
                      <img className="edit-pencil" src={editBtn} alt={t('editITRequirementButton')} />
                      <IoIosArrowForward title={t('editITRequirementArrow')} />

                    </button>

                  </div>
                ))}
              </div>
              )}
      </Card>

      {openModal
      && (
      <ITRequirementsModal
        isOpen
        closeFunctionality={() => closeModal()}
        headerText={requirement._id ? t('editITRequirement') : t('addITRequirement')}
        requirement={requirement}
        updateRequirements={updateRequirements}
      />
      )}
    </div>
  );
};

export default ITRequirementsPage;
