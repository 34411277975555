import React from 'react';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { FiPhone } from 'react-icons/fi';
import PropTypes from 'prop-types';
import sanitizeHTML from 'sanitize-html';
import '../styles/components/DirectoryItem.scss';
import formatPhoneNumber from '../helpers/phone';

const DirectoryItemMulti = ({
  header, phone, check, setCheck, isArrow, handleCard,
}) => {
  const { t } = useTranslation();
  const phoneTypes = [
    {
      display: t('home'),
      value: 'home',
    },
    {
      display: t('workCell'),
      value: 'work cell',
    },
    {
      display: t('personalCell'),
      value: 'personal cell',
    },
    {
      display: t('cottage'),
      value: 'cottage',
    },
    {
      display: t('spouse'),
      value: 'spouse',
    },
    {
      display: t('Other'),
      value: 'other',
    },
  ];
  const displayPhoneType = phoneTypes.find((phoneType) => phoneType.value === phone?.type);
  return (
    <div className="directory-item">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setCheck();
        }}
        className="check-button"
      >
        <div className="left-side">
          <div className="check-icon-container">
            {check ? <FaRegCheckCircle title={t('selected')} /> : <FaRegCircle title={t('notSelected')} />}
          </div>
          <h3>{header}</h3>
        </div>
      </button>
      <div className="right-side">
        {phone?.number && (
          <div>
            <h3>
              {phone.type && `${displayPhoneType.display.toUpperCase()}:`}
            </h3>
            <a href={sanitizeHTML(`tel:${phone.number}}`)}>
              <h3>
                {phone.extension
                  ? `${formatPhoneNumber(phone.number)} ${t('ext')} ${phone.extension}`
                  : formatPhoneNumber(phone.number)}
              </h3>
              <FiPhone title={t('callNow')} />
            </a>
          </div>
        )}
        {isArrow && (
        <button type="button" onClick={() => handleCard()}>
          <IoIosArrowForward title={t('goToContact')} />
        </button>
        )}
      </div>
    </div>
  );
};

DirectoryItemMulti.defaultProps = {
  header: '',
  phone: undefined,
  check: false,
  setCheck: () => { },
  isArrow: false,
  handleCard: () => { },
};

DirectoryItemMulti.propTypes = {
  header: PropTypes.string,
  phone: PropTypes.shape({
    type: PropTypes.string,
    number: PropTypes.string,
    extension: PropTypes.string,
  }),
  check: PropTypes.bool,
  setCheck: PropTypes.func,
  isArrow: PropTypes.bool,
  handleCard: PropTypes.func,
};

export const DirectoryItemSingle = ({ header, onClick }) => (
  <div className="directory-item">
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="directory-single-button"
    >
      <h3>{header}</h3>
    </button>
  </div>
);
DirectoryItemSingle.defaultProps = {
  header: '',
  onClick: () => { },
};

DirectoryItemSingle.propTypes = {
  header: PropTypes.string,
  onClick: PropTypes.func,
};

export default DirectoryItemMulti;
