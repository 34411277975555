import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditProfile from './EditProfile';
import axios from '../services/Api';

/** User database card */
const UserDatabaseCard = ({
  user, onClose, removeUser, handleFetchDirectory,
}) => {
  const { t } = useTranslation('common');

  const saveUser = async (userData) => {
    const postURL = userData._id ? `/admin/user/update/${userData._id}` : '/admin/user/register';
    await axios.post(postURL, userData);
    await handleFetchDirectory();
  };

  return (
    <EditProfile
      user={user}
      header={user._id ? `${user.firstName} ${user.lastName}` : t('newUser')}
      saveUser={saveUser}
      onClose={onClose}
      removeUser={removeUser}
      admin
    />

  );
};
UserDatabaseCard.defaultProps = {
  removeUser: () => {},
  handleFetchDirectory: () => {},
};

UserDatabaseCard.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    isAdmin: PropTypes.bool,
    emergencyContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      mainPhone: PropTypes.string,
      alternatePhone: PropTypes.string,
    }),
    alternateEmails: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mainPhone: PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    alternatePhones: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    })),
    homeAddress: PropTypes.string,
    workPhone: PropTypes.string,
    extension: PropTypes.string,
    notes: PropTypes.string,
    role: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  removeUser: PropTypes.func,
  handleFetchDirectory: PropTypes.func,
};

export default UserDatabaseCard;
