import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBlocker } from 'react-router-dom';
import Card from '../../Card';
import BlueButton from '../../BlueButton';
import downloadDocument from '../../../hooks/downloadDocumentHook';
import axios from '../../../services/Api';
import TwoButtonModal from '../../TwoButtonModal';
import CustomFileUploader from '../../CustomFileUploader';
import updateToast from '../../../services/toastService';
import '../../../styles/components/pages/admin/DocumentsPage.scss';

/**
 * Documents Page for admins
 * @returns JSX
 */
const DocumentsPage = () => {
  const { t } = useTranslation();
  const [doesFileExist, setDoesFileExist] = useState(false);
  const [newDoc, setNewDoc] = useState(null);
  const [docToUpload, setDocToUpload] = useState({});
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [saveCalled, setSaveCalled] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && newDoc && !saveCalled),
  );

  useEffect(() => {
    if (newDoc) {
      const doc = {};
      doc.formData = new FormData();
      doc.formData.append('file', newDoc, newDoc.name);
      setDocToUpload(doc);
    }
  }, [newDoc]);

  useEffect(() => {
    const abortController = new AbortController();
    const getMediaDosAndDontFile = async () => {
      try {
        const data = await axios.get('/constants/document/media-dos-and-donts', {
          signal: abortController.signal,
        });
        if (data) {
          setDoesFileExist(true);
        }
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('mediaFileError'));
      }
    };

    getMediaDosAndDontFile();

    return () => {
      abortController.abort();
    };
  }, []);

  /**
     * Saves the uploaded document
     */
  const saveDoc = async () => {
    try {
      await toast.promise(axios.post('/admin/constants/document/media-dos-and-donts', docToUpload.formData), {
        pending: t('mediaFilePending'),
        success: t('mediaFileSuccess'),
        error: t('mediaFileErrorUpload'),
      });
      setNewDoc(null);
    } catch (error) {
      // error handled by toast
    }
    setSaveCalled(true);
  };

  /**
     * Downloads the media file
     */
  const fetchMediaDoc = async () => {
    await setIsDownloadLoading(true);
    const toastId = toast.loading(t('downloadInProgress'));
    try {
      await downloadDocument('/constants/document/media-dos-and-donts', 'MediaDosAndDonts');
      updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  return (
    <>
      <Card header={t('mediaDosandDonts')} className="documents-page">
        <div className="file-upload-div">
          <div className="file-name">
            {doesFileExist ? (
              <div
                className="collapsible-row"
                role="button"
                tabIndex="0"
                onKeyDown={() => {
                  if (!isDownloadLoading) {
                    fetchMediaDoc();
                  }
                }}
                onClick={() => {
                  if (!isDownloadLoading) {
                    fetchMediaDoc();
                  }
                }}
              >
                <p className="media-url">{`${t('mediaDosandDonts')}.pdf`}</p>
              </div>
            ) : <p className="file">{t('pdfFile')}</p>}
          </div>
          <div className="file-actions">
            <CustomFileUploader doc={newDoc} setDoc={setNewDoc} />
          </div>

        </div>
        <div className="save-button">
          <BlueButton
            text={t('save')}
            onClick={() => {
              setSaveModalOpen(true);
            }}
            disabled={isDownloadLoading || !newDoc}
          />
        </div>
      </Card>
      <TwoButtonModal
        isOpen={saveModalOpen}
        redButtonFunctionality={() => { setSaveModalOpen(false); saveDoc(); }}
        blueButtonFunctionality={() => setSaveModalOpen(false)}
        closeFunctionality={() => setSaveModalOpen(false)}
        headerText={t('saveMediaDosDonts')}
        redButtonText={t('yes')}
        blueButtonText={t('cancel')}
      />
      {blocker.state === 'blocked' && (
        <TwoButtonModal
          isOpen
          redButtonFunctionality={() => blocker.proceed()}
          blueButtonFunctionality={() => blocker.reset()}
          closeFunctionality={() => blocker.reset()}
          headerText={t('moveAwayModalHeader')}
          redButtonText={t('leave')}
          blueButtonText={t('stay')}
        />
      )}
    </>

  );
};

export default DocumentsPage;
