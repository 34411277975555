import axios from '../services/Api';
import Role from '../enums/Role';

/**
 *  Fetches directory based on access filters and page
 * */

export const getDirectory = async (page, filters, hasAccess, abortSignal) => {
  const {
    location, name, role, businessTeam, isAdminFilter,
  } = filters;
  let firstParam = true;
  let urlWithParameters = hasAccess ? `/directory/${page}?` : `/contacts/${page}?`;

  if (location?.length) {
    location.forEach((l) => {
      if (!firstParam) urlWithParameters += '&';
      urlWithParameters += `location[]=${l}`;
      firstParam = false;
    });
  }

  if (role?.length) {
    role.forEach((r) => {
      if (r !== Role.AdminPortalAdministrator) {
        if (!firstParam) urlWithParameters += '&';
        urlWithParameters += `role[]=${r}`;
        firstParam = false;
      }
    });
  }

  if (name) {
    if (!firstParam) urlWithParameters += '&';
    urlWithParameters += `name=${name}&phone=${name}`;
    firstParam = false;
  }

  if (hasAccess && businessTeam?.length) {
    businessTeam.forEach((bt) => {
      if (!firstParam) urlWithParameters += '&';
      urlWithParameters += `businessTeam[]=${bt}`;
      firstParam = false;
    });
  }

  if (isAdminFilter) {
    if (!firstParam) urlWithParameters += '&';
    urlWithParameters += 'isAdmin=true';
    firstParam = false;
  }
  const { data } = await axios.get(urlWithParameters, { signal: abortSignal });
  return (data);
};

/**
 * fetches the rest of directory after a certain page
 */

export const getDirectoryRest = async (page, filters, hasAccess, abortSignal) => {
  const {
    location, name, role, isAdminFilter,
  } = filters;
  let firstParam = true;
  let urlWithParameters = hasAccess ? `/directory/all/${page}?` : `/contacts/all/${page}?`;

  if (location?.length) {
    location.forEach((l) => {
      if (!firstParam) urlWithParameters += '&';
      urlWithParameters += `location[]=${l}`;
      firstParam = false;
    });
  }

  if (role?.length) {
    role.forEach((r) => {
      if (r !== Role.AdminPortalAdministrator) {
        if (!firstParam) urlWithParameters += '&';
        urlWithParameters += `role[]=${r}`;
        firstParam = false;
      }
    });
  }

  if (name) {
    if (!firstParam) urlWithParameters += '&';
    urlWithParameters += `name=${name}&phone=${name}`;
    firstParam = false;
  }

  if (isAdminFilter) {
    if (!firstParam) urlWithParameters += '&';
    urlWithParameters += 'isAdmin=true';
    firstParam = false;
  }

  const { data } = await axios.get(urlWithParameters, { signal: abortSignal });
  return (data);
};
