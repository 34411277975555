import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faPlusCircle,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import axios from '../../../services/Api';
import TwoButtonModal from '../../TwoButtonModal';
import Card from '../../Card';
import CircledButton from '../../CircledButton';
import InputComponent from '../../InputComponent';
import InputSelector from '../../InputSelector';
import BlueButton from '../../BlueButton';
import KDCLogo from '../../KDCLogo';
import useUserList from '../../../hooks/useUserList';
import '../../../styles/components/pages/admin/EditBusinessTeamPage.scss';

const EditBusinessTeamPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  // default users for Location lead/alt incase no users have a BT role for a given location
  const defaultUsers = [{ _id: 'None', firstName: 'None', lastName: '' }, { _id: 'Pending', firstName: 'Pending', lastName: '' }];
  const [businessTeam, setBusinessTeam] = useState({
    _id: null,
    team: '',
    locations: [],
    avps: [],
    executives: [],
    vpAlternates: [],
    vpLeads: [],
  });
  const [businessTeamChanges, setBusinessTeamChanges] = useState(businessTeam);
  const [users, isUsersLoading] = useUserList();
  const [avps, setAvps] = useState([]);
  const [execs, setExecs] = useState([]);
  const [vps, setVps] = useState([]);
  const [locations, setLocations] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname
      && JSON.stringify(businessTeam) !== JSON.stringify(businessTeamChanges)),
  );

  const validBusinessTeam = () => {
    if (businessTeamChanges.name === '') { return false; }
    const isValidLocs = businessTeamChanges.locations.every((loc) => (loc.location !== '' && loc.lead !== '' && loc.alt !== ''));
    if (!isValidLocs) { return false; }
    if (businessTeamChanges.avps.some((avp) => avp === '')) { return false; }
    if (businessTeamChanges.executives.some((exec) => exec === '')) { return false; }
    if (businessTeamChanges.vpLeads.some((vpl) => vpl === '')) { return false; }
    if (businessTeamChanges.vpAlternates.some((vpa) => vpa === '')) { return false; }
    return true;
  };

  const invalidLocations = () => {
    const verifiedLocs = [];
    let invalidLoc = false;
    businessTeamChanges.locations.forEach((loc) => {
      // if a duplicate location exists, check if it has the same lead or alt
      const dupLocations = verifiedLocs.filter((vLoc) => vLoc.location === loc.location);
      dupLocations.forEach((vLoc) => {
        if (!defaultUsers.some((user) => user._id === vLoc.lead) && vLoc.lead === loc.lead) {
          invalidLoc = true;
        }
        if (!defaultUsers.some((user) => user._id === vLoc.alt) && vLoc.alt === loc.alt) {
          invalidLoc = true;
        }
      });
      verifiedLocs.push(loc);
    });
    return invalidLoc || businessTeamChanges.locations.some(
      // if lead is NOT a default user AND lead = alt, throw error
      (loc) => (!defaultUsers.some((user) => user._id === loc.lead) && (loc.lead === loc.alt)),
    );
  };

  const handleSubmit = async (e) => {
    if (!validBusinessTeam()) {
      toast.error(t('emptyFields'));
      return;
    }
    if (invalidLocations()) {
      toast.error(t('invalidBusinessTeam'));
      return;
    }

    const abortController = new AbortController();
    const tempBT = businessTeam;
    try {
      setBusinessTeam(businessTeamChanges);
      const body = businessTeam._id
        ? { team: businessTeam, updatedTeam: businessTeamChanges } : businessTeamChanges;
      const endpoint = businessTeam._id ? '/admin/teams/update' : '/admin/teams/create';
      await toast.promise(axios.post(endpoint, body, { signal: abortController.signal }), {
        pending: businessTeam._id ? t('updatingBusinessTeam') : t('creatingBusinessTeam'),
        success: businessTeam._id ? t('businessTeamUpdated') : t('businessTeamCreated'),
        error: businessTeam._id ? t('businessTeamUpdatedError') : t('businessTeamCreatedError'),
      });
    } catch (err) {
      setBusinessTeam(tempBT);
    }
    navigate(-1);
    e.stopPropagation();
  };

  const handleDelete = async (e) => {
    const abortController = new AbortController();
    const tempBT = businessTeam;
    try {
      setBusinessTeam(businessTeamChanges);
      await toast.promise(axios.post('/admin/users/deleteBusinessTeam', { id: businessTeam._id, team: businessTeam.team }, { signal: abortController.signal }), {
        pending: t('deletingBusinessTeam'),
        success: t('businessTeamDeleted'),
        error: t('errorDeletingBusinessTeam'),
      });
    } catch (err) {
      setBusinessTeam(tempBT);
    }
    navigate(-1);
    e.stopPropagation();
  };

  const addLocation = () => {
    setBusinessTeamChanges({
      ...businessTeamChanges,
      locations: [...businessTeamChanges.locations, { location: '', lead: '', alt: '' }],
    });
  };

  const updateBusinessTeamLocations = (i, newLoc) => {
    const newLocs = businessTeamChanges.locations.map((loc, j) => {
      if (i === j) { return newLoc; }
      return loc;
    });
    setBusinessTeamChanges({ ...businessTeamChanges, locations: newLocs });
  };

  const getLocationUsers = (index, locationName, role) => {
    const locUsers = users.filter(
      (user) => user.role.some((r) => r.locations?.some(
        (loc) => loc.team === businessTeamChanges.team,
      ) || (r.team && r.team === businessTeamChanges.team)),
    );
    // need to filter users who are in the same role at a different location with the same address
    const tempUsers = locUsers.filter((user) => !businessTeamChanges.locations.some(
      (loc, j) => index !== j && loc.location === locationName && loc[role] === user._id,
    ));
    // need to filter users who are already a lead/alt at the same location
    if (role === 'alt') return tempUsers.filter((user) => businessTeamChanges.locations[index].lead !== user._id);
    return tempUsers.filter((user) => businessTeamChanges.locations[index].alt !== user._id);
  };

  const getAvailableUsers = (role, index) => {
    let roleArray = [];
    if (role === 'avps') roleArray = avps;
    if (role === 'vpLeads' || role === 'vpAlternates') roleArray = vps;
    if (role === 'executives') roleArray = execs;

    const tempUsers = roleArray.filter(
      (user) => !businessTeamChanges[role].some(
        (selUser, j) => selUser === user._id && index !== j,
      ),
    );
    return tempUsers.map((u) => ({ display: `${u.firstName} ${u.lastName}`, value: u._id }));
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchRelevantUsers = async () => {
      try {
        const tempAVPs = [];
        const tempVPs = [];
        const tempExecs = [];
        users.forEach((user) => {
          if (user.role.some((role) => role.name === 'Vice President')) tempVPs.push(user);
          if (user.role.some((role) => role.name === 'Executive')) tempExecs.push(user);
          if (user.role.some((role) => role.name === 'Associate Vice President')) tempAVPs.push(user);
        });
        setAvps(tempAVPs);
        setVps(tempVPs);
        setExecs(tempExecs);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingUsers'));
      }
    };

    const fetchLocations = async () => {
      let data = [];
      try {
        const res = await axios.get('/constants/locations', { signal: abortController.signal });
        data = res.data;
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
      const updatedFields = data.map((loc) => ({
        location: loc.name,
        lead: loc.lead,
        alt: loc.alt,
        leadAltOptions: defaultUsers,
      }));
      setLocations(updatedFields);
    };

    const fetchBusinessTeam = async () => {
      let data = [];
      try {
        const res = await axios.get('/admin/teams', { signal: abortController.signal });
        data = res.data;
        const matches = (data.filter((bt) => bt._id === id));
        if (matches.length === 1) {
          // checks for a valid ID in the URL param; if invalid, serve the 'new BT' version page
          setBusinessTeam(matches[0]);
          setBusinessTeamChanges(matches[0]);
        }
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingTeams'));
      }
    };

    Promise.all([
      fetchBusinessTeam(),
      fetchLocations(),
      fetchRelevantUsers(),
    ]).then(setIsLoading(false));

    return (() => {
      abortController.abort();
    });
  }, [isUsersLoading]);

  return ((isLoading || isUsersLoading) ? <KDCLogo spinning /> : (
    <div className="edit-business-team">
      <Card header={(
        <div className="profile-header-edit">
          <div>{businessTeam.team === '' ? t('addNewBusinessTeam') : businessTeam.team}</div>
          <div>
            <CircledButton onClick={handleSubmit}>
              <FontAwesomeIcon icon={faCheck} title={t('updateBusinessTeam')} />
            </CircledButton>
            <CircledButton onClick={() => navigate('/admin/business-teams')}>
              <FontAwesomeIcon icon={faX} title={t('cancelChanges')} />
            </CircledButton>
          </div>
        </div>
      )}
      >
        <div className="business-team-input">
          <InputComponent
            header={t('businessTeamName')}
            onChange={(change) => {
              setBusinessTeamChanges({ ...businessTeamChanges, team: change });
            }}
            value={businessTeamChanges.team}
          />
          {businessTeamChanges.team === '' && <p className="error-text">{t('requiredError', { header: t('businessTeamName') })}</p>}

          <h2 className="business-team-title medium-text">{t('locations')}</h2>
          <div className="location-headers">
            <h2 className="medium-text">{t('location')}</h2>
            <h2 className="medium-text">{t('lead')}</h2>
            <h2 className="medium-text">{t('alternate')}</h2>
          </div>
          {businessTeamChanges.locations?.length > 0
          && businessTeamChanges.locations.map((loc, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field-form locations-field" key={`location-form-${i}`}>
              <div className="field">
                <InputSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`location-${i}`}
                  onChange={
                    (change) => { updateBusinessTeamLocations(i, { ...loc, location: change }); }
                  }
                  value={loc.location}
                  options={locations.map(
                    (l) => ({ display: l.location, value: l.location }),
                  )}
                />
                {loc.location === '' && <p className="error-text">{t('requiredError', { header: t('location') })}</p>}
              </div>
              <div className="field">
                <InputSelector
                  onChange={
                    (change) => { updateBusinessTeamLocations(i, { ...loc, lead: change }); }
                  }
                  value={loc.lead}
                  options={defaultUsers.concat(getLocationUsers(i, loc.location, 'lead')).map((user) => ({ display: `${user.firstName} ${user.lastName}`, value: user._id }))}
                />
                {loc.lead === '' && <p className="error-text">{t('requiredError', { header: t('lead') })}</p>}
              </div>
              <div className="field">
                <InputSelector
                  onChange={
                    (change) => { updateBusinessTeamLocations(i, { ...loc, alt: change }); }
                  }
                  value={loc.alt}
                  options={defaultUsers.concat(getLocationUsers(i, loc.location, 'alt')).map((user) => ({ display: `${user.firstName} ${user.lastName}`, value: user._id }))}
                />
                {loc.alt === '' && <p className="error-text">{t('requiredError', { header: t('alternate') })}</p>}
              </div>
              <CircledButton onClick={() => {
                const newLocs = businessTeamChanges.locations.filter((_, j) => j !== i);
                setBusinessTeamChanges({ ...businessTeamChanges, locations: newLocs });
              }}
              >
                <FaRegTrashAlt title={t('removeLocation')} />
              </CircledButton>
            </div>
          ))}
          <button type="button" className="add-field-btn" onClick={addLocation}>
            {t('newLocation')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('newLocation')} />
          </button>
          {invalidLocations() && <p className="error-text">{t('businessTeamDuplicateLeadsOrAlts')}</p>}

          <h2 className="business-team-title medium-text">{t('avp')}</h2>
          {businessTeamChanges.avps.map((avp, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field-form" key={`avp-form-${i}`}>
              <div className="field">
                <InputSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`avp-${i}`}
                  onChange={(change) => {
                    const newAVPs = businessTeamChanges.avps;
                    newAVPs[i] = change;
                    setBusinessTeamChanges({ ...businessTeamChanges, avps: newAVPs });
                  }}
                  value={avp}
                  options={getAvailableUsers('avps', i)}
                />
                {avp === '' && <p className="error-text">{t('requiredError', { header: t('avp') })}</p>}
              </div>
              <CircledButton onClick={() => {
                const newAVPs = businessTeamChanges.avps.filter((_, j) => j !== i);
                setBusinessTeamChanges({ ...businessTeamChanges, avps: newAVPs });
              }}
              >
                <FaRegTrashAlt title={t('removeAVP')} />
              </CircledButton>
            </div>
          ))}
          <button
            type="button"
            className="add-field-btn"
            onClick={() => {
              setBusinessTeamChanges({ ...businessTeamChanges, avps: [...businessTeamChanges.avps, ''] });
            }}
          >
            {t('newAVP')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('newAVP')} />
          </button>

          <h2 className="business-team-title medium-text">{t('vpLead')}</h2>
          {businessTeamChanges.vpLeads.map((vp, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field-form" key={`vp-lead-form-${i}`}>
              <div className="field">
                <InputSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`vpLead-${i}`}
                  onChange={(change) => {
                    const newVPLs = businessTeamChanges.vpLeads;
                    newVPLs[i] = change;
                    setBusinessTeamChanges({ ...businessTeamChanges, vpLeads: newVPLs });
                  }}
                  value={vp}
                  options={getAvailableUsers('vpLeads', i)}
                />
                {vp === '' && <p className="error-text">{t('requiredError', { header: t('vp') })}</p>}
              </div>
              <CircledButton onClick={() => {
                const newVPLeads = businessTeamChanges.vpLeads.filter((_, j) => j !== i);
                setBusinessTeamChanges({ ...businessTeamChanges, vpLeads: newVPLeads });
              }}
              >
                <FaRegTrashAlt title={t('removeVPLead')} />
              </CircledButton>
            </div>
          ))}
          <button
            type="button"
            className="add-field-btn"
            onClick={() => {
              setBusinessTeamChanges({ ...businessTeamChanges, vpLeads: [...businessTeamChanges.vpLeads, ''] });
            }}
          >
            {t('newVPLead')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('newVPLead')} />
          </button>

          <h2 className="business-team-title medium-text">{t('vpAlternate')}</h2>
          {businessTeamChanges.vpAlternates.map((vp, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field-form" key={`vp-alternate-form-${i}`}>
              <div className="field">
                <InputSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`vpAlt-${i}`}
                  onChange={(change) => {
                    const newVPAlts = businessTeamChanges.vpAlternates;
                    newVPAlts[i] = change;
                    setBusinessTeamChanges({ ...businessTeamChanges, vpAlternates: newVPAlts });
                  }}
                  value={vp}
                  options={getAvailableUsers('vpAlternates', i)}
                />
                {vp === '' && <p className="error-text">{t('requiredError', { header: t('vp') })}</p>}
              </div>
              <CircledButton onClick={() => {
                const newVPAlts = businessTeamChanges.vpAlternates.filter((_, j) => j !== i);
                setBusinessTeamChanges({ ...businessTeamChanges, vpAlternates: newVPAlts });
              }}
              >
                <FaRegTrashAlt title={t('removeVPAlternate')} />
              </CircledButton>
            </div>
          ))}
          <button
            type="button"
            className="add-field-btn"
            onClick={() => {
              setBusinessTeamChanges({ ...businessTeamChanges, vpAlternates: [...businessTeamChanges.vpAlternates, ''] });
            }}
          >
            {t('newVPAlternate')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('newVPAlternate')} />
          </button>

          <h2 className="business-team-title medium-text">{t('executive')}</h2>
          {businessTeamChanges.executives.map((exec, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="field-form" key={`executive-form-${i}`}>
              <div className="field">
                <InputSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`exec-${i}`}
                  onChange={(change) => {
                    const newExecs = businessTeamChanges.executives;
                    newExecs[i] = change;
                    setBusinessTeamChanges({ ...businessTeamChanges, executives: newExecs });
                  }}
                  value={exec}
                  options={getAvailableUsers('executives', i)}
                />
                {exec === '' && <p className="error-text">{t('requiredError', { header: t('executive') })}</p>}
              </div>
              <CircledButton onClick={() => {
                const newExecs = businessTeamChanges.executives.filter((_, j) => j !== i);
                setBusinessTeamChanges({ ...businessTeamChanges, executives: newExecs });
              }}
              >
                <FaRegTrashAlt title={t('removeExecutive')} />
              </CircledButton>
            </div>
          ))}
          <button
            type="button"
            className="add-field-btn"
            onClick={() => {
              setBusinessTeamChanges({ ...businessTeamChanges, executives: [...businessTeamChanges.executives, ''] });
            }}
          >
            {t('newExecutive')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('newExecutive')} />
          </button>

          <div className="submit-discard-btns">
            <BlueButton onClick={handleSubmit} text={t('save')} />
            <BlueButton onClick={() => navigate('/admin/business-teams')} text={t('discard')} />
            <button type="button" className="red-button" onClick={() => setDeleteModalOpen(true)}>{t('delete')}</button>
          </div>
        </div>
      </Card>
      <TwoButtonModal
        isOpen={deleteModalOpen}
        redButtonFunctionality={(e) => handleDelete(e)}
        blueButtonFunctionality={() => setDeleteModalOpen(false)}
        closeFunctionality={() => setDeleteModalOpen(false)}
        headerText={t('deleteModalText')}
        redButtonText={t('yes')}
        blueButtonText={t('no')}
      />
      <TwoButtonModal
        isOpen={blocker.state === 'blocked'}
        redButtonFunctionality={() => blocker.proceed()}
        blueButtonFunctionality={() => blocker.reset()}
        closeFunctionality={() => blocker.reset()}
        headerText={t('discardModalText')}
        redButtonText={t('discard')}
        blueButtonText={t('cancel')}
      />
    </div>
  ));
};

export default EditBusinessTeamPage;
