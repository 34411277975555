import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaRegTrashAlt, FaPlus } from 'react-icons/fa';
import { FaX } from 'react-icons/fa6';
import Role from '../enums/Role';
import StyledSelect from './StyledSelect';

import '../styles/components/RoleItem.scss';

/** RoleItem component */
const RoleItem = ({
  role,
  locations,
  teams,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation('common');

  const allPositions = [{ value: 'Lead', label: t('lead') },
    { value: 'Alternate', label: t('alternate') },
    { value: 'Neither', label: t('neither') }];

  const columns = {
    LOCATION: 'location',
    POSITION: 'position',
    TITLE: 'title',
    TEAM: 'team',
  };

  const roleProperties = {
    [Role.AssociateVicePresident]: null,
    [Role.BusinessContinuityCoordinator]: [columns.LOCATION, columns.POSITION],
    [Role.BusinessTeam]: [columns.TEAM],
    [Role.CGLBusinessContinuityCoordinator]: null,
    [Role.CommunicationsTeam]: [columns.LOCATION, columns.POSITION],
    [Role.DecisionMaker]: [columns.LOCATION],
    [Role.Executive]: null,
    [Role.IncidentManagementTeamSupport]: [columns.TITLE, columns.LOCATION],
    [Role.InfrastructureTeam]: [columns.LOCATION, columns.POSITION],
    [Role.LogisticsTeam]: [columns.LOCATION, columns.POSITION],
    [Role.OfficeLead]: [columns.LOCATION, columns.POSITION],
    [Role.OperationsTeam]: [columns.LOCATION, columns.POSITION],
    [Role.Scribe]: [columns.LOCATION, columns.POSITION],
    [Role.VicePresident]: null,
  };

  const properties = roleProperties[role.name];

  const validateRole = (newLocations) => {
    // Title field isn't considered required
    const requiredFields = properties.filter((col) => col !== columns.TITLE);
    // Check every required field of every location has a truthy value
    return newLocations.every((location) => requiredFields.every((col) => location[col]));
  };

  const addLocation = () => {
    const newLocations = [...role.locations];
    newLocations.push({
      location: '',
      title: '',
      team: '',
      position: '',
    });
    onChange({ ...role, locations: newLocations }, false);
  };

  const removeLocation = (index) => {
    const newLocations = role.locations.filter((_, i) => i !== index);
    onChange({ ...role, locations: newLocations }, validateRole(newLocations));
  };

  const editLocation = (index, field, value) => {
    const newLocations = [...role.locations];
    newLocations[index] = {
      ...newLocations[index],
      [field]: value,
    };
    onChange({ ...role, locations: newLocations }, validateRole(newLocations));
  };

  return (
    <div className="display-roles" key={role.name}>
      <div className="user-role">
        <div className="role-name">
          <h2>{role.name}</h2>
        </div>
        <button
          type="button"
          onClick={(e) => onDelete(e, role.name)}
        >
          <FaRegTrashAlt title={t('deleteRole')} />
        </button>
      </div>
      {properties && (
      <div className="dropdown-container">
        <div className="title-headers">
          {properties.map((col, i) => {
            let className = 'small-column';
            if (properties.length === 1) {
              className = 'single-column';
            } else if (properties.filter((c) => c !== columns.TITLE)[0] === col) {
              // The first column not including the title column should be big
              className = 'big-column';
            }
            if (i === 0) {
              className = `${className} first-column`;
            }
            return <div className={className} key={col}><h2>{t(col)}</h2></div>;
          })}
        </div>

        {role.locations.map((location, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="select-properties" key={`${role.name}-${i}`}>
            {properties.map((col, j) => {
              let className = 'small-column';
              if (properties.length === 1) {
                className = 'single-column';
              } else if (properties.filter((c) => c !== columns.TITLE)[0] === col) {
                // The first column not including the title column should be big
                className = 'big-column';
              }
              if (j === 0) {
                className = `${className} first-column`;
              }
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`${col}-${i}-${j}`}>
                  {col === columns.LOCATION && (
                    <>
                      <StyledSelect
                        options={locations.filter(
                          (loc) => !role.locations.find(
                            (l, index) => l.location === loc.value && i !== index,
                          ),
                        )}
                        onChange={(e) => editLocation(i, 'location', e.value)}
                        value={locations.find(({ value }) => value === location.location)}
                        placeholderText={t('selectLocationPlaceHolder')}
                      />
                      { !location.location && <p className="err">{t('locationRequired')}</p>}
                    </>
                  )}
                  {col === columns.POSITION && (
                    <>
                      <StyledSelect
                        options={allPositions}
                        onChange={(e) => editLocation(i, 'position', e.value)}
                        value={allPositions.find(({ value }) => value === location.position)}
                        placeholderText={t('selectPositionPlaceHolder')}
                      />
                      { !location.position && <p className="err">{t('positionRequired')}</p>}
                    </>
                  )}
                  {col === columns.TEAM && (
                    <>
                      <StyledSelect
                        options={teams.filter(
                          (team) => !role.locations.find(
                            (existing, index) => existing.team === team.value && i !== index,
                          ),
                        )}
                        onChange={(e) => editLocation(i, 'team', e.value)}
                        value={teams.find(({ value }) => value === location.team)}
                        placeholderText={t('selectTeamPlaceHolder')}
                      />
                      { !location.team && <p className="err">{t('teamRequired')}</p>}
                    </>
                  )}
                  {col === columns.TITLE && (
                    <input
                      maxLength={50}
                      type="text"
                      value={location.title}
                      onChange={(e) => editLocation(i, 'title', e.target.value)}
                      className="title-input"
                    />
                  )}
                </div>
              );
            })}
            {role.locations.length > 1 && (
              <div className="delete-button">
                <button
                  type="button"
                  className="role-button"
                  onClick={() => removeLocation(i)}
                >
                  <FaX title={t('deleteProperty')} />
                </button>
              </div>
            )}
          </div>
        ))}

        <div className="add-button">
          {properties.includes(columns.LOCATION) && <h2 className="button-header">{t('addLocation')}</h2>}
          {properties.includes(columns.TEAM) && <h2 className="button-header">{t('addTeam')}</h2>}

          <button
            type="button"
            className="role-button"
            onClick={addLocation}
          >
            <FaPlus title={t('addProperty')} />
          </button>
        </div>

      </div>
      )}
    </div>
  );
};

RoleItem.defaultProps = {
  locations: [],
  teams: [],
};

RoleItem.propTypes = {
  role: PropTypes.shape({
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({
      team: PropTypes.string,
      location: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.string,
    })),
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  teams: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RoleItem;
