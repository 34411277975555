import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { PiTrashLight } from 'react-icons/pi';
import { IconContext } from 'react-icons';
import Card from '../../Card';
import KDCLogo from '../../KDCLogo';
import TwoButtonModal from '../../TwoButtonModal';
import { downloadDocumentBuffer } from '../../../hooks/downloadDocumentHook';
import axios from '../../../services/Api';
import CustomFileUploader from '../../CustomFileUploader';
import updateToast from '../../../services/toastService';
import { IconColours } from '../../icons';

import '../../../styles/components/pages/admin/BCMTemplatesPage.scss';

/**
 * BCM Templates Page for admins
 * @returns JSX
 */
const BCMTemplatesPage = () => {
  const { t } = useTranslation();
  const [downloadsLoading, setDownloadsLoading] = useState([]);
  const [deletesInProgress, setDeletesInProgress] = useState([]);
  const [statusLoading, setStatusLoading] = useState(true);
  const [fileStatuses, setFileStatuses] = useState({});
  const [isExpanded, setIsExpanded] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});

  const sections = [
    'teams',
    'bcc',
    'log',
  ];

  const files = {
    coverPage: 'Cover Page.pdf',
    tableOfContents: 'Table of Contents.pdf',
    introduction: 'Introduction.pdf',
    businessImpactAnalysis: 'Business Impact Analysis.pdf',
    recovery: 'Recovery.pdf',
    contacts: 'Contacts.pdf',
    recoveryLocations: 'Recovery Locations.pdf',
    appendicesCoverPage: 'Appendices Cover Page.pdf',
    incidentResponseChecklists: 'Appendix A: Incident Response Checklists.pdf',
    communicatios: 'Appendix B: Communication.pdf',
    alternateSiteForm: 'Appendix C: Alternate Site Form.pdf',
    procedures: 'Appendix D: Procedures.pdf',
    imtRolesAndResponsibilities: 'Appendix E: IMT Roles and Responsibilities.pdf',
    usefulResources: 'Appendix F: Useful Resources.pdf',
    glossaryOfTerms: 'Appendix G: Glossary of Terms.pdf',
  };

  useEffect(() => {
    const abortController = new AbortController();
    const getFileStatuses = async () => {
      try {
        const fileNames = Object.values(files);
        const responses = await Promise.all(sections.map(
          async (section) => axios.get(`/bcm-template/exist/${section}/${JSON.stringify(fileNames)}`, { signal: abortController.signal }),
        ));
        const data = responses.map((res) => res.data);
        setFileStatuses(sections.reduce(
          (fileStatus, section, i) => ({ ...fileStatus, [section]: data[i] }),
          {},
        ));
        setStatusLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('templateStatusError'));
      }
    };

    getFileStatuses();

    return () => {
      abortController.abort();
    };
  }, []);

  const updateFileStatus = (section, file, exists) => {
    const index = Object.values(files)?.findIndex((f) => f === file);
    const status = [...fileStatuses[section]];
    status[index] = exists;
    setFileStatuses({
      ...fileStatuses,
      [section]: status,
    });
  };

  /**
     * Saves the uploaded document
     */
  const saveDoc = async (section, file, doc) => {
    const toastId = toast.loading(t('uploadingFile', { file }));
    try {
      const buffer = await doc.arrayBuffer();
      const bytes = new Uint8Array(buffer);
      const stringData = bytes.reduce((data, byte) => `${data}${String.fromCharCode(byte)}`, '');

      await axios.post(`/admin/bcm-template/${section}/${file}`, { data: stringData });
      updateFileStatus(section, file, true);
      updateToast(toastId, t('uploadedFile', { file }), toast.TYPE.SUCCESS);
    } catch (error) {
      updateToast(toastId, t('uploadFileError', { file }), toast.TYPE.ERROR);
    }
  };

  const onDeleteClick = (section, file) => {
    setFileToDelete({
      section,
      file,
    });
    setIsDeleteModalOpen(true);
  };

  const isDeleteInprogress = (section, file) => deletesInProgress.includes(`${section}/${file}`);

  const startDelete = (section, file) => {
    setDeletesInProgress((oldState) => [...oldState, `${section}/${file}`]);
  };

  const finishDelete = (section, file) => {
    setDeletesInProgress((oldState) => oldState.filter((deletion) => deletion !== `${section}/${file}`));
  };

  /**
   * Delete a template file
   */
  const deleteDoc = async () => {
    const { section, file } = fileToDelete;
    startDelete(section, file);
    const toastId = toast.loading(t('removingFile', { file }));
    setIsDeleteModalOpen(false);
    try {
      await axios.delete(`/admin/bcm-template/${section}/${file}`);
      updateFileStatus(section, file, false);
      updateToast(toastId, t('removedFile', { file }), toast.TYPE.SUCCESS);
    } catch (error) {
      updateToast(toastId, t('removeFileError', { file }), toast.TYPE.ERROR);
    } finally {
      finishDelete(section, file);
    }
  };

  const isDownloadInprogress = (section, file) => downloadsLoading.includes(`${section}/${file}`);

  const startDownload = (section, file) => {
    setDownloadsLoading((oldState) => [...oldState, `${section}/${file}`]);
  };

  const finishDownload = (section, file) => {
    setDownloadsLoading((oldState) => oldState.filter((download) => download !== `${section}/${file}`));
  };

  /**
     * Downloads the template file
     */
  const downloadTemplateFile = async (section, file) => {
    startDownload(section, file);
    const toastId = toast.loading(t('downloadingFile', { file }));
    try {
      const { data } = await axios.get(`/bcm-template/${section}/${file}`);
      if (data.bufferObject?.data) {
        const buf = new Uint8Array(data.bufferObject.data.data);
        await downloadDocumentBuffer(buf, file, 'application/pdf');
      }
      updateToast(toastId, t('downloadedFile', { file }), toast.TYPE.SUCCESS);
    } catch (err) {
      updateToast(toastId, t('downloadFileError', { file }), toast.TYPE.ERROR);
    } finally {
      finishDownload(section, file);
    }
  };

  const toggleIsExpanded = (section) => {
    setIsExpanded({
      ...isExpanded,
      [section]: !isExpanded[section],
    });
  };

  return (
    <div className="bcm-templates-page">
      {statusLoading && <KDCLogo spinning />}
      {!statusLoading && sections.map((section) => (
        <Card
          header={t(`${section}PlanTemplates`)}
          key={section}
          functionalButton={(
            <button type="button" className="section-header-button" onClick={() => toggleIsExpanded(section)}>
              <IconContext.Provider value={{ color: IconColours.WHITE }}>
                {isExpanded[section] && <IoIosArrowUp title={t(`${section}PlanTemplatesCollapse`)} />}
                {!isExpanded[section] && <IoIosArrowDown title={t(`${section}PlanTemplatesExpand`)} />}
              </IconContext.Provider>
            </button>
          )}
        >
          <>
            {isExpanded[section] && (
              <div className="section-contents">
                {Object.entries(files).map(([key, file], i) => {
                  const fileExists = fileStatuses[section][i];
                  return (
                    <Fragment key={`${section}-${key}`}>
                      <div>{t(key)}</div>
                      {
                      fileExists
                        ? (
                          <button
                            type="button"
                            className="download-button"
                            disabled={isDownloadInprogress(section, file)}
                            onClick={() => downloadTemplateFile(section, file)}
                          >
                            {file}
                          </button>
                        ) : <CustomFileUploader data-testid={`${section}-${key}`} setDoc={(doc) => saveDoc(section, file, doc)} />
                      }
                      <div>
                        {fileExists && !isDeleteInprogress(section, file) && (
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => onDeleteClick(section, file)}
                            aria-label={t('removeFile', { file })}
                          >
                            <PiTrashLight color={IconColours.BLUE} />
                          </button>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            )}
          </>
        </Card>
      ))}
      {!statusLoading && (
        <TwoButtonModal
          isOpen={isDeleteModalOpen}
          redButtonFunctionality={deleteDoc}
          blueButtonFunctionality={() => setIsDeleteModalOpen(false)}
          closeFunctionality={() => setIsDeleteModalOpen(false)}
          headerText={t('removeBCMTemplate', fileToDelete)}
          redButtonText={t('remove')}
          blueButtonText={t('cancel')}
        />
      )}
    </div>

  );
};

export default BCMTemplatesPage;
