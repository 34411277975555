/**
 * Takes a search query and returns a list of filtered results
 * @param {String} searchQuery string given by the user via a searchbox
 * @param {Array[String]} searchFields array of object fields to be searched using the searchQuery.
 * Child objects and arrays of child objects can be searched for using the
 * `.` delimiter between fields. E.g. 'commandCenters.name' looks for matches
 * in the child object `commandCenters` with the field `name`.
 * @param {Array[Object]} input The given JSON data to be searched
 */
const searchHelper = (searchQuery = '', searchFields, input) => {
  if (searchQuery !== '' && input.length > 0) {
    const searchRegex = new RegExp(searchQuery, 'i');

    const hasVal = (s, field, obj) => {
      // check if the given field of an object has a value matching the search
      if (Array.isArray(obj)) return obj.some((x) => s.test(x[field]));
      return s.test(obj[field]);
    };

    const ret = new Set();
    searchFields.forEach((field) => {
      const nest = field.split('.');
      input.forEach((obj) => {
        // check the field unless the field is nested in a child object, then check the child
        if ((nest.length === 1 && hasVal(searchRegex, nest[0], obj))
          || hasVal(searchRegex, nest[1], obj[nest[0]])) {
          ret.add(obj);
        }
      });
    });
    return Array.from(ret);
  }
  return input;
};

export default searchHelper;
