import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faX, faPlusCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import axios from '../../services/Api';
import TwoButtonModal from '../TwoButtonModal';
import Card from '../Card';
import CircledButton from '../CircledButton';
import InputComponent from '../InputComponent';
import BlueButton from '../BlueButton';
import '../../styles/components/pages/EditLocationPage.scss';

const EditLocationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [location, setLocation] = useState({ _id: undefined, name: '', commandCenters: [] });
  const [locationChanges, setLocationChanges] = useState(location);

  const isLocationEdited = () => {
    if (locationChanges.name !== location.name) return true;
    if (locationChanges.commandCenters.length !== location.commandCenters.length) return true;

    let sameCCs = true;
    locationChanges.commandCenters.forEach((cc) => {
      const newCC = JSON.stringify(cc);
      const ccMatches = location.commandCenters.some((loc) => JSON.stringify(loc) === newCC);
      if (!ccMatches) {
        sameCCs = false;
      }
    });
    return !sameCCs;
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && isLocationEdited()),
  );

  const validLocation = () => {
    if (locationChanges.name === '') {
      return false;
    }
    let isValid = true;
    locationChanges.commandCenters.forEach((cc) => {
      if (cc.name === '' || cc.address === '' || cc.contactNumbers.includes('')) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSubmit = async (e) => {
    if (!isLocationEdited()) {
      toast.error(t('noLocationChanges'));
      return;
    }
    if (!validLocation()) {
      toast.error(t('emptyFields'));
      return;
    }
    const abortController = new AbortController();
    const tempLoc = location;
    try {
      setLocation(locationChanges);
      await toast.promise(axios.post('/admin/locations', locationChanges, { signal: abortController.signal }), {
        pending: location._id ? t('updatingLocation') : t('addingLocation'),
        success: location._id ? t('locationUpdated') : t('locationCreated'),
        error: location._id ? t('locationUpdatedError') : t('locationCreatedError'),
      });
      setLocation(locationChanges);
    } catch (err) {
      setLocation(tempLoc);
    }
    navigate(-1);
    e.stopPropagation();
  };

  const addCommandCenter = () => {
    setLocationChanges({
      ...locationChanges,
      commandCenters: [...locationChanges.commandCenters, { name: '', address: '', contactNumbers: [] }],
    });
  };

  const updateCommandCenters = (i, cc) => {
    let newCCs = [...locationChanges.commandCenters];
    if (cc == null) {
      newCCs = locationChanges.commandCenters.filter((_, j) => j !== i);
    } else {
      newCCs[i] = cc;
    }
    setLocationChanges({ ...locationChanges, commandCenters: newCCs });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchLocation = async () => {
      let data = [];
      try {
        const res = await axios.get('/user/location-objects', { signal: abortController.signal });
        data = res.data;
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
      const matches = data.filter((loc) => loc._id === id);

      if (matches.length === 1) {
        setLocation(matches[0]);
        setLocationChanges(matches[0]);
      }
    };
    fetchLocation();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="edit-location">
      <Card header={(
        <div className="profile-header-edit">
          <div>{location.name === '' ? t('addNewLocation') : location.name}</div>
          <div>
            <CircledButton onClick={(e) => handleSubmit(e)}>
              <FontAwesomeIcon icon={faCheck} title={t('updateProfile')} />
            </CircledButton>
            <CircledButton onClick={() => navigate('/command-centers')}>
              <FontAwesomeIcon icon={faX} title={t('cancelChanges')} />
            </CircledButton>
          </div>
        </div>
      )}
      >
        <div className="location-input">
          <InputComponent
            header={t('locationName')}
            onChange={(change) => { setLocationChanges({ ...locationChanges, name: change }); }}
            required
            value={locationChanges.name}
          />
          {locationChanges.name === '' && <p className="error-text">{t('requiredError', { header: t('name') })}</p>}
          <h2 className="location-title medium-text">{t('offsiteCommandCenterLocations')}</h2>
          <button type="button" className="command-center-btn" onClick={() => addCommandCenter()}>
            {t('addCommandCenter')}
            <FontAwesomeIcon icon={faPlusCircle} title={t('addCommandCenter')} />
          </button>
          {locationChanges.commandCenters?.length > 0
          && locationChanges.commandCenters.map((cc, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="command-center-form" key={`${cc._id}-${i}`}>
              <InputComponent
                header={t('name')}
                onChange={(change) => { updateCommandCenters(i, { ...cc, name: change }); }}
                required
                value={cc.name}
              />
              {cc.name === '' && <p className="error-text">{t('requiredError', { header: t('name') })}</p>}
              <InputComponent
                header={t('address')}
                onChange={(change) => { updateCommandCenters(i, { ...cc, address: change }); }}
                required
                value={cc.address}
              />
              {cc.address === '' && <p className="error-text">{t('requiredError', { header: t('address') })}</p>}
              <div className="command-center-contact-numbers">
                <div className="cc-numbers-header">
                  <h3>{t('contactNumbers')}</h3>
                  <CircledButton onClick={() => {
                    const newNums = [...cc.contactNumbers, ''];
                    updateCommandCenters(i, { ...cc, contactNumbers: newNums });
                  }}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} title={t('addContactNumber')} />
                  </CircledButton>
                </div>
                {cc.contactNumbers.length > 0 && cc.contactNumbers.map((num, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="contact-num" key={`${cc._id}-conNum-${j}`}>
                    <div className="num-input">
                      <InputComponent
                        onChange={(change) => {
                          const changeNums = change.replace(/\D/g, '');
                          const newNums = [...cc.contactNumbers];
                          newNums[j] = changeNums;
                          updateCommandCenters(i, { ...cc, contactNumbers: [...newNums] });
                        }}
                        phone
                        value={num}
                      />
                      <CircledButton onClick={() => {
                        updateCommandCenters(i, {
                          ...cc,
                          contactNumbers: cc.contactNumbers.filter(
                            (_, pos) => pos !== j,
                          ),
                        });
                      }}
                      >
                        <FontAwesomeIcon icon={faX} title={t('removeContactNumber')} />
                      </CircledButton>
                    </div>
                    {num === '' && <p className="error-text">{t('requiredError', { header: t('contactNumber') })}</p>}
                  </div>
                ))}
                <button type="button" className="command-center-btn" onClick={() => updateCommandCenters(i, null)}>
                  {t('removeCommandCenter')}
                  <FontAwesomeIcon icon={faMinusCircle} title={t('removeCommandCenter')} />
                </button>
              </div>
            </div>
          ))}
          <div className="submit-discard-btns">
            <BlueButton onClick={handleSubmit} text={t('save')} />
            <BlueButton onClick={() => navigate('/command-centers')} text={t('discard')} />
          </div>
        </div>
      </Card>
      <TwoButtonModal
        isOpen={blocker.state === 'blocked'}
        redButtonFunctionality={() => blocker.proceed()}
        blueButtonFunctionality={() => blocker.reset()}
        closeFunctionality={() => blocker.reset()}
        headerText={t('discardModalText')}
        redButtonText={t('discard')}
        blueButtonText={t('cancel')}
      />
    </div>
  );
};

export default EditLocationPage;
