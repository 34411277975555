import React from 'react';

const highlight = (text, search) => {
  if (text === '' || text === undefined || search === '') return <p className="dark-blue-text">{text}</p>;
  const i = text.toLowerCase().indexOf(search.toLowerCase());
  if (i === -1) return <p className="dark-blue-text">{text}</p>;
  const j = i + search.length;

  return (
    <p className="search-result dark-blue-text">
      {text.slice(0, i)}
      <span className="highlighted-text dark-blue-text">{text.slice(i, j)}</span>
      {text.slice(j)}
    </p>
  );
};

export default highlight;
