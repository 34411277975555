import React from 'react';
import Modal from 'react-modal';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';
import CustomFileUploader from './CustomFileUploader';
import BlueButton from './BlueButton';
import InputSelector from './InputSelector';

import '../styles/components/UploadModal.scss';

const UploadModal = ({
  isOpen, headerText, closeFunctionality, label, onSave, doc, setDoc, fileName, setFileName,
  isInputDropdown, items,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          zIndex: '999',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          backdropFilter: 'blur(5px)',
        },
        content: {
          position: 'absolute',
          zIndex: '1000',
          width: '60%',
          height: 'fit-content',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          borderRadius: '15px',
          backgroundColor: '#002F6C',
          boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <div className="upload-modal">
        <div className="header">
          <h1>
            {headerText}
          </h1>
          <button type="button" onClick={closeFunctionality}>
            <div className="inner-border">
              <FontAwesomeIcon icon={faX} />
            </div>
          </button>
        </div>
        <div className="upload-body">
          { isInputDropdown
            ? (
              <>
                <InputSelector
                  id="bcc-quick-reference-location"
                  header={label}
                  value={fileName}
                  onChange={setFileName}
                  options={items.map((item) => ({ display: item, value: item }))}
                  lowerCaseLabel
                />
                {fileName === '' && <p className="err">{t('locationRequired')}</p>}
              </>

            )
            : (
              <>
                <InputComponent id="file-name" header={label} type="string" value={fileName} onChange={(change) => setFileName(change)} name="file-name" limit={50} />
                {fileName === '' && <p className="err">{t('nameRequired')}</p>}
              </>
            )}
          <div className="file-uploader">
            <h2>{t('pdfFile')}</h2>
            <CustomFileUploader doc={doc} data-testid="upload-emergency-procedure" setDoc={setDoc} />
          </div>
          <div className="save-button">
            <BlueButton
              text={t('save')}
              onClick={() => { if (fileName) { onSave(); } }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

UploadModal.defaultProps = {
  isInputDropdown: false,
  items: [],
  fileName: '',
  setFileName: () => {},
  doc: {},
};

UploadModal.defaultProps = {
  doc: {},
};

UploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  closeFunctionality: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  doc: PropTypes.instanceOf(Blob),
  setDoc: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  isInputDropdown: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default UploadModal;
