import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from '../services/Api';
import UserContext from '../contexts/UserContext';

/** User Provider */
const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    const abortController = new AbortController();
    setUserLoading(true);
    const fetchUser = async () => {
      try {
        const { data } = await axios.get('/user', { signal: abortController.signal });
        setUser(data);
        setUserError(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingUser'));
        setUserError(true);
      }
      setUserLoading(false);
    };
    fetchUser();

    return () => {
      abortController.abort();
    };
  }, []);

  const updateUser = async (updates, config = {}, isAdmin = false, id = '') => {
    const res = isAdmin ? await axios.post(`/admin/user/update/${id}`, updates) : await axios.post('/user', updates, config);
    setUser(res.data);
    return res;
  };

  return (
    <UserContext.Provider
      value={{
        user, userLoading, userError, updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default UserProvider;
