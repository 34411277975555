import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  faBars, faPhone, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { AiOutlineUser } from 'react-icons/ai';
import PageAccessContext from '../contexts/PageAccessContext';
import { GearIcon } from './icons';

// styles
import '../styles/components/Navbar.scss';

/**
 *
 * @param {object} props
 * @returns {JSX} JSX to render the component
 */

const Navbar = ({ isMenuOpen, setIsMenuOpen }) => {
  const { pageAccess } = useContext(PageAccessContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // headers to render the correct title for the corresponding page
  // checks what the path for the page is and gives corresponding title
  const headers = { // need to match urls to pages once pages are made
    '/settings': () => (<h1>{t('settings').toUpperCase()}</h1>),
    '/profile': () => (<h1>{t('profile').toUpperCase()}</h1>),
    '/start-event': () => (<h1>{t('startEvent').toUpperCase()}</h1>),
    '/incidents-management-teams': () => (<h1>{t('incidentsManagementTeam').toUpperCase()}</h1>),
    '/my-teams': () => (<h1>{t('myTeams').toUpperCase()}</h1>),
    '/command-centers': () => (<h1>{t('commandCenters').toUpperCase()}</h1>),
    '/directory': () => (<h1>{t('directory').toUpperCase()}</h1>),
    '/external-support': () => (<h1>{t('externalSupport').toUpperCase()}</h1>),
    '/emergency-procedures': () => (<h1>{t('emergencyProcedures').toUpperCase()}</h1>),
    '/bcc-quick-reference': () => (<h1>{t('bccQuickReference').toUpperCase()}</h1>),
    '/life-works': () => (<h1>{t('lifeWorks').toUpperCase()}</h1>),
    '/events': () => (<h1>{t('currentEvents').toUpperCase()}</h1>),
    '/admin/user-database': () => (<h1>{t('userDatabase').toUpperCase()}</h1>),
    '/admin/decision-makers': () => (<h1>{t('decisionMakers').toUpperCase()}</h1>),
    '/admin/essential-business-partners': () => (<h1>{t('essentialBusinessPartners').toUpperCase()}</h1>),
    '/admin/essential-business-partners/edit': () => (<h1>{t('essentialBusinessPartners').toUpperCase()}</h1>),
    '/admin/locations': () => (<h1>{t('locations').toUpperCase()}</h1>),
    '/admin/business-teams': () => (<h1>{t('businessTeams').toUpperCase()}</h1>),
    '/admin/it-requirements': () => (<h1>{t('itRequirements').toUpperCase()}</h1>),
    '/admin/documents': () => (<h1>{t('documents').toUpperCase()}</h1>),
    '/admin/bcm-templates': () => (<h1>{t('bcmTemplates').toUpperCase()}</h1>),
    '/admin/edit-business-team': () => (<h1>{t('businessTeams').toUpperCase()}</h1>),
    '/': () => (
      <div className="homepage-header">
        <FontAwesomeIcon icon={faPhone} />
        <span>
          {t('homeHeader')}
          <a href="tel://18009249946">1-800-924-9946</a>
        </span>
      </div>
    ),

  };
  const buttons = [
    {
      icon: <AiOutlineUser size="40" title={t('profileBtn')} />,
      text: t('profile'),
      link: '/profile',
      pageAccess: pageAccess?.ProfilePage,
    },
    {
      icon: <GearIcon title={t('settingsBtn')} />,
      text: t('settings'),
      link: '/settings',
      pageAccess: pageAccess?.SettingsPage,
    },
  ];

  const displayTitle = (headerMap, currentPath) => {
    if (currentPath.includes('/admin/essential-business-partners/edit')) {
      return headerMap['/admin/essential-business-partners/edit']();
    }
    if (currentPath.includes('/admin/edit-business-team/')) {
      return headerMap['/admin/edit-business-team']();
    }
    return headerMap[currentPath] && headerMap[currentPath]();
  };
  return (
    <nav className="navbar">
      <div className="burger-icon">
        {!isMenuOpen && pathname !== '/' && <FontAwesomeIcon icon={faBars} aria-label={t('openHamburgerMenu')} onClick={() => setIsMenuOpen(true)} />}
        {isMenuOpen && pathname !== '/' && <FontAwesomeIcon icon={faXmark} aria-label={t('closeHamburgerMenu')} onClick={() => setIsMenuOpen(false)} />}
      </div>
      <div className="nav-title">
        {displayTitle(headers, pathname)}
      </div>
      <div className="btn-list">
        {buttons.filter((btn) => btn.pageAccess).map((btn) => (
          <Link key={btn.link} to={btn.link} onClick={() => { setIsMenuOpen(false); }}>
            {btn.icon}
            <p>{btn.text}</p>
          </Link>
        ))}
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  isMenuOpen: false,
  setIsMenuOpen: () => {},
};

Navbar.propTypes = {
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func,
};

export default Navbar;
