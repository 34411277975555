import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPhone } from 'react-icons/fi';
import { SiGooglemaps } from 'react-icons/si';
import { MdAddLocationAlt } from 'react-icons/md';
import sanitizeHTML from 'sanitize-html';
import { toast } from 'react-toastify';
import { LuPencil } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

import axios from '../../services/Api';
import Card from '../Card';
import CollapsibleRow from '../CollapsibleRow';
import BlueButton from '../BlueButton';
import SearchBar from '../SearchBar';
import KDCLogo from '../KDCLogo';
import formatPhoneNumber from '../../helpers/phone';
import highlight from '../../helpers/searchHighlight';
import searchHelper from '../../helpers/searchHelper';

import '../../styles/components/pages/CommandCentersPage.scss';

const CommandCentersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user: currentUser } = useContext(UserContext);
  const isAdmin = currentUser?.isAdmin;
  const [allLocations, setAllLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const searchableTerms = ['name', 'commandCenters.name', 'commandCenters.address'];

  const getUserLocations = async (signal) => {
    let data = [];
    try {
      const res = await axios.get('/user/location-objects', { signal });
      data = res.data;
    } catch (e) {
      if (e.name === 'CanceledError') return [];
      toast.error(t('errorLoadingLocations'));
    }
    return searchHelper(search, searchableTerms, data);
  };

  // Get Locations
  useEffect(() => {
    const abortController = new AbortController();
    const retrieveLocations = async () => {
      const userLocations = await getUserLocations(abortController.signal);
      setLocations(userLocations);
      setAllLocations(userLocations);
      setIsLoading(false);
    };
    retrieveLocations();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleRowExpandedChange = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const oneExpanded = () => {
    const expansions = Object.values(expandedRows);

    if (expansions.length === 0) {
      return false;
    }

    return expansions.some((expansion) => expansion === true);
  };

  const invertAllExpanded = () => {
    const isOneExpanded = oneExpanded();
    const newExpandedRows = { ...expandedRows };
    locations.forEach((_, index) => {
      newExpandedRows[index] = !isOneExpanded;
    });
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    if (oneExpanded()) invertAllExpanded();
    setLocations(searchHelper(search, searchableTerms, allLocations));
  }, [search]);

  const addLocationBtn = (isAdmin
    ? (
      <button type="button" onClick={() => navigate('/admin/location/')}>
        <MdAddLocationAlt title={t('addLocation')} />
        <span>{t('add')}</span>
      </button>
    )
    : <></>
  );

  return (
    <div className="command-centers-page">
      <Card header={(
        <div>
          {t('locations')}
        </div>
        )}
      >
        <div className="filters-container">
          <SearchBar
            placeholder={t('search')}
            label={t('commandCentersSearch')}
            id="commandCentersSearch"
            type="text"
            value={search}
            disabled={false}
            onChange={setSearch}
          />
        </div>
      </Card>

      <Card header={(
        <div className="locations-main-header">
          {t('currentLocations')}
          {addLocationBtn}
        </div>
      )}
      >
        {isLoading && <KDCLogo spinning />}
        {!isLoading && (
          <>
            {locations.length !== 0
                && (
                  <div className="button-container">
                    <BlueButton onClick={invertAllExpanded} text={oneExpanded() ? t('collapseAll') : t('expandAll')} />
                  </div>
                )}
            <div className="command-centers-data">
              {locations.length === 0
                && (
                <div className="text-container">
                  <h2 className="large-text">{t('noLocationsMatchSearch')}</h2>
                </div>
                )}
              {locations.length > 0 && locations.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                return -1;
              }).map((location, index) => (
                <CollapsibleRow
                    // eslint-disable-next-line react/no-array-index-key
                  key={`${location.name}-${index}`}
                  header={(
                    <div className="cc-row-header">
                      {highlight(location.name, search)}
                      {isAdmin && <button type="button" className="edit-button" aria-label={t('editLocation')} onClick={() => navigate(`/admin/location/${location._id}`)}><LuPencil className="edit-location" /></button>}
                    </div>
                  )}
                  isExpanded={expandedRows[index] === true}
                  invertExpanded={handleRowExpandedChange}
                  rowIndex={index}
                >
                  <h2 className="location-title medium-text">{t('offsiteCommandCenterLocations')}</h2>
                  {location.commandCenters?.length > 0
                    ? location.commandCenters.map((center, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <div key={`${center._id}-${i}`}>
                        <h2 className="medium-text">{t('name')}</h2>
                        <div className="contact-container">{highlight(center.name, search)}</div>
                        <h2 className="medium-text">{t('address')}</h2>
                        <div className="contact-container">
                          <a
                            className="dark-blue-text maps-link"
                            title={t('openMapsFor', { address: center.address })}
                            target="_blank"
                            href={sanitizeHTML(`https://maps.google.com/?q=${center.address}`)}
                            rel="noreferrer"
                          >
                            <div className="no-margin-bottom no-margin-top large-text">
                              {highlight(center.address, search)}
                            </div>
                            <SiGooglemaps />
                          </a>
                          <h2 className="medium-text">{t('contactNumbers')}</h2>
                          {center.contactNumbers?.length > 0
                        && center.contactNumbers.map((num) => (
                          <div key={num} className="contact-container">
                            <a className="dark-blue-text phone-link" title={t('call', { number: num })} href={sanitizeHTML(`tel:${num}`)}>
                              <p className="no-margin-bottom no-margin-top dark-blue-text large-text">{formatPhoneNumber(num)}</p>
                              <FiPhone className="large-icon" />
                            </a>
                          </div>
                        ))}
                        </div>
                      </div>
                    )) : <div className="contact-container"><p className="dark-blue-text">{t('noCommandCenters')}</p></div>}
                </CollapsibleRow>
              ))}
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default CommandCentersPage;
