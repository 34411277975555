import axios from '../services/Api';

/**
 * fetches external support data via POST
*/
export const postExternalSupport = async (page, filters, signal) => {
  const { data } = await axios.post(`/external-support/${page}`, filters, { signal });
  return data;
};

/**
 * fetches all locations external support data
*/
export const getExternalSupportLocations = async (signal) => {
  const { data } = await axios.get('/external-support/locations/', { signal });
  return data;
};
