import React, { useState, useEffect } from 'react';
import { useBlocker, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../../services/Api';

import Card from '../../Card';
import InputComponent from '../../InputComponent';
import CircledButton from '../../CircledButton';
import TwoButtonModal from '../../TwoButtonModal';
import '../../../styles/components/pages/admin/EssentialBusinessPartnersEdit.scss';

const formatDate = (dateString) => {
  if (!dateString) return '';// retrun an empty string if dateString is undefined or null
  const date = new Date(dateString);
  return date.getTime() ? date.toISOString().split('T')[0] : '';
};
const EssentialBusinessPartnersEdit = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { t } = useTranslation('common');
  const [fetchData, setFetchData] = useState({
    name: '',
    contact_name: '',
    address: '',
    vp_area: '',
    contract_owner: '',
    sponsor: '',
    contract_renewal_date: null,
    contract_review_date: [],
    signed_date: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [ebp, setEbp] = useState({}); // modal state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (_id) {
      // fetch data if editing (PUT)
      const fetchBusinessPartner = async () => {
        try {
          const { data } = await axios.get('/ebps');
          const selectedPartner = data.find(
            (partner) => partner._id === _id,
          );

          if (selectedPartner) {
            const inititalEbp = {
              ...selectedPartner,
              signed_date: formatDate(selectedPartner.signed_date),
              contract_review_date:
                selectedPartner.contract_review_date.map(
                  (date) => ({ id: Date.now() + Math.random(), date: formatDate(date) }),
                ),
            };
            setFetchData(inititalEbp);
            setEbp(inititalEbp);
          }
        } catch (error) {
          toast.error(t('fetchDataError'));
        }
      };

      fetchBusinessPartner();
    }
  }, [_id]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname
      && JSON.stringify(ebp) !== JSON.stringify(fetchData)),
  );

  const handleChange = (e, fieldName) => {
    setFetchData((prevData) => ({
      ...prevData,
      [fieldName]: e,
    }));
  };

  const handleAddReviewDate = () => {
    setFetchData((prevData) => ({
      ...prevData,
      contract_review_date: [
        ...prevData.contract_review_date,
        { id: Date.now(), date: '' }, // Generate unique ID
      ],
    }));
  };

  const handleReviewDateChange = (e, id) => {
    setFetchData((prevData) => ({
      ...prevData,
      contract_review_date: prevData.contract_review_date.map((item) => (item.id === id
        ? { ...item, date: e } : item)),
    }));
  };

  const handleRemoveReviewDate = (id) => {
    setFetchData((prevData) => ({
      ...prevData,
      contract_review_date: prevData.contract_review_date.filter(
        (item) => item.id !== id,
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fetchData.name.trim()) {
      toast.error(t('requiredError', { header: t('name') }));
      return;
    }
    setIsLoading(true);
    setEbp(fetchData);
    try {
      const payload = {
        ...fetchData,
        contract_review_date: fetchData.contract_review_date.map((item) => item.date),
      };

      if (_id) {
        await axios.put(`/admin/ebps/update/${_id}`, payload);
        toast.success(t('updateEBPSuccess'));
      } else {
        await axios.post('/ebp', payload);
        toast.success(t('createEBPSuccess'));
      }
      navigate('/admin/essential-business-partners');
    } catch (error) {
      if (_id) {
        toast.error(t('updateEBPError'));
      } else {
        toast.error(t('errorCreatingEbp'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setEbp(fetchData);
    try {
      await axios.delete('/ebp', { data: { _id } });
      toast.success(t('deleteEBPSuccess'));
      navigate('/admin/essential-business-partners');
    } catch (error) {
      toast.error(t('deleteEBPError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="edit-profile">
      <Card
        header={(
          <div className="profile-header-edit">
            <h1>{t('searchEssentialBusinessPartners')}</h1>
            <div className="profile-header-edit-buttons">
              <CircledButton onClick={handleSubmit}>
                <FontAwesomeIcon icon={faCheck} title={t('updateProfile')} />
              </CircledButton>
              <CircledButton onClick={() => navigate('/admin/essential-business-partners')}>
                <FontAwesomeIcon icon={faX} title={t('cancelChanges')} />
              </CircledButton>
            </div>
          </div>
        )}
      >
        <div className="inputs-content">
          <InputComponent
            header={t('ebpName')}
            type="string"
            value={fetchData.name || ''}
            onChange={(e) => handleChange(e, 'name')}
            id="ebpName"
          />
          {!fetchData.name.trim() && <p className="error-text">{t('requiredError', { header: t('name') })}</p>}

          <InputComponent
            header={t('ebpContactName')}
            type="string"
            value={fetchData.contact_name || ''}
            onChange={(e) => handleChange(e, 'contact_name')}
            id="ebpContactName"
          />

          <InputComponent
            header={t('ebpAddress')}
            type="string"
            value={fetchData.address || ''}
            onChange={(e) => handleChange(e, 'address')}
            id="ebpAddress"
          />
          <InputComponent
            header={t('vpArea')}
            type="string"
            value={fetchData.vp_area || ''}
            onChange={(e) => handleChange(e, 'vp_area')}
            id="vpArea"
          />
          <InputComponent
            header={t('contractOwner')}
            type="string"
            value={fetchData.contract_owner || ''}
            onChange={(e) => handleChange(e, 'contract_owner')}
            id="contractOwner"
          />
          <InputComponent
            header={t('sponsor')}
            type="string"
            value={fetchData.sponsor || ''}
            onChange={(e) => handleChange(e, 'sponsor')}
            id="sponsor"
          />

          <div className="ebp-date">
            <InputComponent
              header={t('dateEbpSigned')}
              type="date"
              value={fetchData.signed_date || ''}
              onChange={(e) => handleChange(e, 'signed_date')}
              id="signed-date-input"
            />

          </div>
          <div className="ebp-date-contract">
            <div className="ebp-date-contract-header">
              <h6>{t('contractReviewDate')}</h6>

              <div>
                <CircledButton onClick={handleAddReviewDate}>
                  <FaPlus />
                </CircledButton>
              </div>
            </div>
            <div className="ebp-date-contract-displaying">
              {fetchData.contract_review_date.map(({ id, date }) => (
                <div key={id} className="date-input">
                  <InputComponent
                    type="date"
                    value={date || ''}
                    onChange={(e) => handleReviewDateChange(e, id)}
                    id={`contract-review-date-${id}`}
                  />
                  <FontAwesomeIcon
                    icon={faX}
                    onClick={() => handleRemoveReviewDate(id)}
                    className="remove-icon"
                    title={t('removeDate')}
                  />
                </div>
              ))}
            </div>
          </div>

        </div>

        <div className="button-container">
          <button
            type="button"
            className="blue-button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {t('save')}
          </button>
          <button
            type="button"
            className="blue-button"
            onClick={() => { setEbp(fetchData); navigate('/admin/essential-business-partners'); }}
          >
            {t('discard')}
          </button>
          <button
            type="button"
            className={!_id ? 'red-button no-cursor' : 'red-button'}
            onClick={handleOpenDeleteModal}
            disabled={!_id}
          >
            {t('delete')}
          </button>
        </div>
      </Card>
      <TwoButtonModal
        isOpen={blocker.state === 'blocked'}
        redButtonFunctionality={() => blocker.proceed()}
        blueButtonFunctionality={() => blocker.reset()}
        closeFunctionality={() => blocker.reset()}
        headerText={t('moveAwayModalHeader')}
        redButtonText={t('leave')}
        blueButtonText={t('stay')}
      />

      <TwoButtonModal
        isOpen={isDeleteModalOpen}
        headerText={t('deleteModalText')}
        blueButtonText={t('yes')}
        redButtonText={t('no')}
        blueButtonFunctionality={handleDelete}
        redButtonFunctionality={handleCloseDeleteModal}
        closeFunctionality={() => setIsDeleteModalOpen(false)}
      />
    </div>
  );
};

export default EssentialBusinessPartnersEdit;
