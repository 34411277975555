import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FaCalendarAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../styles/components/DatePicker.scss';

const DatePicker = ({
  id, name, value, onChange, disabled,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null); // Ref for input element

  const triggerDatePicker = () => {
    if (inputRef.current) {
      inputRef.current.showPicker(); // Use the ref to call `showPicker`
      inputRef.current.focus();
    }
  };

  return (
    <div className="date-picker">
      <input
        type="date"
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="date-input"
        id={id}
        disabled={disabled}
        ref={inputRef} // Attach the ref to the input
      />
      <FaCalendarAlt className="calendar-icon" onClick={triggerDatePicker} role="img" aria-label={t('openDatePicker')} />
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  disabled: false,
};

export default DatePicker;
