import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/components/TwoButtonModal.scss';

const TwoButtonModal = ({
  isOpen, headerText, blueButtonText,
  redButtonText, blueButtonFunctionality, redButtonFunctionality,
  closeFunctionality, bodyText, blueButtonsOnly,
}) => (
  <Modal
    isOpen={isOpen}
    style={{
      overlay: {
        position: 'fixed',
        zIndex: '999',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        backdropFilter: 'blur(5px)',
      },
      content: {
        position: 'absolute',
        zIndex: '1000',
        width: '500px',
        height: 'fit-content',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        border: 'none',
        borderRadius: '15px',
        backgroundColor: '#002F6C',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
      },
    }}
  >
    <div className="two-button-modal">
      <div className="header">
        <h1>
          {headerText}
        </h1>
        <button type="button" data-testid="modal-close" onClick={closeFunctionality}>
          <div className="inner-border">
            <FontAwesomeIcon icon={faX} />
          </div>
        </button>
      </div>
      <div className="body-color">
        {bodyText
          && <p className="body-text">{bodyText}</p>}
        <div className="body">
          <button type="button" className="blue-button" onClick={blueButtonFunctionality}>{blueButtonText}</button>
          <button type="button" className={blueButtonsOnly ? 'blue-button' : 'red-button'} onClick={redButtonFunctionality}>{redButtonText}</button>
        </div>
      </div>

    </div>
  </Modal>
);

TwoButtonModal.defaultProps = {
  isOpen: false,
  blueButtonText: '',
  redButtonText: '',
  headerText: '',
  blueButtonFunctionality: () => { },
  redButtonFunctionality: () => { },
  closeFunctionality: () => { },
  bodyText: undefined,
  blueButtonsOnly: false,

};
TwoButtonModal.propTypes = {
  isOpen: PropTypes.bool,
  blueButtonText: PropTypes.string,
  redButtonText: PropTypes.string,
  headerText: PropTypes.string,
  blueButtonFunctionality: PropTypes.func,
  redButtonFunctionality: PropTypes.func,
  closeFunctionality: PropTypes.func,
  bodyText: PropTypes.string,
  blueButtonsOnly: PropTypes.bool,
};

export default TwoButtonModal;
