import React, { createContext, useState } from 'react';
import propTypes from 'prop-types';

/** Context for passing user list around */
const UserListContext = createContext({
  users: [],
  usersETag: '',
  updateUsers: () => {},
});

/** Custom provider for User list context */
const UserListProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [usersETag, setUsersETag] = useState('');
  const updateUsers = (usersData, ETag) => {
    setUsers(usersData);
    setUsersETag(ETag);
  };
  return (
    <UserListContext.Provider value={{ users, usersETag, updateUsers }}>
      {children}
    </UserListContext.Provider>
  );
};

UserListProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export { UserListProvider };

export default UserListContext;
