import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import TwoButtonModal from './TwoButtonModal';
import StyledSelect from './StyledSelect';
import Role from '../enums/Role';
import axios from '../services/Api';
import RoleItem from './RoleItem';

// styles
import '../styles/components/EditRoles.scss';

const EditRole = ({
  userRoles, handleChange, handleAddRole, handleRemoveInput,
  isAdmin, setUserChanges, setInvalidRoles, fullName,
}) => {
  const { t } = useTranslation();
  const [adminModal, setAdminModal] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(true);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [allLocations, setAllLocations] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const fixedValue = {
    value: t('addRole'),
    label: t('addRole'),
  };
  const changeAdminStatus = () => {
    handleChange(!isAdmin, 'isAdmin');
    setAdminModal(false);
  };

  const allRoles = [
    { value: Role.AssociateVicePresident, label: t('assistantVicePresident'), hasLocations: false },
    { value: Role.BusinessContinuityCoordinator, label: t('businessContinuityCoordinator'), hasLocations: true },
    { value: Role.BusinessTeam, label: t('businessTeam'), hasLocations: true },
    { value: Role.CGLBusinessContinuityCoordinator, label: t('cglBcc'), hasLocations: false },
    { value: Role.CommunicationsTeam, label: t('communicationsTeam'), hasLocations: true },
    { value: Role.DecisionMaker, label: t('decisionMaker'), hasLocations: true },
    { value: Role.Executive, label: t('executive'), hasLocations: false },
    { value: Role.IncidentManagementTeamSupport, label: t('Incident Management Team Support'), hasLocations: true },
    { value: Role.InfrastructureTeam, label: t('Infrastructure Team'), hasLocations: true },
    { value: Role.LogisticsTeam, label: t('Logistics Team'), hasLocations: true },
    { value: Role.OfficeLead, label: t('Office Lead'), hasLocations: true },
    { value: Role.OperationsTeam, label: t('Operations Team'), hasLocations: true },
    { value: Role.Scribe, label: t('scribe'), hasLocations: true },
    { value: Role.VicePresident, label: t('vicePresident'), hasLocations: false },
  ];

  useEffect(() => {
    const abortController = new AbortController();
    const getLocations = async () => {
      try {
        const { data } = await axios.get('/constants/locations', { signal: abortController.signal });
        const orderedLocations = data.map((location) => ({
          value: location.name,
          label: location.name,
        }));
        orderedLocations.sort((a, b) => a.value.localeCompare(b.value));
        setAllLocations(orderedLocations);

        setLocationsLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
    };
    const getTeams = async () => {
      try {
        const { data } = await axios.get('/admin/teams', { signal: abortController.signal });
        setAllTeams(data.map((team) => ({
          value: team.team,
          label: team.team,
        })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        setTeamsLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingTeams'));
      }
    };
    getLocations();
    getTeams();
    setInvalidRoles(userRoles.reduce((o, role) => ({ ...o, [role.name]: false }), {}));
    return () => {
      abortController.abort();
    };
  }, []);

  const availableRoles = allRoles.filter((role) => (!userRoles.some((r) => r.name === role.value)));

  return (
    <div className="edit-roles-container">

      <div className="user-admin-check">
        <h2 className="admin-text">{t('makeAdminText')}</h2>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setAdminModal(true);
          }}
          className="check-button"
        >

          {isAdmin ? <FaRegCheckCircle title={t('selected')} /> : <FaRegCircle title={t('notSelected')} />}

        </button>
      </div>

      <div className="add-new-role">
        {!(teamsLoading || locationsLoading)
          ? (
            <div>
              <StyledSelect
                data-testId="add-role-select"
                aria-label={t('addRole')}
                options={availableRoles}
                onChange={(e) => {
                  if (e.hasLocations) {
                    handleAddRole('role', {
                      name: e.value,
                      locations: [
                        {
                          location: '', position: '', team: '', title: '',
                        },
                      ],
                    });
                  } else {
                    handleAddRole('role', {
                      name: e.value,
                    });
                  }
                  setInvalidRoles((prev) => ({
                    ...prev,
                    [e.value]: e.hasLocations,
                  }));
                }}
                value={fixedValue}
                placeholderText={t('addRole')}
              />
            </div>
          )
          : (
            <div>
              <h2 className="admin-text">{t('loadingUserRoles')}</h2>
            </div>
          )}
      </div>

      {userRoles.map((role, i) => (
        <RoleItem
          role={role}
          locations={allLocations}
          teams={allTeams}
          key={role.name}
          onChange={(newRole, isValid) => {
            setUserChanges((prevUser) => ({
              ...prevUser,
              role: prevUser.role.map((r) => (r.name === newRole.name
                ? newRole
                : r)),
            }));
            setInvalidRoles((prevRoles) => ({ ...prevRoles, [newRole.name]: !isValid }));
          }}
          onDelete={(e, r) => {
            handleRemoveInput(e, 'role', i);
            setInvalidRoles((prev) => {
              const newInvalidRoles = { ...prev };
              delete newInvalidRoles[r];
              return newInvalidRoles;
            });
          }}
        />
      ))}

      <TwoButtonModal
        isOpen={adminModal}
        redButtonFunctionality={() => setAdminModal(false)}
        blueButtonFunctionality={() => changeAdminStatus()}
        closeFunctionality={() => setAdminModal(false)}
        headerText={isAdmin ? t('removeAdminModalText', { user: fullName })
          : t('makeAdminModalText', { user: fullName })}
        redButtonText={isAdmin ? t('keepAdmin') : t('cancel')}
        blueButtonText={isAdmin ? t('removeAdmin') : t('makeAdminButton')}
        blueButtonsOnly={isAdmin}
      />
    </div>
  );
};

export default EditRole;
EditRole.defaultProps = {
  handleChange: () => {},
  handleAddRole: () => {},
  handleRemoveInput: () => {},
  setUserChanges: () => {},
  isAdmin: false,
  setInvalidRoles: () => {},
  fullName: '',
};

EditRole.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      location: PropTypes.string,
      position: PropTypes.string,
      team: PropTypes.string,
      title: PropTypes.string,
    })),
  })).isRequired,
  handleChange: PropTypes.func,
  handleAddRole: PropTypes.func,
  handleRemoveInput: PropTypes.func,
  setUserChanges: PropTypes.func,
  isAdmin: PropTypes.bool,
  setInvalidRoles: PropTypes.func,
  fullName: PropTypes.string,
};
