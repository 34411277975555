import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaXmark } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';
import ContactRow from './ContactRow';
import '../styles/components/ContactCard.scss';
import formatPhoneNumber from '../helpers/phone';
import UserDatabaseCard from './UserDatabaseCard';

const ContactCard = ({
  user, handleClose, isAdmin, removeUser, handleFetchDirectory,
}) => {
  const { t } = useTranslation();
  const checkNotApplicable = (condition, children, key, notApplicableCustom) => {
    if (condition) {
      return (children());
    }
    if (notApplicableCustom) {
      return (notApplicableCustom());
    }
    return (<p key={key} className="not-applicable">{t('notApplicable')}</p>);
  };
  const items = [{
    header: t('officeLocation'),
    data: user.location,
    type: 'address',
  }, {
    header: t('corporateEmail'),
    data: user.email,
    type: 'email',
  }, {
    header: t('alternateEmails'),
    data: user.alternateEmails,
    custom: () => (
      <>
        <h2>{t('alternateEmails')}</h2>

        {checkNotApplicable(user?.alternateEmails && user?.alternateEmails.length > 0, () => (
          user.alternateEmails.map((email) => (
            <ContactRow key={email} name={email} contacts={[{ type: 'email', data: email }]} />
          ))
        ))}
      </>
    ),
  }, {
    header: t('workPhone'),
    data: user.workPhone,
    custom: () => (
      <>
        <h2>{t('workPhone')}</h2>
        {checkNotApplicable(user.workPhone && user.workPhone.length > 0, () => (
          <>
            <ContactRow key={user.workPhone} name={formatPhoneNumber(user.workPhone)} contacts={[{ type: 'text', data: user.workPhone }, { type: 'phone', data: user.workPhone }]} />
            <ContactRow key={user.extension} name={`${t('ext')} ${user.extension}`} />
          </>
        ))}
      </>
    ),
  }, {
    header: user.mainPhone.type,
    data: user.mainPhone.number,
    custom: () => (
      <>
        <h2>
          {t(user.mainPhone.type.toLowerCase())}
        </h2>
        {checkNotApplicable(user?.mainPhone?.number, () => (
          <>
            <ContactRow key={user.mainPhone.number} name={formatPhoneNumber(user.mainPhone.number)} contacts={[{ type: 'text', data: user.mainPhone.number }, { type: 'phone', data: user.mainPhone.number }]} />
            {user.mainPhone.extension
                && <ContactRow key={user.mainPhone.extension} name={`${t('ext')}${user.mainPhone.extension}`} />}
          </>
        ))}
      </>
    ),
  }, {
    header: t('alternatePhones'),
    data: user.alternatePhones,
    custom: () => (
      <>
        <h2>{t('alternatePhone')}</h2>
        {user.alternatePhones && user.alternatePhones.length > 0
          ? user.alternatePhones.map((phone) => (
            checkNotApplicable(phone.number.length > 0, () => (
              <ContactRow key={phone.number} name={formatPhoneNumber(phone.number)} contacts={[{ type: 'text', data: phone.number }, { type: 'phone', data: phone.number }]} secondaryText={phone.type} />
            ))
          )) : <p className="not-applicable">{t('notApplicable')}</p>}
      </>
    ),
  }, {
    header: t('homeAddress'),
    data: user.homeAddress,
    type: 'address',
  },
  ];

  const [showUserDatabase, setShowUserDatabase] = useState(isAdmin && !user._id);
  if (showUserDatabase) {
    return (
      <UserDatabaseCard
        user={user}
        onClose={handleClose}
        removeUser={removeUser}
        handleFetchDirectory={handleFetchDirectory}
      />
    );
  }
  return (
    <div className="contact-card">
      <div className="contact-card-header">
        <h1>
          {`${user.firstName} ${user.lastName}`}
        </h1>
        <div className="edit-Button">
          <button type="button" onClick={handleClose}>
            <FaXmark title={t('closeContact')} />
          </button>

          {isAdmin && (
            <button type="button" onClick={() => { setShowUserDatabase(true); }}>
              <BiPencil title={t('editButton')} />
            </button>
          )}
        </div>
      </div>
      <div className="contact-card-data">
        {
          items.map((item) => {
            if (!item.custom) {
              return (
                <div key={item.header}>
                  <h2>
                    {item.header}
                  </h2>
                  {checkNotApplicable(item?.data, () => (
                    <ContactRow
                      name={item.data}
                      contacts={[{ type: item.type, data: item.data }]}
                    />
                  ))}
                </div>
              );
            }
            return (
              <div key={item.header}>
                {item.custom()}
              </div>
            );
          })
        }
        { user.emergencyContact && Object.keys(user.emergencyContact).length > 0
        && (
        <>
          <h2 className="emergency-contact-header">{t('emergencyContact')}</h2>
          <div className="contact-card-emergency-data">
            {checkNotApplicable(user?.emergencyContact?.firstName
            || user?.emergencyContact?.lastName,
            () => (
              <p className="emergency-contact-name">
                {`${user.emergencyContact.firstName} ${user.emergencyContact.lastName}`}
                <span className="emergency-contact-relation">{user.emergencyContact.relation}</span>
              </p>
            ))}
            <div className="contact-row-one-line">
              <h3>
                {`${t('mainPhone')}:`}
              </h3>
              {checkNotApplicable(user?.emergencyContact?.mainPhone, () => (
                <ContactRow name={formatPhoneNumber(user?.emergencyContact?.mainPhone)} contacts={[{ type: 'text', data: user.emergencyContact.mainPhone }, { type: 'phone', data: user.emergencyContact.mainPhone }]} />
              ))}
            </div>
            <div className="contact-row-one-line">
              <h3>
                {`${t('alternatePhone')}:`}
              </h3>
              {checkNotApplicable(user?.emergencyContact?.alternatePhone, () => (
                <ContactRow
                  name={formatPhoneNumber(user?.emergencyContact?.alternatePhone)}
                  contacts={[{ type: 'text', data: user.emergencyContact.alternatePhone },
                    { type: 'phone', data: user.emergencyContact.alternatePhone }]}
                />
              ))}
            </div>
          </div>
        </>
        )}
      </div>
    </div>
  );
};

ContactCard.defaultProps = {
  handleClose: () => {},
  handleFetchDirectory: () => {},
  removeUser: () => {},
  isAdmin: false,
  user: {
    emergencyContact: {
      firstName: '',
      lastName: '',
      relation: '',
      mainPhone: '',
      alternatePhone: '',
    },
    alternateEmails: [],
    email: '',
    firstName: '',
    lastName: '',
    mainPhone: {
      type: '',
      string: '',
      extension: '',
    },
    alternatePhones: [],
    homeAddress: '',
    workPhone: '',
    extension: '',
    notes: '',
    location: '',
    isAdmin: false,
    role: [],
  },

};

ContactCard.propTypes = {
  handleClose: PropTypes.func,
  removeUser: PropTypes.func,
  handleFetchDirectory: PropTypes.func,
  isAdmin: PropTypes.bool,
  user: PropTypes.shape({
    _id: PropTypes.string,
    emergencyContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      mainPhone: PropTypes.string,
      alternatePhone: PropTypes.string,
    }),
    alternateEmails: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mainPhone: PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    alternatePhones: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    })),
    homeAddress: PropTypes.string,
    workPhone: PropTypes.string,
    extension: PropTypes.string,
    notes: PropTypes.string,
    location: PropTypes.string,
  }),
};

export default ContactCard;
