import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import '../styles/components/AddDecisionMakersModal.scss';
import { useTranslation } from 'react-i18next';
import { faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from './SearchBar';

/**
 * Recipients Modal
 * @returns JSX
 */
const AddDecisionMakersModal = ({
  isOpen, location, users, closeFunctionality, addFunctionality,
}) => {
  const { t } = useTranslation('common');
  const [userSearch, setUserSearch] = useState('');
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (userSearch && userSearch.trim().length >= 2) {
      const searchResults = users.filter((x) => (x.fullName.toLowerCase().includes(userSearch)
      || x.upn.toLowerCase().includes(userSearch)
      || x.email.toLowerCase().includes(userSearch)));
      setUserList(searchResults);
    } else {
      setUserList([]);
    }
  }, [userSearch]);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          zIndex: '1000',
          overflowY: 'auto',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          top: '0',
          left: '0',
          backdropFilter: 'blur(5px)',
        },
        content: {
          position: 'absolute',
          zIndex: '1000',
          width: '700px',
          backgroundColor: '#D7EFFF',
          borderRadius: '15px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <div className="decision-makers-modal">
        <div className="header">
          <h1>
            {t('addDecisionMaker', { location })}
          </h1>
          <button type="button" onClick={closeFunctionality}>
            <div className="inner-border">
              <FontAwesomeIcon icon={faX} />
            </div>
          </button>
        </div>
        <div className="body">
          <SearchBar
            placeholder={t('searchDecisionMaker')}
            label="decisionMakerSearch"
            id="decisionMakerSearch"
            type="text"
            value={userSearch}
            disabled={false}
            onChange={setUserSearch}
          />

          {userList.map((user, i) => (
            <div key={user._id} className="row-item">
              <h3>{user.fullName}</h3>
              <button
                className="right-side"
                type="button"
                data-testid="add-button"
                onClick={() => {
                  setUserSearch('');
                  addFunctionality(userList[i]);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

AddDecisionMakersModal.defaultProps = {
  isOpen: false,
  location: '',
  closeFunctionality: () => { },
  addFunctionality: () => { },
  users: [],

};
AddDecisionMakersModal.propTypes = {
  isOpen: PropTypes.bool,
  location: PropTypes.string,
  closeFunctionality: PropTypes.func,
  addFunctionality: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    fullName: PropTypes.string,
    upn: PropTypes.string,
    email: PropTypes.string,
  })),
};

export default AddDecisionMakersModal;
