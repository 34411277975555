import { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import UserListContext from '../providers/UserListProvider';
import axios from '../services/Api';
import StatusCode from '../enums/StatusCode';

/**
 * Get the current list of users for the current user
 * Attempts to refresh the cached users on mount
 * @returns {array} current users user list
 */
const useUserList = () => {
  const {
    users,
    usersETag,
    updateUsers,
  } = useContext(UserListContext);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    // Fetch updated groups on mount
    setIsLoading(true);
    const abortController = new AbortController();
    const getUsersList = async () => {
      try {
        let route = '';
        if (user.isAdmin) {
          route = '/admin/users';
        }
        if (route) {
          const { data, headers } = await axios.get(route, {
            signal: abortController.signal,
            headers: { 'If-None-Match': usersETag },
            validateStatus: (status) => StatusCode.isSuccess(status)
            || status === StatusCode.NotModified,
          });
          if (data) {
            updateUsers(data, headers.etag);
          }
        } else {
          updateUsers([], '');
        }
      } catch (err) {
        if (err.name === 'CanceledError') return;
        toast.error(t('errorLoadingUserList'));
        updateUsers([], '');
      }
      setIsLoading(false);
    };
    getUsersList();

    return () => {
      abortController.abort();
    };
  }, [user.isAdmin]);

  return [
    users || [],
    isLoading,
  ];
};

export default useUserList;
