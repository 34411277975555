import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { LuChevronDownCircle, LuChevronUpCircle } from 'react-icons/lu';
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa';
import { BsPrinter } from 'react-icons/bs';
import { toast } from 'react-toastify';
import MultiSelectDisplay from '../MultiSelectDisplay';
import BlueButton from '../BlueButton';
import CollapsibleRow from '../CollapsibleRow';
import Card from '../Card';
import DirectoryFilterSelect from '../DirectoryFilterSelect';
import fetchRollCall from '../../hooks/rollCallhook';
import axios from '../../services/Api';
import '../../styles/components/pages/IncidentsManagementTeams.scss';

/**
 * Incidents Management Teams Page
 * @returns JSX component
 */
const IncidentsManagementTeamsPage = () => {
  const { t } = useTranslation();
  const [checkLocation, setCheckLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [businessTeams, setBusinessTeams] = useState([]);
  const [checkBusinessTeams, setCheckBusinessTeams] = useState([]);
  const [checkVps, setCheckVps] = useState([]);
  const [checkEvps, setCheckEvps] = useState([]);
  const [isBusinessTeamsOpen, setIsBusinessTeamsOpen] = useState(false);
  const [isVpsOpen, setIsVpsOpen] = useState(false);
  const [isEvpsOpen, setIsEvpsOpen] = useState(false);
  const [isLocationsOpen, setIsLocationsOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(true);
  const [isChainOfCommandOpen, setChainOfCommandOpen] = useState(false);
  const [isBTOpen, setBTOpen] = useState(false);
  const [isIMTOpen, setIMTOpen] = useState(false);
  const [vps, setVps] = useState([]);
  const [evps, setEvps] = useState([]);
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [businessTeamRollCall, setBusinessTeamRollCall] = useState([]);
  const [selectedRollCallTeams, setSelectedRollCallTeams] = useState([]);
  const [ImtManagement, setImtManagement] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [nestedImtCollapsible, setNestedImtCollapsible] = useState(new Map());

  /**
   * clears all checks
   */
  const clearAll = () => {
    setCheckBusinessTeams(new Array(businessTeams.length).fill(false));
    setCheckEvps(new Array(evps.length).fill(false));
    setCheckVps(new Array(vps.length).fill(false));
    setCheckLocation(new Array(locations.length).fill(false));
  };

  /**
   * Extracts VPs and EVps from roll call
   * @param {*} evpOrVp string defining target
   */
  const extractEvpsVps = (currentRollCall, evpOrVp, tempArray) => currentRollCall[evpOrVp]
    .forEach((mgmt) => {
      const isExecutives = evpOrVp === 'executives';
      const temp = tempArray.find((val) => val.id === mgmt._id);
      // creating a new mapped user
      if (!temp) {
        const mgmtObject = isExecutives ? {
          name: `${mgmt.firstName} ${mgmt.lastName}`,
          id: mgmt._id,
          team: [currentRollCall.team],
          locations: currentRollCall.locations,
          vps: currentRollCall.vps.map((vp) => vp._id),
        } : {
          name: `${mgmt.firstName} ${mgmt.lastName}`,
          id: mgmt._id,
          team: [currentRollCall.team],
          locations: currentRollCall.locations,
          evps: currentRollCall.executives.map((evp) => evp._id),
        };
        tempArray.push(mgmtObject);
      } else {
      // if the user already exists in the array we update it's properties
        if (currentRollCall.team) {
          temp.team?.push(currentRollCall.team);
        }
        if (currentRollCall.locations) {
          temp.locations = Array.from(new Set([
            ...temp.locations,
            currentRollCall.locations,
          ]));
        }
        if (currentRollCall.executives && !isExecutives) {
          temp.executives = Array.from(new Set([
            ...temp.evps,
            ...currentRollCall.executives.map((exec) => exec._id),
          ]));
        }
        if (currentRollCall.vps && isExecutives) {
          temp.vps = Array.from(new Set([
            ...temp.vps,
            ...currentRollCall.vps.map((vp) => vp._id),
          ]));
        }
      }
    });

  useEffect(() => {
    const abortController = new AbortController();
    const handleFetchIMT = async () => {
      try {
        const [{ data: l }, rc] = await Promise.all([
          axios.get('/constants/locations', { signal: abortController.signal }),
          fetchRollCall(abortController.signal),
        ]);
        setCheckLocation(new Array(l.length).fill(false));
        setLocations(l);
        const bt = rc.map((call) => ({ team: call.team, locations: call.locations, ...call }));
        const vpsTemp = [];
        const execTemp = [];
        /**
         * Update management(exec/vp) while extracting and updating duplicates
         * */
        rc.forEach((call) => {
          extractEvpsVps(call, 'vps', vpsTemp);
          extractEvpsVps(call, 'executives', execTemp);
        });
        setBusinessTeams(bt);
        setVps(vpsTemp);
        setEvps(execTemp);
        setCheckVps(new Array(vpsTemp.length).fill(false));
        setCheckEvps(new Array(execTemp.length).fill(false));
        setCheckBusinessTeams(new Array(bt.length).fill(false));
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingRollCall'));
      }
    };
    handleFetchIMT();
    return () => {
      abortController.abort();
    };
  }, []);

  const locationOptions = locations.map((loc) => loc.name);
  const businessTeamsOptions = businessTeams.map((bt) => bt.team);
  const vpOptions = vps.map((vp) => vp.name);
  const evpOptions = evps.map((evp) => evp.name);
  const isSomeFilterChecked = checkLocation.includes(true) || checkBusinessTeams.includes(true)
    || checkEvps.includes(true) || checkVps.includes(true);

  /**
   * Extracts true values
   * @param {*} checkArray Array of bools
   * @param {*} valueArray Array of value
   * @returns new value array
   */
  const itemCheckArrayToValue = (checkArray, valueArray) => checkArray
    .map((op, i) => (op ? valueArray[i] : null)).filter((val) => val);

  const oneExpanded = () => {
    const expansions = Object.values(expandedRows);

    if (expansions.length === 0) {
      return false;
    }

    return expansions.some((expansion) => expansion === true);
  };

  const invertAllExpanded = () => {
    const isOneExpanded = oneExpanded();
    const newExpandedRows = { ...expandedRows };

    businessTeamRollCall.forEach((_, index) => {
      newExpandedRows[index] = !isOneExpanded;
    });
    setExpandedRows(newExpandedRows);
  };

  const handleRowExpandedChange = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  /**
   * Get IMT users
   * @param {*} imtData
   * @returns JSX
   */
  const getImtUsers = (imtData) => {
    const allUsers = [
      ...imtData?.alternate,
      ...imtData?.member,
      imtData?.lead,
    ].filter((person) => person);
    if (allUsers.length > 0) {
      return (allUsers.map((person) => (
        <div key={`imt-user-${person._id}`} className="chain-of-command-user">
          <button
            type="button"
            className="check-button"
            onClick={() => (selectedUsers.includes(person._id)
              ? setSelectedUsers(selectedUsers.filter((dm) => dm !== person._id))
              : setSelectedUsers([...selectedUsers, person._id]))}
          >
            {selectedUsers.includes(person._id)
              ? <FaRegCheckCircle className="check" title={t('selected')} />
              : <FaRegCircle className="check" title={t('notSelected')} />}
          </button>
          <p className="chain-of-command-user-name">{`${person?.firstName} ${person?.lastName}`}</p>
        </div>
      )));
    }
    return (
      <div className="chain-of-command-user">
        <p className="chain-of-command-user-name">{t('none')}</p>
      </div>
    );
  };

  /**
   * Gets team details
   * @param {*} teamName - to be used for keys
   * @param {*} items - list of team details
   * @param {*} title - title of the said list
   * @returns JSX
   */
  const getItemDetails = (teamName, items, title) => (
    <div key={`${teamName}-container-${title}`} className="team-details-container">
      <p className="team-detail-header">{`${title}:`}</p>
      <div key={`${teamName}-details-container-${title}`} className="team-detail-content-container">
        {items.length > 0 ? items.map((item) => (
          <div key={item._id ? `list-${item._id}` : `list-${item}`}>
            {item.firstName
              ? (
                <div key={item._id ? `button-${item._id}` : `button-${item}`} className="business-team-user">
                  <button
                    type="button"
                    className="check-button"
                    onClick={() => (selectedUsers.includes(item._id)
                      ? setSelectedUsers(selectedUsers.filter((dm) => dm !== item._id))
                      : setSelectedUsers([...selectedUsers, item._id]))}
                  >
                    {selectedUsers.includes(item._id)
                      ? <FaRegCheckCircle className="check" title={t('selected')} />
                      : <FaRegCircle className="check" title={t('notSelected')} />}
                  </button>

                  <p className="business-team-user-name">{ `${item.firstName} ${item.lastName}`}</p>
                </div>
              ) : <p key={item._id ? `text-${item._id}` : `text-${item}`} className="team-detail-content">{item}</p>}
          </div>
        )) : <p className="team-detail-content">{t('none')}</p>}
      </div>
    </div>
  );

  /**
   * Gets team details
   * @param {*} team - team object
   * @returns JSX
   */
  const getTeamDetails = (team) => (
    <div key={`${team.team}-details-container`} className="team-details">
      {getItemDetails(team.team, team.locations, t('imtLocations'))}
      {getItemDetails(team.team, team.vpLeads, t('imtVpLeads'))}
      {getItemDetails(team.team, team.vpAlternates, t('imtVpAlts'))}
      {getItemDetails(team.team, team.leads, t('imtLeads'))}
      {getItemDetails(team.team, team.alternates, t('imtAlts'))}
    </div>
  );

  useEffect(() => {
    const abortController = new AbortController();
    const selectedLocations = itemCheckArrayToValue(checkLocation, locationOptions);
    const selectedEvps = itemCheckArrayToValue(checkEvps, evps.map((evp) => evp.id));
    const selectedVps = itemCheckArrayToValue(checkVps, vps.map((vp) => vp.id));
    const selectedBusinessTeams = itemCheckArrayToValue(checkBusinessTeams, businessTeamsOptions);

    const filters = {
      locations: selectedLocations,
      evps: selectedEvps,
      vps: selectedVps,
      businessTeams: selectedBusinessTeams,
    };

    const getImtData = async () => {
      try {
        const [decisionMakersList, businessTeamsList, managementTeamList] = await Promise.all([
          axios.post('/roll-call/decision-makers', filters, { signal: abortController.signal }),
          axios.post('/roll-call/business-teams', filters, { signal: abortController.signal }),
          axios.post('/incident-management-team', filters, { signal: abortController.signal }),
        ]);
        if (decisionMakersList?.data?.length >= 0) {
          setDecisionMakers(decisionMakersList.data.sort((a, b) => {
            if (a.location < b.location) {
              return -1;
            }
            if (a.location > b.location) {
              return 1;
            }
            return 0;
          }));
        }
        if (businessTeamsList?.data?.length >= 0) {
          setBusinessTeamRollCall(businessTeamsList.data);
        }
        if (managementTeamList?.data?.length >= 0) {
          setImtManagement(managementTeamList.data);
          setIMTOpen(true);
        }
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingDecisionMakers'));
      }
    };

    getImtData();
    return () => {
      abortController.abort();
    };
  }, [checkLocation, checkBusinessTeams, checkEvps, checkVps]);

  /**
   * Expand or collapse IMT data
   * @param {*} location - location of the IMT
   */
  const collapseImt = (location) => {
    if (nestedImtCollapsible.has(location)) {
      setNestedImtCollapsible(new Map(nestedImtCollapsible)
        .set(location, !nestedImtCollapsible.get(location)));
    } else {
      setNestedImtCollapsible(new Map(nestedImtCollapsible).set(location, true));
    }
  };

  /**
   * Selects all users of a team
   * @param {*} team - team
   */
  const teamSelect = (team) => {
    // if the team is already selected, remove it
    if (selectedRollCallTeams.includes(team.team)) {
      setSelectedRollCallTeams(selectedRollCallTeams
        .filter((rc) => rc !== team.team));

      setSelectedUsers([
        ...selectedUsers
          .filter((user) => !team.alternates
            .some((altVp) => altVp._id === user)
            && !team.vpLeads.some((vp) => vp._id === user)
            && !team.vpAlternates.some((vp) => vp._id === user)
            && !team.leads.some((lead) => lead._id === user)),
      ]);
    } else {
      setSelectedRollCallTeams([...selectedRollCallTeams, team.team]);
      setSelectedUsers(Array.from(new Set([...selectedUsers,
        ...team.alternates.map((altVp) => altVp._id),
        ...team.vpLeads.map((vp) => vp._id),
        ...team.vpAlternates.map((vp) => vp._id),
        ...team.leads.map((lead) => lead._id)])));
    }
  };

  return (
    <div className="imt">
      {!isLocationsOpen && !isBusinessTeamsOpen && !isVpsOpen && !isEvpsOpen && (
      <Card
        header={t('filters')}
        className="filter-header"
        functionalButton={(
          <>
            <button className="filter-button" type="button" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>{isFiltersOpen ? <LuChevronUpCircle title={t('closeDropdown')} /> : <LuChevronDownCircle title={t('expandDropdown')} />}</button>
          </>
      )}
      >
        {isFiltersOpen
        && (
        <div className="filters-container">
          <MultiSelectDisplay
            options={businessTeamsOptions}
            isSelectedMulti={checkBusinessTeams}
            setIsSelected={setCheckBusinessTeams}
            setIsOpen={setIsBusinessTeamsOpen}
            name={t('businessTeams')}
            isMulti
            placeholder={t('selectBusinessTeams')}
          />

          <MultiSelectDisplay
            options={evpOptions}
            isSelectedMulti={checkEvps}
            setIsSelected={setCheckEvps}
            setIsOpen={setIsEvpsOpen}
            name={t('evps')}
            isMulti
            placeholder={t('selectEVPs')}
          />

          <MultiSelectDisplay
            options={vpOptions}
            isSelectedMulti={checkVps}
            setIsSelected={setCheckVps}
            setIsOpen={setIsVpsOpen}
            name={t('vps')}
            isMulti
            placeholder={t('selectVPs')}
          />

          <MultiSelectDisplay
            options={locationOptions}
            isSelectedMulti={checkLocation}
            setIsSelected={setCheckLocation}
            setIsOpen={setIsLocationsOpen}
            name={t('locations')}
            isMulti
            placeholder={t('selectLocations')}
          />
          <div className="cancel-button">
            <BlueButton
              text={t('clearAll')}
              onClick={clearAll}
            />
          </div>
        </div>
        )}
      </Card>
      )}
      {(!isLocationsOpen && !isEvpsOpen && !isVpsOpen && !isBusinessTeamsOpen) && (
      <Card>
        <div className="imt-body">
          <div className="imt-body-header">
            <div className="cancel-button">
              <BlueButton
                text={t('clearAll')}
                onClick={() => {
                  setSelectedUsers([]);
                  selectedRollCallTeams([]);
                }}
              />
            </div>
            <div className="source-link">
              <Trans i18nKey="imtOrgChartSource">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={window.IMT_SOURCE_URL}
                >
                  Roles & Responsibilities
                </a>
              </Trans>
            </div>
            <BsPrinter className="printer-icon" title={t('exportToPdf')} />
          </div>

          <Card
            header={t('chainOfCommand')}
            isInternal
            functionalButton={<button className="filter-button" type="button" onClick={() => setChainOfCommandOpen(!isChainOfCommandOpen)}>{isChainOfCommandOpen ? <LuChevronUpCircle title={t('closeDropdown')} /> : <LuChevronDownCircle title={t('expandDropdown')} />}</button>}
          >
            <div className="chain-of-command">
              {isChainOfCommandOpen && !isSomeFilterChecked && <p className="empty-filter">{t('selectFilter')}</p>}
              {isChainOfCommandOpen && isSomeFilterChecked && decisionMakers.length > 0
                && (
                <div className="chain-of-command-body">
                  {decisionMakers.map((dmByLocation) => (
                    <div key={`dm-location-${dmByLocation.location}`}>
                      <h1 className="chain-of-command-location">{dmByLocation.location}</h1>
                      {dmByLocation.decisionMakers.map((user) => (
                        <div className="chain-of-command-user" key={user._id}>

                          { selectedUsers.includes(user._id) ? (
                            <button
                              type="button"
                              className="check-button"
                              onClick={() => {
                                setSelectedUsers(selectedUsers.filter((dm) => dm !== user._id));
                              }}
                            >
                              <FaRegCheckCircle className="check" title={t('selected')} />

                            </button>
                          )
                            : (
                              <button
                                type="button"
                                className="check-button"
                                onClick={() => { setSelectedUsers([...selectedUsers, user._id]); }}
                              >
                                <FaRegCircle className="check" title={t('notSelected')} />
                              </button>
                            )}
                          <p className="chain-of-command-user-name">{ `${user.firstName} ${user.lastName}`}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                )}
              {isChainOfCommandOpen && isSomeFilterChecked && decisionMakers.length === 0 && <p className="empty-filter">{t('noLocationsFound')}</p>}
            </div>
          </Card>

          <Card
            header={t('businessTeams')}
            isInternal
            functionalButton={(
              <button
                className="filter-button"
                type="button"
                onClick={() => setBTOpen(!isBTOpen)}
              >
                {
                  isBTOpen ? <LuChevronUpCircle title={t('closeDropdown')} />
                    : <LuChevronDownCircle title={t('expandDropdown')} />
                }
              </button>
          )}
          >
            {isBTOpen && !isSomeFilterChecked && <p className="empty-filter">{t('selectFilter')}</p>}
            { isBTOpen && isSomeFilterChecked && businessTeamRollCall.length > 0
            && (
            <div>
              <div className="button-container">
                <BlueButton onClick={invertAllExpanded} text={oneExpanded() ? t('collapseAll') : t('expandAll')} />
              </div>
              <div className="business-team">
                {businessTeamRollCall.map((team, index) => (
                  <CollapsibleRow
                    key={`row-${team.team}`}
                    isExpanded={expandedRows[index] === true}
                    invertExpanded={handleRowExpandedChange}
                    header={(
                      <div className="team-container">
                        <button
                          type="button"
                          className="check-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            teamSelect(team);
                          }}
                        >
                          {selectedRollCallTeams.includes(team.team)
                            ? <FaRegCheckCircle className="check" title={t('selected')} />
                            : <FaRegCircle className="check" title={t('notSelected')} />}
                        </button>
                        <p className="team-name">{team.team}</p>
                      </div>
                      )}
                    team={team}
                    rowIndex={index}
                  >
                    {getTeamDetails(team)}
                  </CollapsibleRow>
                ))}
              </div>
            </div>
            )}
          </Card>
          {isIMTOpen && isSomeFilterChecked && ImtManagement.length > 0
            && ImtManagement.map((mgmt) => (
              <Card
                key={`imt-location-${mgmt.location}`}
                header={(
                  <div className="imt-details-header">
                    <p>{t('incidentManagementTeam')}</p>
                    <p>{mgmt.location}</p>
                  </div>
                  )}
                isInternal
                functionalButton={(
                  <button
                    className="filter-button"
                    type="button"
                    onClick={() => {
                      collapseImt(mgmt.location);
                    }}
                  >
                    {nestedImtCollapsible.get(mgmt.location)
                      ? <LuChevronUpCircle title={t('closeDropdown')} />
                      : <LuChevronDownCircle title={t('expandDropdown')} />}
                  </button>
                )}
              >
                <>
                  {nestedImtCollapsible.get(mgmt.location) && mgmt.imtData
                    && Object.keys(mgmt.imtData).map((objectKey) => (
                      <div key={`${objectKey}-imt-location-${mgmt.location}`}>
                        <h1 className="chain-of-command-location">{objectKey}</h1>
                        {getImtUsers(mgmt.imtData[objectKey])}
                      </div>
                    ))}
                </>
              </Card>
            ))}
        </div>
      </Card>
      )}
      {isLocationsOpen && !isBusinessTeamsOpen && !isVpsOpen && !isEvpsOpen && (
      <DirectoryFilterSelect
        options={locationOptions}
        selectedOptions={itemCheckArrayToValue(checkLocation, locationOptions)}
        setSelected={setCheckLocation}
        name={t('locations')}
        setOpen={setIsLocationsOpen}
        isMulti
      />
      )}
      {!isLocationsOpen && isBusinessTeamsOpen && !isVpsOpen && !isEvpsOpen && (
      <DirectoryFilterSelect
        options={businessTeamsOptions}
        selectedOptions={itemCheckArrayToValue(checkBusinessTeams, businessTeamsOptions)}
        setSelected={setCheckBusinessTeams}
        name={t('businessTeams')}
        setOpen={setIsBusinessTeamsOpen}
        isMulti
      />
      )}
      {!isLocationsOpen && !isBusinessTeamsOpen && isVpsOpen && !isEvpsOpen && (
      <DirectoryFilterSelect
        options={vpOptions}
        selectedOptions={itemCheckArrayToValue(checkVps, vpOptions)}
        setSelected={setCheckVps}
        name={t('vps')}
        setOpen={setIsVpsOpen}
        isMulti
      />
      )}
      {!isLocationsOpen && !isBusinessTeamsOpen && !isVpsOpen && isEvpsOpen && (
      <DirectoryFilterSelect
        options={evpOptions}
        selectedOptions={itemCheckArrayToValue(checkEvps, evpOptions)}
        setSelected={setCheckEvps}
        name={t('evps')}
        setOpen={setIsEvpsOpen}
        isMulti
      />
      )}
    </div>
  );
};

export default IncidentsManagementTeamsPage;
