import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  faPlus, faCheck, faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useBlocker } from 'react-router-dom';
import axios from '../services/Api';
import TwoButtonModal from './TwoButtonModal';
import InputComponent from './InputComponent';
import CircledButton from './CircledButton';
import Card from './Card';
import colors from '../styles/base/_colors.module.scss';

// styles
import '../styles/components/EditExternalSupport.scss';

const EditExternalSupport = ({
  externalSupport, onClose, updateExternalSupport,
}) => {
  const { t } = useTranslation();
  const [errorTextRequired, setErrorTextRequired] = useState({
    name: '',
    location: [],
    phone: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  const [externalSupportChanges, setExternalSupportChanges] = useState({
    _id: externalSupport._id,
    name: externalSupport.name,
    contact: externalSupport.contact,
    phoneNumber: externalSupport.phoneNumber,
    address: externalSupport.address,
    accountNumber: externalSupport.accountNumber,
    location: externalSupport.location,
    type: externalSupport.type,
  });

  const dropDownStyles = {
    container: (provided) => ({
      ...provided,
      width: 'calc(100% - 2rem)',
      marginLeft: '1rem',
      fontSize: '1rem',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'inherit',
      border: 'none',
      borderBottom: '#888888 2px solid',
      color: colors.DarkBlue,
      paddingInline: '',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.DarkBlue,
    }),
    input: (provided) => ({
      ...provided,
      color: colors.DarkBlue,
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? colors.DropdownSelect : colors.Dropdown,
      color: colors.DarkBlue,
    }),
  };
  useEffect(() => {
    const abortController = new AbortController();
    window.scrollTo(0, 0);
    const getLocations = async () => {
      try {
        const { data } = await axios.get('/constants/locations', { signal: abortController.signal });
        const sortedData = data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (b.name > a.name) {
            return -1;
          }
          return 0;
        });
        setAllLocations(sortedData);
        setLocationOptions(sortedData.filter(
          (loc) => !externalSupport.location.some(
            (existingLocation) => existingLocation === loc.name,
          ),
        ));
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingLocations'));
      }
    };
    getLocations();

    return () => {
      abortController.abort();
    };
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      let error = false;
      if (externalSupportChanges.name.trim() === '') {
        error = true;
        setErrorTextRequired((prev) => ({ ...prev, name: t('requiredError', { header: t('name') }) }));
      } else {
        setErrorTextRequired((prev) => ({ ...prev, name: '' }));
      }

      if (externalSupportChanges.phoneNumber && externalSupportChanges.phoneNumber.length !== 10) {
        error = true;
        setErrorTextRequired((prev) => ({ ...prev, phone: t('invalidPhone', { header: t('phoneNumber') }) }));
      } else {
        setErrorTextRequired((prev) => ({ ...prev, phone: '' }));
      }

      const tempLocationError = [];
      for (let i = 0; i < externalSupportChanges.location.length; i += 1) {
        if (externalSupportChanges.location[i] === '') {
          error = true;
          tempLocationError.push(t('locationRequired'));
        } else {
          tempLocationError.push('');
        }
      }
      setErrorTextRequired((prev) => ({ ...prev, location: tempLocationError }));

      if (error) {
        toast(t('saveExternalSupportFail'), { type: 'error' });
      } else {
        const { data } = await toast.promise(axios.post('admin/external-support', externalSupportChanges),
          externalSupportChanges._id ? {
            success: t('updatedExternalSupport'),
            pending: t('updatingExternalSupport'),
            error: t('errorUpdatingExternalSupport'),
          }
            : {
              success: t('addedExternalSupport'),
              pending: t('addingExternalSupport'),
              error: t('errorAddingExternalSupport'),
            });
        updateExternalSupport(
          {
            ...externalSupportChanges,
            _id: externalSupportChanges._id ? externalSupportChanges._id : data._id,
          }, false,
        );
        onClose();
      }
    } catch {
    // handled by toast.promise
    }
  };

  const handleDelete = async () => {
    try {
      await toast.promise(axios.post('admin/external-support/delete', {
        externalSupportId: externalSupport._id,
      }), {
        pending: t('deletingExternalSupport'),
        success: t('deletedExternalSupport'),
        error: t('errorDeletingExternalSupport'),
      });
      updateExternalSupport(externalSupportChanges, true);
      onClose();
    } catch {
      setDeleteModalOpen(false);
    }
  };

  const handleChange = (change, key, index) => {
    const temp = JSON.parse(JSON.stringify(externalSupportChanges));
    if (key === 'location') {
      if (change === 'selectAllLocations') {
        temp.location = allLocations.map((loc) => loc.name);
        setLocationOptions([]);
      } else {
        setLocationOptions(allLocations.filter(
          (loc) => !externalSupportChanges.location.some(
            (existingLocation, i) => existingLocation === loc.name && i !== index,
          ) && loc.name !== change,
        ));
        temp[key][index] = change;
      }
    } else {
      temp[key] = change;
    }
    setExternalSupportChanges(temp);
  };

  const isSupportEdited = () => {
    let isEdited = false;
    Object.keys(externalSupportChanges).forEach((key) => {
      if (key === 'location') {
        if (externalSupportChanges[key].length !== externalSupport[key].length) {
          isEdited = true;
        } else {
          for (let i = 0; i < externalSupportChanges[key].length; i += 1) {
            if (externalSupportChanges[key][i] !== externalSupport[key][i]) {
              isEdited = true;
              break;
            }
          }
        }
      } else if (externalSupportChanges[key] !== externalSupport[key]) {
        isEdited = true;
      }
    });
    return (isEdited);
  };

  const handleDeleteLocation = (index) => {
    const newLocations = externalSupportChanges.location.filter((loc, i) => i !== index);
    setLocationOptions(allLocations.filter(
      (loc) => !newLocations.some((newLoc) => newLoc === loc.name),
    ));
    setExternalSupportChanges({
      ...externalSupportChanges,
      location: newLocations,
    });
  };
  const handleAddLocation = () => {
    setExternalSupportChanges({
      ...externalSupportChanges,
      location: [...externalSupportChanges.location, ''],
    });
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => (
      currentLocation.pathname !== nextLocation.pathname && isSupportEdited()),
  );
  useEffect(() => {
    const unloadCallback = (e) => {
      e.preventDefault();
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const handleCloseEdit = (e) => {
    e.preventDefault();
    if (isSupportEdited()) {
      setIsModalOpen(true);
    } else {
      onClose();
    }
  };
  return (
    <div className="edit-external-support">
      {externalSupport && (
      <Card
        header={(
          <div className="external-support-header-edit">
            <h1>{t('externalSupport')}</h1>
            <div className="external-support-header-edit-buttons">
              <CircledButton onClick={(e) => handleSave(e)}>
                <FontAwesomeIcon icon={faCheck} title={t('updateExternalSupport')} />
              </CircledButton>
              <CircledButton onClick={(e) => handleCloseEdit(e)}>
                <FontAwesomeIcon icon={faX} title={t('cancelChanges')} />
              </CircledButton>
            </div>
          </div>
        )}
      >
        <div className="external-support-items-container">
          <InputComponent id="external-support-name" header={t('name')} type="string" value={externalSupportChanges.name} onChange={(change) => handleChange(change, 'name')} required name="name" limit={100} />
          {errorTextRequired.name && (
          <p className="err">{errorTextRequired.name}</p>
          )}
          <InputComponent id="external-support-contact" header={t('contact')} type="string" value={externalSupportChanges.contact} onChange={(change) => handleChange(change, 'contact')} name="last-name" limit={100} />
          <InputComponent id="external-support-account-number" header={t('accountNumber')} type="string" value={externalSupportChanges.accountNumber} onChange={(change) => handleChange(change, 'accountNumber')} name="account-number" limit={50} />
          <InputComponent id="external-support-phoneNumber" header={t('phoneNumber')} type="number" value={externalSupportChanges.phoneNumber} onChange={(change) => handleChange(change, 'phoneNumber')} name="phone-number" limit={10} phone />
          {errorTextRequired.phone && (
          <p className="err">{errorTextRequired.phone}</p>
          )}
          <InputComponent id="external-support-address" header={t('address')} type="string" value={externalSupportChanges.address} onChange={(change) => handleChange(change, 'address')} name="address" limit={500} />
          <InputComponent id="external-support-type" header={t('type')} type="string" value={externalSupportChanges.type} onChange={(change) => handleChange(change, 'type')} name="type" limit={50} />
          <h2>
            {t('locations')}
          </h2>

          {
            externalSupportChanges.location.map((supportLoc, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="location-row" key={index}>
                <div className="select-location">

                  <Select
                    onChange={(change) => handleChange(change.value, 'location', index)}
                    options={locationOptions.length !== 0
                      ? [
                        {
                          value: 'selectAllLocations',
                          label: t('selectAllLocations'),
                        }, ...locationOptions.map((loc) => ({
                          value: loc.name,
                          label: loc.name,
                        })),
                      ]
                      : []}
                    styles={dropDownStyles}
                    value={{
                      label: supportLoc || t('selectLocation'),
                      value: supportLoc,
                    }}
                  />
                  {errorTextRequired.location[index] && (
                    <p className="err">{errorTextRequired.location[index]}</p>
                  )}
                </div>
                {externalSupportChanges.location.length > 1 && (
                  <button
                    type="button"
                    onClick={() => { handleDeleteLocation(index); }}
                  >
                    <FaRegTrashAlt title={t('deleteLocation')} />
                  </button>
                )}
              </div>
            ))

          }
          <div className="add-location-button">
            <button
              type="button"
              className="role-button"
              onClick={() => handleAddLocation()}
            >
              <FontAwesomeIcon icon={faPlus} title={t('addProperty')} />
            </button>
            <h2 className="button-header">{t('addLocation')}</h2>
          </div>

          {blocker.state === 'blocked' && (
          <TwoButtonModal
            isOpen
            redButtonFunctionality={() => blocker.proceed()}
            blueButtonFunctionality={() => blocker.reset()}
            closeFunctionality={() => blocker.reset()}
            headerText={t('moveAwayModalHeader')}
            redButtonText={t('leave')}
            blueButtonText={t('stay')}
          />
          )}
          <TwoButtonModal
            isOpen={isModalOpen}
            redButtonFunctionality={() => onClose()}
            blueButtonFunctionality={() => setIsModalOpen(false)}
            closeFunctionality={() => setIsModalOpen(false)}
            headerText={t('discardModalText')}
            redButtonText={t('discard')}
            blueButtonText={t('cancel')}
          />

          <TwoButtonModal
            isOpen={deleteModalOpen}
            redButtonFunctionality={() => handleDelete()}
            blueButtonFunctionality={() => setDeleteModalOpen(false)}
            closeFunctionality={() => setDeleteModalOpen(false)}
            headerText={t('deleteModalText')}
            redButtonText={t('yes')}
            blueButtonText={t('no')}
          />

        </div>

        <div className="three-button-container">
          <button type="button" className="blue-button" onClick={(e) => handleSave(e)}>{t('save')}</button>
          <button type="button" className="blue-button" onClick={(e) => handleCloseEdit(e)}>{t('discard')}</button>
          {(externalSupport._id) && <button type="button" className="red-button" onClick={() => setDeleteModalOpen(true)}>{t('delete')}</button>}
        </div>

      </Card>
      )}
    </div>
  );
};

export default EditExternalSupport;

EditExternalSupport.defaultProps = {
  onClose: () => { },
  updateExternalSupport: () => {},
};

EditExternalSupport.propTypes = {
  onClose: PropTypes.func,
  updateExternalSupport: PropTypes.func,
  externalSupport: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    contact: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountNumber: PropTypes.string,
    address: PropTypes.string,
    location: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
  }).isRequired,
};
