import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputComponent from './InputComponent';
import axios from '../services/Api';

import '../styles/components/ITRequirementsModal.scss';

const ITRequirementsModal = ({
  isOpen, headerText, closeFunctionality, requirement, updateRequirements,
}) => {
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [workingRequirement, setWorkingRequirement] = useState(requirement);
  const onSave = async () => {
    if (workingRequirement.name.trim().length === 0) {
      setNameError(true);
    } else {
      try {
        if (!workingRequirement._id) {
          const { data } = await toast.promise(axios.post('/internalITRequirements/create', workingRequirement), {
            success: t('createdRequirement'),
            pending: t('creatingRequirement'),
            error: t('errorCreatingRequirement'),
          });
          updateRequirements({
            _id: data._id,
            name: workingRequirement.name,
          }, false);
        } else {
          await toast.promise(axios.put(`/internalITRequirements/update/${workingRequirement._id}`, workingRequirement), {
            success: t('updatedRequirement'),
            pending: t('updatingRequirement'),
            error: t('errorUpdatingRequirement'),
          });
          updateRequirements(workingRequirement, false);
        }
        closeFunctionality();
      } catch (e) {
        // handled by toast error
      }
    }
  };

  const onDelete = async () => {
    try {
      await toast.promise(axios.delete(`internalITRequirements/delete/${workingRequirement._id}`), {
        success: t('deletedRequirement'),
        pending: t('deletingRequirement'),
        error: t('errorDeletingRequirement'),
      });
      updateRequirements(workingRequirement, true);
      closeFunctionality();
    } catch (e) {
      // handled by toast error
    }
  };

  const handleChange = (change) => {
    setWorkingRequirement((prev) => ({
      ...prev,
      name: change,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          zIndex: '999',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          backdropFilter: 'blur(5px)',
        },
        content: {
          position: 'absolute',
          zIndex: '1000',
          width: '500px',
          height: 'fit-content',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          borderRadius: '15px',
          backgroundColor: '#002F6C',
          boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <div className="it-requirements-modal">
        <div className="header">
          <h1>
            {headerText}
          </h1>
          <button type="button" onClick={closeFunctionality}>
            <div className="inner-border">
              <FontAwesomeIcon icon={faX} />
            </div>
          </button>
        </div>
        <div className="body-color">
          <div className="modal-body">
            <InputComponent
              id="it-requirement"
              header={t('itRequirementName')}
              required
              type="string"
              value={workingRequirement.name}
              onChange={(change) => handleChange(change)}
              name="it-requirement-name"
              limit={50}
            />
            {nameError && (
            <p className="err">{t('itRequirementNameError')}</p>
            )}
          </div>
          <div className="button-container">
            <button type="button" className="blue-button" onClick={() => onSave()}>{t('save')}</button>
            <button type="button" className="blue-button" onClick={() => closeFunctionality()}>{t('discard')}</button>
            {workingRequirement._id && <button type="button" className="red-button" onClick={() => onDelete()}>{t('delete')}</button>}
          </div>
        </div>

      </div>
    </Modal>
  );
};

ITRequirementsModal.defaultProps = {
  isOpen: false,
  headerText: '',
  requirement: {
    _id: '',
    name: '',
  },
  closeFunctionality: () => { },
  updateRequirements: () => {},
};
ITRequirementsModal.propTypes = {
  isOpen: PropTypes.bool,
  headerText: PropTypes.string,
  closeFunctionality: PropTypes.func,
  requirement: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  updateRequirements: PropTypes.func,
};

export default ITRequirementsModal;
