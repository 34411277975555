import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons/lib';
import { TiPlus, TiPencil } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from '../../../services/Api';
import Card from '../../Card';
import SearchBar from '../../SearchBar';
import highlight from '../../../helpers/searchHighlight';
import '../../../styles/components/pages/admin/EssentialBusinessPartners.scss';

const EssentialBusinessPartners = () => {
  const [fetchData, setFetchData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const fetchDataAsync = async () => {
      try {
        const response = await axios.get('/ebps', { signal: controller.signal });
        const names = response.data;
        const sortedEbps = names.filter((ebp) => ebp?.name).sort((a, b) => a?.name?.toLowerCase()
          .localeCompare(b?.name?.toLowerCase()));
        setFetchData(sortedEbps);
        setDisplayData(sortedEbps);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingData'));
      }
    };

    fetchDataAsync();
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      setDisplayData(fetchData);
    } else {
      const filterData = fetchData.filter((item) => item?.name?.toLowerCase()
        .includes(searchValue?.toLowerCase()));
      const sortedEbps = filterData.sort((a, b) => a?.name?.toLowerCase()
        .localeCompare(b?.name?.toLowerCase()));
      setDisplayData(sortedEbps);
    }
  }, [searchValue, fetchData]);

  const handleEditClick = (id) => {
    navigate(`/admin/essential-business-partners/edit/${id}`);
  };

  const handleAddClick = () => {
    navigate('/admin/essential-business-partners/edit');
  };

  return (
    <div className="Essential-Business-page">
      <Card header={<div>{t('searchEssentialBusinessPartners')}</div>}>
        <div className="select-card-body">
          <div className="searchbar">
            <SearchBar
              placeholder={t('searchByName')}
              label={t('businessPartnerSearch')}
              type="text"
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              id="businessPartnerSearch"
            />
          </div>
        </div>
      </Card>

      <Card
        className="essential-business-card"
        header={(
          <div className="essential-business-partner-header">
            <h2>{t('essentialBusinessPartners')}</h2>
            <div className="excel-container">
              <IconContext.Provider value={{ size: '1.1em' }}>
                <button
                  type="button"
                  className="add-button"
                  onClick={handleAddClick}
                >
                  <span>{t('add')}</span>
                  <TiPlus />
                </button>
              </IconContext.Provider>
            </div>
          </div>
        )}
      >
        <div className="items-container">
          {displayData.length > 0 ? (
            displayData.map((item, index) => (
              <div
                tabIndex="0"
                role="button"
                onKeyDown={() => handleEditClick(item._id)}
                onClick={() => handleEditClick(item._id)}
                className="EssentialBusiness-items"
                key={item._id || `item-${index}`}
              >
                <div className="left-side">
                  <div>{item?.name && highlight(item.name, searchValue)}</div>
                </div>
                <div className="right-side">

                  <TiPencil />

                </div>
              </div>
            ))
          ) : (
            <div>{t('noResults')}</div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default EssentialBusinessPartners;
