import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FaRegTrashAlt } from 'react-icons/fa';
import updateToast from '../../services/toastService';
import axios from '../../services/Api';
import downloadDocument from '../../hooks/downloadDocumentHook';
import PageSvg from '../../assets/bcc-quick-reference/page.svg';
import UserContext from '../../contexts/UserContext';
import UploadModal from '../UploadModal';
import TwoButtonModal from '../TwoButtonModal';

import '../../styles/components/pages/EmergencyProceduresPage.scss';

/**
 * Renders the EmergencyProceduresPage
 * @returns
 */
const EmergencyProceduresPage = () => {
  const [procedures, setProcedures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDownloadLoading, setIsDownloadLoading] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [procedureText, setProcedureText] = useState('');
  const [newDoc, setNewDoc] = useState(null);
  const [docToUpload, setDocToUpload] = useState({});
  const { user } = useContext(UserContext);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (newDoc) {
      const doc = {};
      doc.formData = new FormData();
      doc.formData.append('file', newDoc, newDoc.name);
      setDocToUpload(doc);
    }
  }, [newDoc]);

  /**
   * Deletes an emergency procedure
   */
  const deleteProcedure = async () => {
    const toastId = toast.loading(t('deletingProcedure'));
    try {
      await axios.post('/admin/emergency-procedures/delete', { procedure: procedureText });
      updateToast(toastId, t('procedureDeleted'), toast.TYPE.SUCCESS);
      setProcedures([...procedures.filter((name) => name !== procedureText)]);
    } catch (error) {
      updateToast(toastId, t('deletingProcedureFailed'), toast.TYPE.ERROR);
    }
    setProcedureText('');
  };

  /**
   * Adds an emeregncy procedure
   */
  const addProcedure = async () => {
    const toastId = toast.loading(t('addingProcedure'));
    try {
      const { data } = await axios.post(`/admin/emergency-procedures/${procedureText}`, docToUpload.formData);
      if (data) {
        setProcedures([...procedures, data.name.replace('procedure-', '')]);
        updateToast(toastId, t('procedureAdded'), toast.TYPE.SUCCESS);
      }
    } catch (error) {
      updateToast(toastId, t('addingProcedureFailed'), toast.TYPE.ERROR);
    }
    setProcedureText('');
    setOpenUploadModal(false);
  };

  /**
   * Renders the download button
   * @param {*} text - file name
   * @param {*} t - translation function
   * @param {*} setIsDownloadLoading
   * @param {*} isDownloadLoading
   * @param {*} index
   * @returns download button with functionality
   */
  const downloadButton = (text, index) => {
    const handleLoad = (state) => {
      const temp = [...isDownloadLoading];
      temp[index] = state;
      setIsDownloadLoading(temp);
    };
    const handleDownload = async (docName) => {
      const toastId = toast.loading(t('downloadInProgress'));
      handleLoad(true);
      try {
        await downloadDocument(`/emergency-procedures/${docName}`, `emergency-procedure-${docName}`);
        updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
      } catch (err) {
        updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
      } finally {
        handleLoad(false);
      }
    };
    return (
      <div className="download-button" key={`${text}-div`}>
        {!isDownloadLoading[index] && <h2>{text}</h2>}
        {isDownloadLoading[index] && <h2>{t('loading')}</h2>}
        <div className="buttons-div">
          <button className="transparent-button" type="button" onClick={() => handleDownload(text)} key={text} disabled={isDownloadLoading[index]}>
            <div className="icons">
              <img src={PageSvg} alt={t('downloadPdf')} />
            </div>
          </button>
          {user?.isAdmin && (
          <button className="transparent-button" type="button" onClick={() => { setDeleteModalOpen(true); setProcedureText(text); }} key={`${text}-delete`}>
            <FaRegTrashAlt title={t('deleteEmergencyProcedure')} />
          </button>
          )}
        </div>

      </div>
    );
  };

  useEffect(() => {
    const abortController = new AbortController();

    const getProcedures = async () => {
      try {
        const { data } = await axios.get('/emergency-procedures', { signal: abortController.signal });
        if (data) {
          setProcedures(data);
          setIsDownloadLoading(Array(data.length).fill());
        }
        setLoading(false);
      } catch (error) {
        if (error.name === 'CanceledError') return;
        toast(t('procedureError'), {
          type: 'error',
        });
      }
    };

    getProcedures();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="emergency-procedures">
      <div className="emergency-procedures-header">
        {user?.isAdmin
         && (
         <div
           className="add-emergency-procedures"
           tabIndex="0"
           role="button"
           onKeyDown={() => setOpenUploadModal(true)}
           onClick={() => setOpenUploadModal(true)}
         >
           <AiOutlineUserAdd size="1em" />
           <p>{t('add')}</p>
         </div>
         )}
      </div>
      {procedures.length > 0 && !loading
      && (
      <div className="links-container">
        {procedures && procedures.filter((name) => name !== null)
          .map((name, index) => downloadButton(
            name, index,
          ))}
      </div>
      )}
      {procedures.length === 0 && !loading
        && <p>{t('noProcedures')}</p>}
      {loading
      && <p>{t('loading')}</p>}
      {user?.isAdmin && (
      <UploadModal
        isOpen={openUploadModal}
        headerText={t('addEmergencyProcedures')}
        closeFunctionality={() => { setOpenUploadModal(false); setProcedureText(''); }}
        label={t('newProcedureName')}
        onSave={() => addProcedure()}
        doc={newDoc}
        setDoc={setNewDoc}
        fileName={procedureText}
        setFileName={setProcedureText}
      />
      )}
      <TwoButtonModal
        isOpen={deleteModalOpen}
        redButtonFunctionality={() => { setDeleteModalOpen(false); deleteProcedure(); }}
        blueButtonFunctionality={() => { setDeleteModalOpen(false); setProcedureText(''); }}
        closeFunctionality={() => { setDeleteModalOpen(false); setProcedureText(''); }}
        headerText={t('deleteEmergencyProcedure', { item: procedureText })}
        redButtonText={t('delete')}
        blueButtonText={t('cancel')}
      />
    </div>
  );
};

export default EmergencyProceduresPage;
