import React from 'react';
import PropTypes from 'prop-types';
import { LuChevronDownCircle, LuChevronUpCircle } from 'react-icons/lu';
import '../styles/components/CollapsibleRow.scss';

/**
 * CollapsibleRow component
 * @param {header} element
 * @param {children} element
 * @param {isExpanded} boolean
 * @param {invertExpanded} function
 * @param {rowIndex} number
 * @returns {JSX} JSX to render the component
 */

const CollapsibleRow = ({
  header, children, isExpanded, invertExpanded, rowIndex, customHeaderClass,
}) => (
  <div className="wrapper">
    <div
      className="collapsible-row"
      tabIndex="0"
      role="button"
      onKeyDown={() => invertExpanded(rowIndex)}
      onClick={() => invertExpanded(rowIndex)}
    >
      <div className={`row-header ${customHeaderClass}`}>
        {header}
      </div>
      {isExpanded ? <LuChevronUpCircle size="20px" className="arrow" /> : <LuChevronDownCircle size="20px" className="arrow" />}
    </div>
    {isExpanded && (
      <div className="row-container">
        { children }
      </div>
    )}
  </div>
);

CollapsibleRow.defaultProps = {
  isExpanded: false,
  customHeaderClass: '',
};

CollapsibleRow.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  invertExpanded: PropTypes.func.isRequired,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  customHeaderClass: PropTypes.string,
};

export default CollapsibleRow;
