import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import colors from '../styles/base/_colors.module.scss';

const StyledSelect = ({
  defaultValue, onChange, options, value, placeholderText, ...props
}) => {
  // styles for the select
  const { t } = useTranslation();

  const dropDownStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '1rem',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: colors.Card,
      borderColor: colors.DarkBlue,
      borderRadius: '8px',
      color: colors.DarkBlue,
      '&:hover': {
        borderColor: colors.DarkBlue,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.DarkBlue,
    }),
    input: (provided) => ({
      ...provided,
      color: colors.DarkBlue,
    }),
    menu: (provided) => ({
      ...provided,
      color: colors.LightBlue,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? colors.DropdownSelect : colors.Dropdown,
      color: colors.DarkBlue,
    }),
  };

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      autoFocus={false}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      styles={dropDownStyles}
      value={value || undefined}
      placeholder={placeholderText || t('selectPlaceHolder')}
    />
  );
};

StyledSelect.defaultProps = {
  defaultValue: null,
  value: null,
  placeholderText: null,
};

StyledSelect.propTypes = {
  defaultValue: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholderText: PropTypes.string,
};

export default StyledSelect;
