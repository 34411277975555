export default {
  Ok: 200,
  NotModified: 304,
  Forbidden: 403,
  NotFound: 404,
  IntenalServerError: 500,

  // Helper methods for determining if code falls in a defined range
  /**
   * Check if the given code falls in the "1xx Informational response" status range
   * @param {*} code code to check
   * @returns true if it's between 100 and 199
   */
  isInformation: (code) => code >= 100 && code < 200,
  /**
   * Check if the given code falls in the "2xx Success" status range
   * @param {*} code code to check
   * @returns true if it's between 200 and 299
   */
  isSuccess: (code) => code >= 200 && code < 300,
  /**
   * Check if the given code falls in the "3xx Redirection" status range
   * @param {*} code code to check
   * @returns true if it's between 300 and 399
   */
  isRedirect: (code) => code >= 300 && code < 400,
  /**
   * Check if the given code falls in the "4xx Client Error" status range
   * @param {*} code code to check
   * @returns true if it's between 400 and 499
   */
  isClientError: (code) => code >= 400 && code < 500,
  /**
   * Check if the given code falls in the "5xx Server Error" status range
   * @param {*} code code to check
   * @returns true if it's between 500 and 599
   */
  isServerError: (code) => code >= 500 && code < 600,
};
