import React, {
  useEffect, useContext, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SiGooglemaps } from 'react-icons/si';
import { FiPhone } from 'react-icons/fi';
import sanitizeHtml from 'sanitize-html';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import Card from '../Card';
import '../../styles/components/pages/ExternalSupportPage.scss';
import CollapsibleRow from '../CollapsibleRow';
import BlueButton from '../BlueButton';
import formatPhoneNumber from '../../helpers/phone';
import SearchBar from '../SearchBar';
import MultiSelectDisplay from '../MultiSelectDisplay';
import DirectoryFilterSelect from '../DirectoryFilterSelect';
import UserContext from '../../contexts/UserContext';
import { getExternalSupportLocations, postExternalSupport } from '../../hooks/ExternalSupportHooks';
import highlight from '../../helpers/searchHighlight';
import EditExternalSupport from '../EditExternalSupport';
import editBtn from '../../assets/images/profile-page/editBtn.svg';

const ExternalSupportPage = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);
  const [page, setPage] = useState([]);
  const [nextPage, setNextPage] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [locationsFilterModalOpen, setLocationsFilterModalOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [workingExternalSupport, setWorkingExternalSupport] = useState(null);
  const { user: currentUser } = useContext(UserContext);
  const isAdmin = currentUser?.isAdmin;
  const handleFetchLocations = async (abortSignal) => getExternalSupportLocations(abortSignal);

  const handleRowExpandedChange = (index) => {
    const temp = [...isExpanded];
    temp[index] = !temp[index];
    setIsExpanded(temp);
  };
  const allExpanded = () => {
    if (isExpanded.length === 0) {
      return false;
    }
    return isExpanded.every((expansion) => expansion !== true);
  };

  const invertAllExpanded = () => {
    const allAreExpanded = allExpanded();
    const newExpandedRows = [...isExpanded];
    const final = newExpandedRows.map(() => allAreExpanded);
    setIsExpanded(final);
  };

  const [filters, updateFilters] = useReducer(
    (state, action) => {
      const updates = {};
      if (action.location) {
        const tempLocations = locations.filter((_, i) => action.location[i]);
        // non-admin users shouldn't be able to view all external supports by passing no locations
        updates.locations = tempLocations.length > 0 ? tempLocations : locations;
      }
      if (action.name !== undefined) {
        updates.search = action.name;
      }
      return {
        ...state,
        ...updates,
      };
    },
    {
      search: '',
      locations,
    },
  );

  const addExternalSupport = () => {
    setWorkingExternalSupport({
      _id: undefined,
      name: '',
      accountNumber: '',
      contact: '',
      phoneNumber: '',
      address: '',
      location: [],
      type: '',
    });
  };

  const handleFetchExternalSupports = async (abortSignal) => {
    try {
      const data = await Promise.all([
        postExternalSupport(pageIndex, filters, abortSignal),
        postExternalSupport(pageIndex + 1, filters, abortSignal),
      ]);
      setIsExpanded(new Array(data[0].length).fill(false));
      setPage(data[0]);
      setNextPage(data[1]);
    } catch (err) {
      if (err.name === 'CanceledError') {
        return;
      }
      toast(t('couldNotRetrieveLocations'), { type: 'error' });
    }
  };

  const updateExternalSupport = (externalSupportChanges, deleted) => {
    const updated = page.find((req) => req._id === externalSupportChanges._id);
    if (deleted) {
      const temp = page.filter((p) => p._id !== externalSupportChanges._id);
      setPage(temp);
    } else if (updated) {
      const temp = page.map(
        (p) => (p._id === externalSupportChanges._id ? externalSupportChanges : p),
      );
      temp.sort((a, b) => a.name.localeCompare(b.name));
      setPage(temp);
    } else {
      setPage([...page, externalSupportChanges]);
    }
  };

  useEffect(() => {
    // runs on intial render
    const abortController = new AbortController();
    const loadLocations = async () => {
      try {
        const data = await handleFetchLocations(abortController.signal);
        setLocations(data);
        // trigger filter to call external support endpoint with new locations set above
        updateFilters({ location: [] });
        setIsPageLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingDirectory'));
      }
    };
    loadLocations();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    handleFetchExternalSupports(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [filters]);

  useEffect(() => {
    // Don't filter if page is initially loading (no filters have been set by the user)
    if (isPageLoading && !search) return () => {};
    const timeout = setTimeout(() => {
      updateFilters({ name: search });
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const fetchNextPage = async (i) => {
    const abortController = new AbortController();

    if (nextPage.length > 0) {
      try {
        const data = await postExternalSupport(i + 1, filters, abortController.signal);
        setPage([...page, ...nextPage]);
        setNextPage(data);
        setIsExpanded([...isExpanded, ...new Array(nextPage.length).fill(false)]);
        setPageIndex(pageIndex + 1);
      } catch (err) {
        toast(t('couldNotRetrieveExternalSupportNextPage'), { type: 'error' });
      }
    }
  };

  const checkIfItemExists = (condition, children) => {
    if (condition) {
      return (children);
    }
    return (<p className="dark-blue-text">{t('notApplicable')}</p>);
  };

  return (
    <div className="external-support">
      {!locationsFilterModalOpen && !workingExternalSupport && (
        <>
          <Card
            header={<h2>{t('filterExternalSupport')}</h2>}
            className="filter-header"
          >
            <div className="filters-container">
              <SearchBar
                placeholder={t('search')}
                label={t('externalSupportSearch')}
                id="externalSupportSearch"
                type="text"
                value={search}
                disabled={false}
                onChange={setSearch}
              />
              <MultiSelectDisplay
                options={locations}
                isSelectedMulti={locations.map(
                  (option) => filters.locations.findIndex((o) => o === option) !== -1,
                )}
                setIsSelected={(l) => updateFilters({ location: l })}
                setIsOpen={setLocationsFilterModalOpen}
                name={t('locations')}
                isMulti
              />
            </div>
          </Card>
          { page.length === 0 || locations.length === 0 ? (
            <Card header={(
              <div className="external-support-header">
                <h2>{t('noLocations')}</h2>
                {isAdmin && (
                <button type="button" onClick={() => addExternalSupport()}>
                  <span>{t('addPlus')}</span>
                </button>
                )}
              </div>
            )}
            >
              <div className="no-locations">
                <h1>{locations.length > 0 ? t('noExternalSupportsMatchSearch') : t('noLocationsHaveExternalSupport')}</h1>
              </div>
            </Card>
          ) : (
            <Card header={(
              <div className="external-support-header">
                <h2>
                  {' '}
                  {t('externalSupportResults')}
                </h2>
                {isAdmin && (
                <button type="button" onClick={() => addExternalSupport()}>
                  <span>{t('addPlus')}</span>
                </button>
                )}
              </div>
            )}
            >
              <InfiniteScroll
                dataLength={pageIndex * 25}
                next={() => fetchNextPage(pageIndex)}
                hasMore={nextPage.length > 0}
              >
                <div className="button-container">
                  <BlueButton onClick={() => invertAllExpanded()} text={allExpanded() ? t('expandAll') : t('collapseAll')} />
                </div>
                <div className="external-supports-data">
                  {page.map((item, index) => (
                    <CollapsibleRow
                      key={item._id}
                      header={(
                        <div className="external-support-row">
                          {highlight(item.name, search)}
                          {isAdmin && (
                          <button
                            type="button"
                            data-testid="edit-external-support-pencil"
                            onClick={() => setWorkingExternalSupport(item)}
                          >
                            <img src={editBtn} alt={t('editExternalSupport')} />
                          </button>
                          )}
                        </div>
                      )}
                      isExpanded={isExpanded[index]}
                      invertExpanded={handleRowExpandedChange}
                      rowIndex={index}
                      customHeaderClass="increase-width"
                    >
                      <h3 className="medium-text">{t('contact')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.contact, <p className="dark-blue-text">{item.contact}</p>)}
                      </div>
                      <h3 className="medium-text">{t('accountNumber')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.contact, <p className="dark-blue-text">{item.accountNumber}</p>)}
                      </div>
                      <h3 className="medium-text">{t('phoneNumber')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.phoneNumber,
                          <a
                            className="dark-blue-text phone-link"
                            aria-label={`${t('call', { number: item.phoneNumber })}`}
                            href={sanitizeHtml(`tel:${item.phoneNumber}`)}
                          >
                            <p className="dark-blue-text">{item.phoneNumber && formatPhoneNumber(item.phoneNumber)}</p>
                            <FiPhone className="large-icon" />
                          </a>)}
                      </div>

                      <h3 className="medium-text">{t('address')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.address,
                          <a
                            className="dark-blue-text maps-link"
                            aria-label={`${t('openMapsFor', { address: item.address })}`}
                            target="_blank"
                            href={sanitizeHtml(`https://maps.google.com/?q=${item.address}`)}
                            rel="noreferrer"
                          >
                            <p className="dark-blue-text">{item.address}</p>
                            <SiGooglemaps className="large-icon" />
                          </a>)}
                      </div>
                      <h3 className="medium-text">{t('type')}</h3>
                      <div className="contact-container">
                        {checkIfItemExists(item.type, highlight(item.type, search))}
                      </div>
                    </CollapsibleRow>
                  ))}
                </div>
              </InfiniteScroll>
            </Card>
          )}
        </>
      )}
      {locationsFilterModalOpen && (
        <DirectoryFilterSelect
          options={locations}
          selectedOptions={filters.locations}
          setSelected={(l) => updateFilters({ location: l })}
          name="locations"
          setOpen={setLocationsFilterModalOpen}
          isMulti
        />
      )}
      {isAdmin && workingExternalSupport && (
        <EditExternalSupport
          onClose={() => {
            setWorkingExternalSupport(null);
          }}
          externalSupport={workingExternalSupport}
          updateExternalSupport={updateExternalSupport}
        />
      )}
    </div>
  );
};

export default ExternalSupportPage;
