import React, { useState, useId } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileUpload } from 'react-icons/md';
import '../styles/components/CustomFileUploader.scss';

/**
 * Custom file uploader component
 * @returns JSX
 */
const CustomFileUploader = ({ doc, setDoc, ...props }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const id = useId();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setDoc(file);
    }
  };

  return (
    <div className="custom-uploader-div">
      <label htmlFor={id} className="file-upload-label">
        <MdOutlineFileUpload />
        <span className="file-upload-span">{t('clickToUpload')}</span>
        <input
          id={id}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="application/pdf"
          data-testid={props['data-testid']}
        />
      </label>

      {doc && fileName && <span className="file-name-span">{fileName}</span>}
    </div>
  );
};

CustomFileUploader.defaultProps = {
  'data-testid': undefined,
  doc: {},

};

CustomFileUploader.propTypes = {
  doc: PropTypes.instanceOf(Blob),
  setDoc: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

export default CustomFileUploader;
