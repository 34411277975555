import React, { useEffect, useState, useContext } from 'react';
import { LuFilePlus2 } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import updateToast from '../../services/toastService';
import UserContext from '../../contexts/UserContext';
import PageSvg from '../../assets/bcc-quick-reference/page.svg';
import axios from '../../services/Api';
import downloadDocument from '../../hooks/downloadDocumentHook';
import UploadModal from '../UploadModal';
import TwoButtonModal from '../TwoButtonModal';

import '../../styles/components/pages/BCCQuickReference.scss';

const BCCQuickReferencePage = () => {
  const { t } = useTranslation('common');
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [newDoc, setNewDoc] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [docToUpload, setDocToUpload] = useState({});
  const [selectedLocation, setSelectedLocation] = useState('');
  const { user } = useContext(UserContext);

  /**
   * gets bcc locations
   * @param {*} signal
   */
  const getLocations = async (signal) => {
    setLoading(true);
    try {
      const { data } = await axios.get('/constants/bcc-documents', { signal });
      setIsDownloadLoading(Array(data.length).fill());
      setLocations(data);
    } catch (error) {
      if (error.name === 'CanceledError') return;
      toast.error(t('errorLoadingBCC'));
    }
    setLoading(false);
  };

  /**
   * Gets all locations for admin
   * @param {*} signal
   */
  const getAllLocations = async (signal) => {
    try {
      const { data } = await axios.get('/constants/locations', { signal });
      setAllLocations(data.map((location) => location.name));
    } catch (error) {
      if (error.name === 'CanceledError') return;
      toast.error(t('errorLoadingLocations'));
    }
  };

  useEffect(() => {
    if (newDoc) {
      const doc = {};
      doc.formData = new FormData();
      doc.formData.append('file', newDoc, newDoc.name);
      setDocToUpload(doc);
    }
  }, [newDoc]);

  useEffect(() => {
    const abortController = new AbortController();
    getLocations(abortController.signal);
    if (user?.isAdmin) {
      getAllLocations(abortController.signal);
    }
    return () => {
      abortController.abort();
    };
  }, []);

  /**
     * Saves the uploaded document
     */
  const saveDoc = async () => {
    const toastId = toast.loading(t('addingBccQuickReference'));
    try {
      const { data } = await axios.post(`/admin/constants/document/bcc-quick-reference${selectedLocation}`, docToUpload.formData);
      if (data) {
        const locationIndex = locations.findIndex((el) => el === selectedLocation);
        if (locationIndex === -1) {
          setLocations([...locations, data.name.replace('constant-bcc-quick-reference', '')].sort((a, b) => {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
          }));
        }
        updateToast(toastId, t('bccQuickReferenceAdded'), toast.TYPE.SUCCESS);
      }
    } catch (error) {
      // error handled by toast
      updateToast(toastId, t('errorAddingBcc'), toast.TYPE.ERROR);
    }
    setSelectedLocation('');
  };

  /**
   * Renders the download button
   * @param {*} text - file name
   * @param {*} index
   * @returns download button with functionality
   */
  const downloadButton = (text, index) => {
    const handleLoad = (state) => {
      const temp = [...isDownloadLoading];
      temp[index] = state;
      setIsDownloadLoading(temp);
    };
    const handleDownload = async (location) => {
      const toastId = toast.loading(t('downloadInProgress'));
      handleLoad(true);
      try {
        await downloadDocument(`/constants/document/bcc-quick-reference${location}`, location);
        updateToast(toastId, t('downloadSuccess'), toast.TYPE.SUCCESS);
      } catch (err) {
        updateToast(toastId, t('downloadError'), toast.TYPE.ERROR);
      } finally {
        handleLoad(false);
      }
    };
    return (
      <div className="download-button" key={`${text}-div`}>
        {!isDownloadLoading[index] && <h2>{text}</h2>}
        {isDownloadLoading[index] && <h2>{t('loading')}</h2>}
        <div className="buttons-div">
          <button className="transparent-button" type="button" onClick={() => handleDownload(text)} key={text} disabled={isDownloadLoading[index]}>
            <div className="icons">
              <img src={PageSvg} alt={t('downloadPdf')} />
            </div>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bcc-quick-reference">
      <div className="bcc-header">
        {user?.isAdmin
          && (
          <div
            className="add-bcc"
            tabIndex="0"
            role="button"
            onKeyDown={() => setOpenUploadModal(true)}
            onClick={() => setOpenUploadModal(true)}
          >
            <LuFilePlus2 size="1em" />
            <p>{t('add')}</p>
          </div>
          )}
      </div>

      {locations.length > 0 && !loading
      && (
      <div className="links-container">
        {locations && locations.filter((location) => location !== null)
          .map((location, index) => downloadButton(
            location, index,
          ))}
      </div>
      )}
      {locations.length === 0 && !loading
        && <p>{t('noBccDocsFromLocations')}</p>}
      {loading
      && <p>{t('loading')}</p>}
      {user?.isAdmin && (
        <UploadModal
          isOpen={openUploadModal}
          headerText={t('addBccQuickReference')}
          closeFunctionality={() => { setOpenUploadModal(false); setSelectedLocation(''); }}
          label={t('location')}
          onSave={() => { setOpenUploadModal(false); setSaveModalOpen(true); }}
          setDoc={setNewDoc}
          isInputDropdown
          items={allLocations}
          fileName={selectedLocation}
          setFileName={setSelectedLocation}
        />
      )}
      {user?.isAdmin && (
      <TwoButtonModal
        isOpen={saveModalOpen}
        redButtonFunctionality={() => { setSaveModalOpen(false); saveDoc(); }}
        blueButtonFunctionality={() => { setSaveModalOpen(false); setSelectedLocation(''); }}
        closeFunctionality={() => { setSaveModalOpen(false); setSelectedLocation(''); }}
        headerText={t('saveBccQuickReference')}
        redButtonText={t('yes')}
        blueButtonText={t('cancel')}
      />
      )}
    </div>
  );
};

export default BCCQuickReferencePage;
