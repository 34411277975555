import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TiPlus } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import { LuPencil } from 'react-icons/lu';
import axios from '../../../services/Api';
import Card from '../../Card';
import CollapsibleRow from '../../CollapsibleRow';
import BlueButton from '../../BlueButton';
import SearchBar from '../../SearchBar';
import KDCLogo from '../../KDCLogo';
import highlight from '../../../helpers/searchHighlight';
import searchHelper from '../../../helpers/searchHelper';
import useUserList from '../../../hooks/useUserList';

import '../../../styles/components/pages/admin/BusinessTeamsPage.scss';

const BusinessTeamsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, isUserLoading] = useUserList();
  const [rawTeams, setRawTeams] = useState([]);
  const [populatedTeams, setPopulatedTeams] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const searchableTerms = [
    'team',
    'locations.location',
    'locations.lead',
    'locations.alt',
    'avps',
    'executives',
    'vpLeads',
    'vpAlternates',
  ];

  const populateTeams = (teams) => {
    const populateArray = (arr) => arr.map(
      // Find the user in the user list
      (item) => {
        const user = users.find((u) => u._id === item);
        return user ? `${user.firstName} ${user.lastName}` : undefined;
      },
    ).filter(
      // Remove any entries that weren't found
      (item) => item,
    );

    const populateUser = (user) => {
      if (user === 'None' || user === 'Pending') {
        return user;
      }
      const userObj = users.find((u) => u._id === user);
      return userObj ? `${userObj.firstName} ${userObj.lastName}` : '';
    };
    return teams.map((team) => ({
      ...team,
      avps: populateArray(team.avps),
      vpLeads: populateArray(team.vpLeads),
      vpAlternates: populateArray(team.vpAlternates),
      executives: populateArray(team.executives),
      locations: team.locations.map((location) => ({
        ...location,
        lead: populateUser(location.lead),
        alt: populateUser(location.alt),
      })),
    }));
  };

  // Get teams
  useEffect(() => {
    const abortController = new AbortController();
    const getTeams = async () => {
      try {
        const { data } = await axios.get('/admin/teams', { signal: abortController.signal });
        setRawTeams(data);
        setIsLoading(false);
      } catch (e) {
        if (e.name === 'CanceledError') return;
        toast.error(t('errorLoadingTeams'));
      }
    };
    getTeams();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    setPopulatedTeams(populateTeams(rawTeams));
  }, [users, rawTeams]);

  const handleRowExpandedChange = (team) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [team]: !prevState[team],
    }));
  };

  const isOneExpanded = Object.values(expandedRows).includes(true);

  const invertAllExpanded = () => {
    const newExpandedRows = { ...expandedRows };
    populatedTeams.forEach(({ _id }) => {
      newExpandedRows[_id] = !isOneExpanded;
    });
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    if (isOneExpanded) invertAllExpanded();
  }, [search]);

  const filteredTeams = searchHelper(search, searchableTerms, populatedTeams);
  filteredTeams.sort((a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()));

  return (
    <div className="business-teams-page">
      <Card header={<div>{t('search')}</div>}>
        <div className="filters-container">
          <SearchBar
            placeholder={t('search')}
            label={t('businessTeamsSearch')}
            id="businessTeamsSearch"
            type="text"
            value={search}
            disabled={false}
            onChange={setSearch}
          />
        </div>
      </Card>

      <Card
        header={(
          <div className="teams-header">
            <h2>{t('currentTeams')}</h2>
            {!isLoading && !isUserLoading && (
              <div className="add-button-container">
                <button type="button" onClick={() => navigate('/admin/edit-business-team/')}>
                  <TiPlus size="1.5em" title={t('addTeam')} />
                  <span>{t('add')}</span>
                </button>
              </div>
            )}
          </div>
        )}
      >
        {(isLoading || isUserLoading) ? (
          <KDCLogo spinning />
        ) : (
          <>
            {filteredTeams.length !== 0 && (
              <div className="button-container">
                <BlueButton onClick={invertAllExpanded} text={isOneExpanded ? t('collapseAll') : t('expandAll')} />
              </div>
            )}
            <div className="business-team-data">
              {filteredTeams.length === 0 && rawTeams.length > 0 && (
                <div className="text-container">
                  <h2 className="large-text">{t('noTeamsMatchSearch')}</h2>
                </div>
              )}
              {filteredTeams.length === 0 && rawTeams.length === 0 && (
                <div className="text-container">
                  <h2 className="large-text">{t('noTeams')}</h2>
                </div>
              )}
              {filteredTeams.length > 0 && filteredTeams.map((team) => (
                <CollapsibleRow
                  key={`${team._id}`}
                  header={(
                    <div className="business-team-header">
                      {highlight(team.team, search)}
                      <button type="button" className="edit-button" aria-label={t('editBusinessTeam')} onClick={() => navigate(`/admin/edit-business-team/${team._id}`)}>
                        <LuPencil className="edit-business-team" />
                      </button>
                    </div>
                  )}
                  isExpanded={expandedRows[team._id]}
                  invertExpanded={handleRowExpandedChange}
                  rowIndex={team._id}
                >
                  <div className="team-wrapper">
                    <h2 className="section-title medium-text">{t('locations')}</h2>
                    {team.locations?.length > 0
                      ? (
                        <div className="locations-grid">
                          <h2 className="medium-text">{t('location')}</h2>
                          <h2 className="medium-text">{t('teamLead')}</h2>
                          <h2 className="medium-text">{t('teamAlternate')}</h2>
                          {team.locations.map((location, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Fragment key={`${location.location}-${i}`}>
                              <div>{highlight(location.location, search)}</div>
                              <div>{highlight(location.lead, search)}</div>
                              <div>{highlight(location.alt, search)}</div>
                            </Fragment>
                          ))}
                        </div>
                      ) : <div className="contact-container"><p className="dark-blue-text">{t('noTeamLocations')}</p></div>}
                    <h2 className="section-title medium-text">{t('avp')}</h2>
                    {team.avps?.length > 0
                      ? team.avps.map((avp, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${avp}-${i}`} className="contact-container">{highlight(avp, search)}</div>
                      )) : <div className="contact-container"><p className="dark-blue-text">{t('noTeamAVPs')}</p></div>}
                    <h2 className="section-title medium-text">{t('vpLead')}</h2>
                    {team.vpLeads?.length > 0
                      ? team.vpLeads.map((vpLead, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${vpLead}-${i}`} className="contact-container">{highlight(vpLead, search)}</div>
                      )) : <div className="contact-container"><p className="dark-blue-text">{t('noTeamVPLeads')}</p></div>}
                    <h2 className="section-title medium-text">{t('vpAlternate')}</h2>
                    {team.vpAlternates?.length > 0
                      ? team.vpAlternates.map((vpAlt, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${vpAlt}-${i}`} className="contact-container">{highlight(vpAlt, search)}</div>
                      )) : <div className="contact-container"><p className="dark-blue-text">{t('noTeamVPAlternates')}</p></div>}
                    <h2 className="section-title medium-text">{t('executive')}</h2>
                    {team.executives?.length > 0
                      ? team.executives.map((executive, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${executive}-${i}`} className="contact-container">{highlight(executive, search)}</div>
                      )) : <div className="contact-container"><p className="dark-blue-text">{t('noTeamExecutives')}</p></div>}
                  </div>
                </CollapsibleRow>
              ))}
            </div>
          </>
        )}
      </Card>
    </div>

  );
};

export default BusinessTeamsPage;
