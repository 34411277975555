import React, { useState } from 'react';
import PropTypes from 'prop-types';
import formatPhoneNumber from '../helpers/phone';
import DatePicker from './DatePicker';
import '../styles/components/InputComponent.scss';

const InputComponent = ({
  header,
  required,
  optional,
  name,
  type,
  value,
  onChange,
  nestedItem,
  smallerItem,
  disabled,
  id,
  limit,
  phone,
}) => {
  const [formattedPhone, setFormattedPhone] = useState(
    phone ? formatPhoneNumber(value) : '',
  );

  return (
    <div className="item input-component">
      <label
        htmlFor={id}
        className={smallerItem ? 'smaller-input form-input' : 'form-input'}
      >
        {!nestedItem && !smallerItem && (
          <h2 className="input-header">
            {`${header} ${required ? '*' : ''}`}
            {optional && <span className="optional-header">{optional}</span>}
          </h2>
        )}

        {nestedItem && (
          <h3 className="nested-header">
            {`${header} ${required ? '*' : ''}`.toUpperCase()}
            {optional && <span>{optional}</span>}
          </h3>
        )}

        {!nestedItem && smallerItem && (
          <h3 className="smaller-input-header">
            {`${header} ${required ? '*' : ''}`}
          </h3>
        )}

        {type === 'date' ? (
          <DatePicker
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <input
            name={name}
            maxLength={limit}
            type="text"
            value={phone ? formattedPhone : value}
            disabled={disabled}
            onChange={(e) => {
              e.preventDefault();
              if (type === 'number') {
                onChange(e.target.value.replace(/\D/g, ''));
              } else {
                onChange(e.target.value);
              }
              if (phone) {
                setFormattedPhone(formatPhoneNumber(e.target.value));
              }
            }}
            className="input"
            id={id}
          />
        )}
      </label>
    </div>
  );
};

InputComponent.defaultProps = {
  header: '',
  optional: '',
  required: false,
  name: '',
  type: '',
  value: '',
  nestedItem: false,
  smallerItem: false,
  onChange: null,
  disabled: false,
  id: '',
  limit: Infinity,
  phone: false,
};

InputComponent.propTypes = {
  header: PropTypes.string,
  optional: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  nestedItem: PropTypes.bool,
  smallerItem: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  limit: PropTypes.number,
  phone: PropTypes.bool,
};

export default InputComponent;
